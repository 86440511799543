import { dateToString } from '@microfrontends/react-components'
import { assetUrl } from '../../../helpers/common'
import { EPages } from '../../../helpers/constant'
import { TSelectData } from '../../SearchResults/helpers/filterHelper'
import { ISearchFilterParams, ISEOSearch } from '../../SearchResults/types/ISearchFilterParams'
import { ELanguage } from '../../SearchResults/utils/language'
import { ResponseJobSearch } from '../../SearchResults/services/jobSearchService'

const SEARCH_PARAMS_META_PRIORITY = [
    'query',
    'categoryId',
    'locationId',
    'typeWorkingId',
    'jobLevelId',
    'isUrgentJob',
    'indexName'
]

// const MAX_COMPONENTS_TO_BUILD_META = 3

// 1,2,3 => before: Tuyển dụng việc làm
// 4,5 => before: Việc làm
// 1 => after:   mới nhất | Vietnamworks
// 2 => after:   lương cao | Vietnamworks
// 3,4,5 => after: null

// isUrgent = true
// 1,2,3  => before: Tuyển dụng việc làm gấp
// 4,5 => before: Việc làm gấp
// 1 => after:   mới nhất | Vietnamworks
// 2 => after:   lương cao | Vietnamworks
// 3,4,5 => after: null
// no params: Tuyển dụng tìm việc làm lương cao mới nhất | Vietnamworks
//= > tite =  before + metaText + after

export const getTitle = (
    keyword: string,
    categoryName: string,
    locationName: string,
    language: ELanguage,
    companyName: string,
    jobLevelName: string = '',
    typeWorkingName: string = '',
    isUrgent: boolean = false,
    searchResultData: null | Partial<ResponseJobSearch> = null,
    url: string = '',
    districtName?: string
) => {
    const isEn = language === ELanguage.EN
    const CATEGORY_PREP = isEn ? 'at' : 'ngành'
    const LOCATION_PREP = isEn ? 'in' : 'tại'
    const locationNameOrigin = locationName.split(',')
    if (districtName != '' && districtName != undefined) {
        locationName = locationName.split(',')[0]
    }

    const categoryText = categoryName !== '' ? `${CATEGORY_PREP} ${categoryName}` : ''
    const locationText =
        locationName !== ''
            ? `${LOCATION_PREP} ${
                  districtName != '' && districtName != undefined ? districtName + `, ` : ``
              }${locationName}`
            : ''

    const searchParamsMeta = [jobLevelName, keyword, typeWorkingName, categoryName, locationName]
    const countSearchParamsMeta = searchParamsMeta.filter(function (component) {
        return component !== ''
    }).length

    // create text middle
    let metaComponents = [
        jobLevelName,
        keyword,
        typeWorkingName,
        categoryText.replace(`IT - Phần mềm`, `IT - Phần mềm (InTech)`),
        locationText
    ]

    if (isEn) {
        metaComponents = [
            jobLevelName,
            keyword,
            typeWorkingName,
            'jobs',
            categoryText.replace(`IT - Software`, `IT - Software (InTech)`),
            locationText
        ]
    }

    const metaText =
        locationNameOrigin.length > 1 && districtName != ''
            ? LOCATION_PREP + ' ' + districtName + ', ' + locationNameOrigin[0]
            : metaComponents.filter((component) => component !== '').join(' ')

    const searchParams = {
        keyword,
        categoryName,
        locationName,
        jobLevelName,
        typeWorkingName,
        isUrgent
    }

    /**
     * VNW-19886: [SEO][Search Results] Update meta title and description including page number
     *
     * @author Tuan, Phan Thanh
     * @since 27 Dec, 2022
     */

    const suffix = ` | Vietnamworks`
    /**
     * VNW-29607: [Web] Update metadescription and <h tag> for job search, company profile and CCP
     *
     * @author Thien, Tran Du
     * @since 14, May 2024
     */
    if (url && (url.indexOf('/management-jobs') >= 0 || url.indexOf('/viec-lam-quan-ly') >= 0)) {
        if (isEn) {
            return 'All jobs | Vietnamworks'
        }
        const now = dateToString(null)
        const jobCount = searchResultData?.nbHits && searchResultData?.nbHits > 0 ? `${searchResultData?.nbHits} ` : ''

        return `Tuyển dụng ${jobCount} việc làm Quản lý lương cao, phúc lợi tốt, mới nhất ${now}`
    }

    if (!countSearchParamsMeta && isUrgent) {
        if (isEn) {
            /**
             * Request to update by Ngoc Anh (PM)
             *
             * @since 24 Aug, 2023
             */
            return 'All jobs | Vietnamworks'
        }
        return 'Tuyển dụng việc làm gấp lương cao mới nhất' + suffix
    }

    const isSearchAll = isSearchParamsEmpty(searchParams)
    if (isSearchAll && !companyName) {
        if (isEn) {
            return 'All jobs | Vietnamworks'
        }
        return 'Tuyển dụng tìm việc làm lương cao mới nhất' + suffix
    }

    // create text before
    let textBefore = isEn ? '' : isUrgent ? 'Tuyển dụng việc làm gấp ' : 'Tuyển dụng việc làm '

    if (isUrgent) {
        textBefore = isEn ? 'Urgent ' : `${textBefore} `
    }

    // create text after
    let textAfter = ''
    if (countSearchParamsMeta === 1) {
        textAfter = (isEn ? '' : ` mới nhất`) + suffix
    }
    if (countSearchParamsMeta === 2) {
        textAfter = (isEn ? '' : ` lương cao`) + suffix
    }
    /**
     * VNW-29607: [Web] Update metadescription and <h tag> for job search, company profile and CCP
     *
     * @author Thien, Tran Du
     * @since 14, May 2024
     */
    const now = dateToString(null)
    const jobCount = searchResultData?.nbHits && searchResultData?.nbHits > 0 ? `${searchResultData?.nbHits} ` : ''

    if (locationName !== '' && keyword === '') {
        textAfter = isEn ? '' : ` mới nhất ${now}`
    }
    if (locationName === '' && url.indexOf('-kv') > 1) {
        textBefore = `${isEn ? '' : isUrgent ? 'Tuyển dụng gấp' : 'Tuyển dụng'} ${jobCount} việc làm `
        textAfter = isEn ? '' : ` lương cao, phúc lợi tốt, mới nhất ${now}`
    }
    if (locationName !== '' && keyword !== '') {
        textBefore = `${isEn ? '' : isUrgent ? 'Tuyển dụng gấp' : 'Tuyển dụng'} ${jobCount} việc làm `
        textAfter = isEn ? '' : ` lương cao, mới nhất ${now}`
    }

    // create full text title
    return `${textBefore}${metaText}${textAfter}`
}

function isSearchParamsEmpty(params: { [key: string]: string | boolean }) {
    return Object.values(params).every((value) => value === '' || value === false)
}

export const getDescriptionMeta = (
    keyword: string,
    categoryName: string,
    locationName: string,
    language: ELanguage,
    companyName: string,
    jobLevelName: string = '',
    typeWorkingName: string = '',
    isUrgent: boolean = false,
    searchResultData: null | Partial<ResponseJobSearch> = null,
    url: string = '',
    districtName?: string
): string => {
    const searchParamsMeta = [jobLevelName, keyword, typeWorkingName, categoryName, locationName]
    const locationNameOrigin = locationName.split(',')
    const countSearchParamsMeta = searchParamsMeta.filter(function (component) {
        return component !== ''
    }).length

    const isEn = language === ELanguage.EN
    const isLocationParamOnly = locationName && !jobLevelName && !keyword && !typeWorkingName && !categoryName
    const hasDistrictFilter = districtName != '' && districtName != undefined && locationNameOrigin.length >= 1

    if (isLocationParamOnly) {
        const jobCount = searchResultData?.nbHits && searchResultData?.nbHits > 0 ? `${searchResultData?.nbHits}` : ''
        const monthName = new Date().toLocaleString('default', { month: isEn ? 'long' : 'numeric' })

        const nestedLocationsName = `${districtName ? `${districtName}, ` : ''}${
            locationNameOrigin[0] ? locationNameOrigin[0] : ''
        }`

        const jobDesVi = jobCount ? `với hơn ${jobCount} việc làm ` : ''

        const enDes = `Open positions for jobs in ${hasDistrictFilter ? districtName + `, ` : ``}${
            locationNameOrigin[0]
        } in ${monthName} with attractive salaries and benefits. See more details on VietnamWorks to apply .`

        const viDes = `Tuyển dụng việc làm ${nestedLocationsName} ${jobDesVi}trong tháng ${monthName} với mức lương cao hấp dẫn, đãi ngộ tốt. Xem chi tiết tại VietnamWorks để nộp đơn.`

        return isEn ? enDes : viDes
    }

    let urgentText = isEn ? '' : 'Tuyển dụng '
    if (isUrgent) {
        urgentText = isEn ? 'Urgent! ' : '(Tuyển dụng Gấp) '
    }
    const CATEGORY_PREP = isEn ? 'at' : 'ngành'
    const LOCATION_PREP = isEn ? 'in' : 'tại'

    const categoryText = categoryName !== '' ? `${CATEGORY_PREP} ${categoryName}` : ''
    const locationText =
        locationName !== ''
            ? `${LOCATION_PREP} ${
                  districtName != '' && districtName != undefined ? districtName + `, ` : ``
              }${locationName}`
            : ''
    let extraText = ''
    if (typeWorkingName && locationName && countSearchParamsMeta === 2) {
        extraText = 'các ngành nghề mới nhất'
    }
    if (
        (jobLevelName && locationName && countSearchParamsMeta === 2) ||
        (typeWorkingName && countSearchParamsMeta === 1) ||
        (typeWorkingName && jobLevelName && countSearchParamsMeta === 2) ||
        (jobLevelName && countSearchParamsMeta === 1)
    ) {
        extraText = 'các ngành nghề'
    }
    if (jobLevelName && typeWorkingName && locationName && countSearchParamsMeta === 3) {
        extraText = 'mới nhất'
    }

    let metaComponents = [
        jobLevelName,
        keyword,
        typeWorkingName,
        categoryText.replace(`IT - Phần mềm`, `IT - Phần mềm (InTech)`),
        extraText,
        locationText
    ]
    if (isEn) {
        metaComponents = [
            jobLevelName,
            keyword,
            typeWorkingName,
            'jobs',
            categoryText.replace(`IT - Software`, `IT - Software (InTech)`),
            locationText
        ]
    }
    const metaText = metaComponents.filter((component) => component !== '').join(' ')

    const searchParams = {
        keyword,
        categoryName,
        locationName,
        jobLevelName,
        typeWorkingName
    }

    const isSearchAll = isSearchParamsEmpty(searchParams)
    const jobCount = searchResultData?.nbHits && searchResultData?.nbHits > 0 ? `${searchResultData?.nbHits} ` : ''

    /**
     * VNW-29607: [Web] Update metadescription and <h tag> for job search, company profile and CCP
     *
     * @author Thien, Tran Du
     * @since 14, May 2024
     */
    if (url && (url.indexOf('/management-jobs') >= 0 || url.indexOf('/viec-lam-quan-ly') >= 0)) {
        if (isEn) {
            return 'All jobs | Vietnamworks'
        }

        if (jobCount !== '') {
            return `Hơn ${jobCount}việc làm Quản lý toàn quốc, mới cập nhật từ các công ty hàng đầu, phúc lợi tốt, đi làm ngay. Tìm việc lương cao nhanh chóng, hiệu quả, dễ dàng ứng tuyển tại VietnamWorks!`
        }
        return `Việc làm Quản lý toàn quốc, mới cập nhật từ các công ty hàng đầu, phúc lợi tốt, đi làm ngay. Tìm việc lương cao nhanh chóng, hiệu quả, dễ dàng ứng tuyển tại VietnamWorks!`
    }

    /**
     * VNW-19886: [SEO][Search Results] Update meta title and description including page number
     *
     * @author Tuan, Phan Thanh
     * @since 27 Dec, 2022
     */
    const suffix = ''

    if (isSearchAll && isUrgent) {
        return (
            (isEn
                ? 'Urgent! Open positions for all the latest high salary jobs in 2021 with attractive salaries and benefits. See more details on VietnamWorks.'
                : '(Tuyển dụng Gấp) tìm kiếm việc làm tại nhiều vị trí, ngành nghề với mức lương cao hấp dẫn, đãi ngộ tốt. Xem chi tiết tại Vietnamworks để nộp đơn.') +
            suffix
        )
    }

    if (isSearchAll && !companyName) {
        return (
            (isEn
                ? `Open positions for all the latest high salary jobs in 2021 with attractive salaries and benefits. See more details on VietnamWorks.`
                : `Thông tin tuyển dụng tất cả các việc làm trên toàn quốc với mức lương cao, hấp dẫn, chế độ đãi ngộ tốt. Tìm kiếm nhanh việc làm tại website VietnamWorks để nộp đơn!`) +
            suffix
        )
    }

    if (companyName) {
        return (
            (isEn
                ? `${companyName} recruits many positions with high salary, professional environment and good benefits. See more details on Vietnamworks.`
                : `Tìm việc làm tuyển dụng tại ${companyName} lương cao, mới nhất từ nhà tuyển dụng số 1 Việt Nam. Ứng tuyển nhanh tại Vietnamworks`) +
            suffix
        )
    }

    if (typeWorkingName && !isUrgent && countSearchParamsMeta === 1) {
        return (
            (isEn
                ? `Open positions for all the latest high salary jobs in 2021 with attractive salaries and benefits. See more details on VietnamWorks.`
                : `Tìm kiếm việc làm, tuyển dụng nhiều vị trí ${typeWorkingName} các ngành nghề với mức lương cao hấp dẫn, đãi ngộ tốt. Xem chi tiết tại Vietnamworks để nộp đơn.`) +
            suffix
        )
    }

    let afterText = 'Xem chi tiết tại Vietnamworks.'
    if (countSearchParamsMeta < 3 && !keyword) {
        afterText = 'Xem chi tiết tại Vietnamworks để nộp đơn.'
    }
    if (countSearchParamsMeta === 5) {
        afterText = 'Nộp CV ngay.'
    }

    let noneJobLevelText = ' nhiều vị trí'
    if (jobLevelName) {
        noneJobLevelText = ''
    }

    if (locationName === '' && url && url.indexOf('-kv') > 1) {
        let descriptionText = isEn
            ? `${urgentText}Open positions for ${metaText} with attractive salaries and benefits. See more details on VietnamWorks.`
            : `Hơn ${jobCount}việc làm ${metaText} mới cập nhật từ các công ty hàng đầu, phúc lợi tốt, đi làm ngay. Tìm việc lương cao nhanh chóng, hiệu quả, dễ dàng ứng tuyển tại VietnamWorks!`
        if (jobCount === '') {
            descriptionText = isEn
                ? `${urgentText}Open positions for ${metaText} with attractive salaries and benefits. See more details on VietnamWorks.`
                : `Việc làm ${metaText} mới cập nhật từ các công ty hàng đầu, phúc lợi tốt, đi làm ngay. Tìm việc lương cao nhanh chóng, hiệu quả, dễ dàng ứng tuyển tại VietnamWorks!`
        }

        return descriptionText
    }

    if (locationName !== '' && keyword !== '') {
        let descriptionText = isEn
            ? `${urgentText}Open positions for ${metaText} with attractive salaries and benefits. See more details on VietnamWorks.`
            : `Hơn ${jobCount}việc làm ${metaText} cập nhật mới nhất, phúc lợi tốt, đi làm ngay. Tìm việc lương cao nhanh chóng, hiệu quả, dễ dàng ứng tuyển tại VietnamWorks!`

        if (jobCount === '') {
            descriptionText = isEn
                ? `${urgentText}Open positions for ${metaText} with attractive salaries and benefits. See more details on VietnamWorks.`
                : `Việc làm ${metaText} cập nhật mới nhất, phúc lợi tốt, đi làm ngay. Tìm việc lương cao nhanh chóng, hiệu quả, dễ dàng ứng tuyển tại VietnamWorks!`
        }

        return descriptionText
    }

    return (
        (isEn
            ? `${urgentText}Open positions for ${metaText} with attractive salaries and benefits. See more details on VietnamWorks.`
            : `${urgentText}tìm kiếm việc làm${noneJobLevelText} ${metaText} với mức lương cao hấp dẫn, đãi ngộ tốt. ${afterText}`) +
        suffix
    )
}

export const getKeywordMeta = (
    keyword: string,
    categoryName: string,
    locationName: string,
    language: ELanguage,
    districtName?: string
): string => {
    const hasParams = !!keyword || !!categoryName || !!locationName
    const hasLocationParamsOnly = locationName && !keyword && !categoryName
    const locationNameOrigin = locationName.split(',')
    const hasDistrictFilter = districtName != '' && districtName != undefined && locationNameOrigin.length >= 1
    // English
    if (language === ELanguage.EN) {
        if (hasLocationParamsOnly) {
            return `job ${hasDistrictFilter ? districtName + `, ` : ``}${locationNameOrigin[0]}, job search ${
                hasDistrictFilter ? districtName + `, ` : ``
            }${locationNameOrigin[0]}, find a job at ${hasDistrictFilter ? districtName + `, ` : ``}${
                locationNameOrigin[0]
            }, recruitment ${hasDistrictFilter ? districtName + `, ` : ``}${locationNameOrigin[0]}, job search ${
                hasDistrictFilter ? districtName + `, ` : ``
            }${locationNameOrigin[0]}`
        }
        if (hasParams) {
            let keywordMeta = 'Job search'
            keywordMeta += keyword ? `, ${keyword}` : ``
            keywordMeta += categoryName ? `, ${categoryName}` : ``
            keywordMeta += locationName ? `, ${'in ' + locationName}` : ``
            return keywordMeta
        }
        return 'Job Search, Recruitment'
    }

    // Vietnamese
    if (hasParams) {
        if (hasLocationParamsOnly) {
            return `việc làm ${hasDistrictFilter ? districtName + `, ` : ``}${locationNameOrigin[0]}, tìm việc làm ${
                hasDistrictFilter ? districtName + `, ` : ``
            }${locationNameOrigin[0]}, tìm việc làm tại ${hasDistrictFilter ? districtName + `, ` : ``}${
                locationNameOrigin[0]
            }, tuyển dụng ${hasDistrictFilter ? districtName + `, ` : ``}${locationNameOrigin[0]}, tìm việc ${
                hasDistrictFilter ? districtName + `, ` : ``
            }${locationNameOrigin[0]}`
        }
        let keywordMeta = 'Tìm việc nhanh'
        keywordMeta += keyword ? `, ${keyword}` : ``
        keywordMeta += categoryName ? `, ${categoryName}` : ``
        keywordMeta += locationName ? `, ${'tại ' + locationName}` : ``
        return keywordMeta
    }
    return 'Tìm việc làm, tin tuyển dụng, tuyển dụng lương cao'
}
const OG_IMAGE = process.env.VNW_IMAGES_URL + '/img/vnw_logo.png'
type TDocMeta = {
    title: string
    description: string
    keywords: string
    ogImageUrl: string
}
export const buildDynamicMeta = (keyword: string, pageName: string, transFunc: any): TDocMeta => {
    const defaultMeta: TDocMeta = {
        title: transFunc('defautMetaTitle'),
        description: transFunc('defautMetaDesc'),
        keywords: transFunc('defaultMetaKeywords'),
        ogImageUrl: OG_IMAGE
    }

    if (pageName === EPages.INTERVIEW) {
        const title =
            keyword === ''
                ? transFunc('defautInterviewMetaTitle')
                : `${keyword}: ${transFunc('common_interview_questions')}`
        const desc =
            keyword === ''
                ? transFunc('defautInterviewMetaDesc')
                : `${transFunc('interviewKeywordMetaDesc').replace(/%s/g, keyword)}`

        const ogImage = assetUrl(transFunc('/images/new-og-image-interview-en.png'))

        return {
            ...defaultMeta,
            title: title,
            description: desc,
            ogImageUrl: ogImage
        }
    }

    if (pageName === EPages.SALARY) {
        const title =
            keyword === ''
                ? transFunc('defautSalaryMetaTitle')
                : transFunc('salaryKeywordMetaTitle').replace(/%s/g, keyword)
        const desc =
            keyword === ''
                ? transFunc('defautSalaryMetaDesc')
                : transFunc('salaryKeywordMetaDesc').replace(/%s/g, keyword)

        const ogImage = assetUrl(transFunc('/images/new-og-image-salary-en.png'))
        return {
            ...defaultMeta,
            title: title,
            description: desc,
            ogImageUrl: ogImage
        }
    }

    return defaultMeta
}

export function filterParamsByPriority(params: Partial<ISearchFilterParams>) {
    const filteredParams: Partial<ISearchFilterParams> = {}

    // let countComponents = 0

    for (const fieldName of SEARCH_PARAMS_META_PRIORITY) {
        /**
         * VNW-15987 [SearchResult] Improve SEO by modifying meta tags
         *
         * @author Tri Tran
         * @since Apr 19, 2022
         * Url and canonical need the same
         */

        // if (countComponents === MAX_COMPONENTS_TO_BUILD_META) {
        //     break
        // }

        const value = params[fieldName]
        let isValidValue = false

        if (typeof value === 'boolean' && value === true) {
            isValidValue = true
        }

        if (typeof value === 'string' && value !== '') {
            isValidValue = true
        }

        if (typeof value === 'number' && value !== -1) {
            isValidValue = true
        }

        /**
         * Support Multiple Job Categories
         */
        if (typeof value === 'object' && value.length > 0) {
            isValidValue = true
        }

        if (isValidValue) {
            filteredParams[fieldName] = value
            // countComponents++
        }
    }

    return filteredParams
}

export function buildSEOMeta(
    searchParams: Partial<ISearchFilterParams>,
    selectData: TSelectData,
    language: ELanguage
): Partial<ISEOSearch> {
    const {
        query = '',
        categoryId = [],
        locationId = [],
        userId = 0,
        isUrgentJob = false,
        jobLevelId,
        typeWorkingId,
        page = 0
    } = searchParams
    const locations = selectData.locationById || []
    const categories = selectData.categoryById || []
    const jobLevelSelectData = selectData.level.find((item) => item.id === jobLevelId)
    const typeWorkingSelectData = selectData.typeWorking.find((item) => item.id === typeWorkingId)

    const location: { id: number; name: string }[] = []
    if (locationId !== undefined && locationId !== null && typeof locationId === 'object' && locationId.length > 0) {
        locationId.map((item) => {
            location.push({
                id: item,
                name: locations[item] !== undefined ? locations[item].name : ''
            })
        })
    }

    const category: { id: number; name: string }[] = []
    if (categoryId !== undefined && categoryId !== null && typeof categoryId === 'object' && categoryId.length > 0) {
        categoryId.map((item) => {
            category.push({
                id: item,
                name: categories[item] !== undefined ? categories[item].name : ''
            })
        })
    }

    const jobLevel = jobLevelSelectData
        ? {
              id: jobLevelId || 0,
              name: (language === ELanguage.EN ? jobLevelSelectData.nameEn : jobLevelSelectData.name) || ''
          }
        : null

    const typeWorking = typeWorkingSelectData
        ? {
              id: typeWorkingId || 0,
              name: (language === ELanguage.EN ? typeWorkingSelectData.nameEn : typeWorkingSelectData.name) || ''
          }
        : null

    return {
        category: category,
        location: location,
        query: query,
        userId,
        jobLevel: jobLevel,
        typeWorking: typeWorking,
        isUrgentJob,
        page: page
    }
}

type TPlaceHolderValues = {
    number_of_jobs: string
    current_month: string
    current_date: string
}

function replacePlaceHolder(template: string, values: TPlaceHolderValues) {
    return template.replace(/{(\w+)}/g, (_, key) => values[key] || '')
}

/**
 * Convert meta data title
 * @param {string} text The text processed
 * @param {object | null} searchResultData The search result response from serverside
 * @returns {string} Processed string
 */
export function convertMetaInfo(text: string, searchResultData: null | Partial<ResponseJobSearch> = null): string {
    if (!text) {
        return ''
    }
    const jobCount = searchResultData?.nbHits && searchResultData?.nbHits > 0 ? `${searchResultData?.nbHits} ` : ''
    const placeHolderValues: TPlaceHolderValues = {
        number_of_jobs: jobCount,
        current_date: dateToString(null),
        current_month: 'tháng ' + (new Date().getMonth() + 1)
    }
    return replacePlaceHolder(text, placeHolderValues)
}
