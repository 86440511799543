import { put, takeLatest } from 'redux-saga/effects'
import {
    CREATE_JOB_ALERT_FAILED,
    createNewJobAlertSuccess,
    DELETE_JOB_ALERT_FAILED,
    deleteJobAlertSuccess,
    REQUEST_CREATE_JOB_ALERT,
    REQUEST_DELETE_JOB_ALERT,
    REQUEST_UPDATE_JOB_ALERT,
    TRequestCreateNewJobAlertAction,
    TRequestDeleteJobAlertAction,
    TRequestUpdateJobAlertAction,
    UPDATE_JOB_ALERT_FAILED,
    updateJobAlertSuccess
} from '../jobAlertActions'
import { TSubmitUpdateJobAlert } from '../types'
import jobAlertApi from './../services/jobAlertApi'
import * as Sentry from '@sentry/node'

export function* watchJobAlertCreation() {
    yield takeLatest(REQUEST_CREATE_JOB_ALERT, createNewJobAlert)
}

export function* watchJobAlertEdition() {
    yield takeLatest(REQUEST_UPDATE_JOB_ALERT, updateJobAlert)
}

export function* watchJobAlertDeletion() {
    yield takeLatest(REQUEST_DELETE_JOB_ALERT, deleteJobAlert)
}

function* createNewJobAlert(action: TRequestCreateNewJobAlertAction) {
    const { payload } = action
    const { item: submitJobAlert, language } = payload

    try {
        const responseData = yield jobAlertApi.createJobAlert(submitJobAlert, language)
        const isSuccess = responseData.length > 0
        if (isSuccess) {
            yield put(createNewJobAlertSuccess(responseData[0]))
        } else {
            yield put({ type: CREATE_JOB_ALERT_FAILED, payload: '' })
        }
    } catch (err) {
        Sentry.captureException(err)

        if (err instanceof Error) {
            yield put({ type: CREATE_JOB_ALERT_FAILED, payload: err.message })
        }
    }
}

function* updateJobAlert(action: TRequestUpdateJobAlertAction) {
    const { payload } = action
    const { item: submitJobAlert, index, language } = payload

    try {
        const responseData = yield jobAlertApi.updateJobAlert(submitJobAlert as TSubmitUpdateJobAlert, language)
        const isSuccess = responseData.length > 0
        if (isSuccess) {
            yield put(updateJobAlertSuccess(responseData[0], index))
        } else {
            yield put({ type: UPDATE_JOB_ALERT_FAILED, payload: '' })
        }
    } catch (err) {
        Sentry.captureException(err)

        if (err instanceof Error) {
            yield put({ type: UPDATE_JOB_ALERT_FAILED, payload: err.message })
        }
    }
}

function* deleteJobAlert(action: TRequestDeleteJobAlertAction) {
    const { payload } = action
    const { item: jobAlertId, index } = payload

    try {
        const responseData = yield jobAlertApi.deleteJobAlert(jobAlertId as number)
        const isSuccess = responseData
        if (isSuccess) {
            yield put(deleteJobAlertSuccess(index))
        } else {
            yield put({ type: DELETE_JOB_ALERT_FAILED, payload: '' })
        }
    } catch (err) {
        Sentry.captureException(err)

        if (err instanceof Error) {
            yield put({ type: DELETE_JOB_ALERT_FAILED, payload: '' })
        }
    }
}
