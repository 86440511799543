import { useSelector } from 'react-redux'
import { setCookie } from '../../helpers/common'
import { createGetSelectDataAction } from '../../packages/SearchResults/components/SearchFilter/Sagas/initialData/initialDataActions'
import { createSearchJobsAction } from '../../packages/SearchResults/components/SearchFilter/Sagas/searchFilterJobs/searchFilterActions'
import filterHelper, { TSelectData } from '../../packages/SearchResults/helpers/filterHelper'
import { localParamsHelper } from '../../packages/SearchResults/helpers/localParamsHelper'
import { urlParamSync } from '../../packages/SearchResults/helpers/urlParamSync'
import searchResultsStore from '../../packages/SearchResults/searchResultsStore'
import { EPageTab } from '../../packages/SearchResults/types/EPageTag'
import { ISearchFilterParams } from '../../packages/SearchResults/types/ISearchFilterParams'
import { IUrlSyncOptions } from '../../packages/SearchResults/types/IUrlSyncOptions'
import { isAllJobsPath, isManagementJobPath } from '../../packages/SearchResults/utils/routeRegex'

import { TAG } from './constant'
import { initialDataProvider } from '@microfrontends/app-shell-v2'
import { ENestedDatasetName, TNestedGenericId } from '@microfrontends/react-components'
import { isIgnoreLocation } from '../../packages/SearchResults/helpers/url'
import { getCookie } from '../../packages/SearchResults/utils/utilities'

export interface ISearchEvent extends Event {
    detail: {
        params: Partial<ISearchFilterParams>
        from: string
        hideFilter?: boolean
        clearCurrentParams?: boolean
        forceUrlSync?: boolean // setting this to true, will sync the search the URL, and vice-versa. default to `true`
        fromRouting?: boolean
    }
}

const buildUrlSyncOptions = (forceSync: boolean = true, language: string) => {
    return urlParamSync.buildUrlSyncOptions(language, forceSync)
}

const search = (params: ISearchFilterParams, urlSyncOptions: IUrlSyncOptions): void => {
    const clonedParams = JSON.parse(JSON.stringify(params))
    // Dispatch to the redux store
    searchResultsStore.dispatch(createSearchJobsAction(clonedParams, urlSyncOptions))

    // Update location cookie
    // If there is location in search params then we should save to user cookie
    if (clonedParams.locationId.length === 0) {
        setCookie('VNWSearchJob[city]', 'all', 30)
    } else {
        let cookieValue = clonedParams.locationId.join(',')
        if (cookieValue.toString() === 'NaN') {
            cookieValue = 'all'
        }
        setCookie('VNWSearchJob[city]', cookieValue, 30)
    }

    if (typeof window !== 'undefined') {
        // Scroll to top
        window.scrollTo(0, 0)
    }

    // Clear the search query
    // TODO: [Refactor] move this to urlParamsSync
    if (typeof window !== 'undefined') {
        const currentURL = new URL(window.location.href)
        const searchParams = currentURL.searchParams
        if (searchParams.has('setNewLocation')) {
            searchParams.delete('setNewLocation')
            history.pushState(null, document.title, currentURL.toString())
        }
    }
}

const handleGlobalSearchEvent =
    (pageTab: EPageTab, currentParams: ISearchFilterParams, language: string) => (event) => {
        const isInterviewPage = pageTab === EPageTab.INTERVIEW
        // Since the Interview page request data differently,
        // so we do not handle interview SEARCH events here
        if (!isInterviewPage) {
            const defaultParams: ISearchFilterParams = filterHelper.getDefaultSearchFilterParams()
            const e = event as ISearchEvent
            const eventParams = e.detail.params
            const from = e.detail.from
            const clearCurrentParams = e.detail.clearCurrentParams
            const urlSyncOptions = buildUrlSyncOptions(e.detail.forceUrlSync, language)
            // If the first search has employerId, the next search will remove this param
            // because we don't want user search/filter on employer job list
            if (currentParams.userId > 0) {
                currentParams.userId = 0
            }


            const mergedParams =
                clearCurrentParams === true
                    ? Object.assign({}, defaultParams, eventParams)
                    : Object.assign({}, currentParams, eventParams)

            from &&
                console.debug(
                    '%c[SearchFilter] Global Search Event > from',
                    'color: #4CAF50',
                    from,
                    'mergedParams:',
                    mergedParams,
                    'urlSyncOptions:',
                    urlSyncOptions
                )
            search(mergedParams, urlSyncOptions) // TODO: can make urlSyncOptions optional
        }
    }

export const addGlobalSearchEventListener = (
    pageTab: EPageTab,
    currentParams: ISearchFilterParams,
    language: string
) => {
    if (typeof window === 'undefined') return
    window.addEventListener('SEARCH', handleGlobalSearchEvent(pageTab, currentParams, language))
}

export const removeGlobalSearchEventListener = (
    pageTab: EPageTab,
    currentParams: ISearchFilterParams,
    language: string
) => {
    if (typeof window === 'undefined') return
    window.removeEventListener('SEARCH', handleGlobalSearchEvent(pageTab, currentParams, language))
}

export const fireGlobalSearchEvent = (
    params: Partial<ISearchFilterParams>,
    from: string,
    hideFilter: boolean = true,
    clearCurrentParams: boolean = false,
    forceUrlSync: boolean = true,
    fromRouting: boolean = false
) => {
    if (typeof window !== 'undefined') {
        const searchEvent = new CustomEvent('SEARCH', {
            detail: {
                params: {
                    ...params,
                    flatJobFunctionIds: initialDataProvider.getChildrenIdsFromNestedIds(
                        params.jobFunctionId || [],
                        ENestedDatasetName.JOB_FUNCTION
                    )
                },
                from,
                hideFilter,
                clearCurrentParams,
                forceUrlSync,
                fromRouting
            }
        })
        window.dispatchEvent(searchEvent)
    }
}
export const searchJobsFromURLandLocalStorageParams =
    (forceLocationPath: boolean = false, lastParams: ISearchFilterParams, pageTab: EPageTab) =>
    () => {
        const locationPath =
            forceLocationPath || typeof window !== 'undefined' ? window.location.pathname + window.location.search : ''
        const isAllJobs = isAllJobsPath(locationPath)
        const isManagementPath = isManagementJobPath(locationPath)
        const selectData = useSelector<any, TSelectData>((state) => {
            return state.searchFilter.selectData
        })

        /**
         * There are 2 big cases here, that cause `componentDidMount`,
         * which then execute this method:
         *      1. Coming back from another pageTab, e.g. Interview, the params from the Redux store
         *      might store some old params from the previous search
         *      2. Newly loaded, in this case, the params from the redux store would be empty/default
         *      we will get the params from the URL
         */

        /**
         * Big Case 1: Coming back from another pageTab, e.g. Interview,
         * we'll need to get `query` and `location` from the previous search
         *
         * @Note: Coming back from the management job page does not trigger `componentDidMount`
         * since `management job page` uses the same components as the normal jobs page.
         */
        const anyParamWasSet = filterHelper.checkIfAnyParamWasSet(lastParams)
        const urlSearchParams = urlParamSync.collectSearchConditionFromUrl(locationPath)
        const params = urlParamSync.collectFilterDataFromUrl(locationPath, selectData)

        if (params.query !== '') urlSearchParams.query = params.query
        if (params.categoryId.length > 0) urlSearchParams.categoryId = params.categoryId
        if (params.locationId.length > 0) urlSearchParams.locationId = params.locationId
        if (params.isUrgentJob !== undefined) urlSearchParams.isUrgentJob = params.isUrgentJob
        if (params.typeWorkingId !== -1) urlSearchParams.typeWorkingId = params.typeWorkingId
        if (params.jobLevelId !== -1) urlSearchParams.jobLevelId = params.jobLevelId
        if (params.page !== 0) urlSearchParams.page = params.page
        urlSearchParams.companyIndustries = params.companyIndustries
        urlSearchParams.jobFunctionId = params.jobFunctionId.concat(
            urlParamSync.mappingCategoryIdToJobFunctionId(urlSearchParams.categoryId)
        )

        // This also means it's not the first page load, because in that case,
        // the redux store would be empty/default
        if (anyParamWasSet) {
            const defaultParams = filterHelper.getDefaultSearchFilterParams()
            const isNormalJobPage = pageTab === EPageTab.JOBS
            const isManagementJobPage = pageTab === EPageTab.MANAGEMENT_JOBS
            // Search normal jobs
            if (isNormalJobPage) {
                const params: Partial<ISearchFilterParams> = {
                    ...defaultParams, // Biz Rule: reset all
                    query: lastParams.query, // Biz Rule: keep the keyword query
                    locationId: lastParams.locationId, // Biz Rule: keep the location
                    categoryId: lastParams.categoryId, // NEW Biz Rule: keep category VNW-17948
                    typeWorkingId: lastParams.typeWorkingId, // VNW-18179 Keep type working id
                    jobLevelId: lastParams.jobLevelId, // VNW-18179 Keep job level
                    isUrgentJob: lastParams.isUrgentJob, // VNW-18179 Keep urgent job
                    salary: lastParams.salary, // VNW-18179 Salary
                    benefitIds: lastParams.benefitIds, // VNW-18179 Benefit
                    page: urlSearchParams.page,
                    companyIndustries: urlSearchParams.companyIndustries,
                    districtId: lastParams.districtId
                }

                fireGlobalSearchEvent(params, 'searchJobsFromRouting', false, !!forceLocationPath)
            } else if (isManagementJobPage) {
                // Search management jobs
                const defaultManagementJobParams = filterHelper.getDefaultManagementJobParams()
                const params: Partial<ISearchFilterParams> = {
                    ...defaultParams, // Biz Rule: reset all
                    ...defaultManagementJobParams,
                    query: lastParams.query, // Biz Rule: keep the keyword query
                    locationId: lastParams.locationId, // Biz Rule: keep the location
                    districtId: lastParams.districtId
                }

                fireGlobalSearchEvent(params, 'searchJobsFromRouting', false, !!forceLocationPath)
            }
        } else {
            /**
             * Big Case 2: Newly loaded
             *
             * Biz rules:
             * 1. If URL contains the params, use them
             * 2. If URL does not contains:
             *      - If users have not selected a location, use the detected location
             *      - If users have selected a location, which ever it it, use it.
             *      - Note: The detected location is stored in cookie
             */

            // get query param 'page' from url
            const searchQuery = typeof window !== 'undefined' ? window.location.search : ''
            if (searchQuery !== '') {
                const rawParamsArray = searchQuery?.replace('?', '')
                const paramsArray = rawParamsArray.split('&')
                for (let i = 0; i < paramsArray.length; i++) {
                    const paramInfo = paramsArray[i].split('=')
                    if (paramInfo[0] === 'page') {
                        // Minus 1 (- 1) is because our site is using page from 0 to ...
                        const pageNumber = parseInt(paramInfo[1]) - 1
                        if (pageNumber > 0) {
                            urlSearchParams.page = pageNumber
                        }
                        break
                    }
                }
            }
            const defaultParams = filterHelper.getDefaultSearchFilterParams()
            const detectedLocation: number[] = (() => {
                const location: number[] = []
                let cityCookie = getCookie('VNWSearchJob[city]')
                /**
                 *  JIRA VNW-14082 Search with location when using "Hot categories"
                 *
                 *  Detect if there is a query param on url that match: 'location' then replace it with cookie 'VNWSearchJob[city]'
                 *
                 *  @author Tran Thien
                 *  @since Sep, 8 2021
                 */
                if (isIgnoreLocation()) {
                    cityCookie = 'all'
                }
                if (cityCookie !== null && cityCookie !== 'all') {
                    const cityCookieRaw = cityCookie.split(',')
                    cityCookieRaw.map((item) => location.push(parseInt(item)))
                }
                return location
            })()
            const userHasSelectedALocation: boolean = (() => {
                const urlParams = new URLSearchParams(window?.location?.search)
                return urlParams.has('setNewLocation')
            })()

            let searchParams: ISearchFilterParams = filterHelper.getDefaultSearchFilterParams()
            {
                /**
                 * Biz Rule 1: use URL Params
                 */
                searchParams = {
                    ...defaultParams,
                    ...urlSearchParams
                }

                // Also need to clear the cookie if we clear the keyword, because the homepage is using this cooking to display the recent search
                if (isAllJobs) setCookie('VNWSearchJob[keyword]', '')
            }
            const localSearchParams = localParamsHelper.getParams()

            /**
             * Biz Rule 2: If URL does not contain location info
             *      - Users have chosen a location -> use it, (it's from the URL, since it was synced there)
             *      - Otherwise use detected location
             */

            if (
                urlSearchParams !== undefined &&
                urlSearchParams.locationId !== undefined &&
                urlSearchParams.locationId?.length === 0
            ) {
                const desireLocationId = userHasSelectedALocation ? urlSearchParams.locationId : detectedLocation
                searchParams = {
                    ...searchParams,
                    ...{ locationId: desireLocationId }
                }
            }

            /**
             * Biz Rule 3: In case of having employer ID in URL, reset all other search params to the default values
             */
            if (urlSearchParams.userId && urlSearchParams.userId > 0) {
                searchParams = {
                    ...defaultParams,
                    ...{ userId: urlSearchParams.userId }
                }
            }

            /**
             * Biz Rule 4: If searching management jobs:
             *      - We need to update the conditions for the management jobs
             *      - Use the local params for `locationId` and `query`, because in this case,
             *      the URL does not contains these info
             */
            if (isManagementPath) {
                const localSearchParams = localParamsHelper.getParams()
                const defaultManagementJobParams = filterHelper.getDefaultManagementJobParams()
                searchParams = {
                    ...searchParams,
                    ...defaultManagementJobParams,
                    query: localSearchParams.query,
                    locationId: localSearchParams.locationId
                }
            }
            /**
             * Fix VNW-15670 read category from localStorage and map to searchParams
             */

            searchParams = {
                ...searchParams,
                categoryId: localSearchParams.categoryId
            }
            // Check date here
            if (localSearchParams.clearDate !== undefined && localSearchParams.clearDate !== '') {
                const today: any = new Date(Date.now())
                const beforeDate: any = new Date(localSearchParams.clearDate)
                const diffTime = Math.abs(today - beforeDate)
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
                if (diffDays > 1) {
                    // reset
                    searchParams = {
                        ...searchParams,
                        benefitIds: [],
                        salary: [-1, -1]
                    }
                    // categoryId
                    if (urlSearchParams.categoryId.length < 0 || searchParams.categoryId.length > 0) {
                        searchParams = {
                            ...searchParams,
                            categoryId: []
                        }
                    }
                    // is Urgent
                    if (!urlSearchParams.isUrgentJob) {
                        searchParams = {
                            ...searchParams,
                            isUrgentJob: false
                        }
                    }
                    // locationId
                    if (urlSearchParams.locationId.length < 0) {
                        searchParams = {
                            ...searchParams,
                            locationId: []
                        }
                    }
                    // jobLevelId
                    if (urlSearchParams.jobLevelId === -1) {
                        searchParams = {
                            ...searchParams,
                            jobLevelId: -1
                        }
                    }
                    // userId
                    if (urlSearchParams.userId === 0) {
                        searchParams = {
                            ...searchParams,
                            userId: 0
                        }
                    }
                }
            }
            if (urlSearchParams.categoryId !== undefined && urlSearchParams.categoryId?.length > 0) {
                searchParams = {
                    ...searchParams,
                    categoryId: urlSearchParams.categoryId
                }
            }
            /**
             * Finally, we fire the search event
             */

            fireGlobalSearchEvent(
                searchParams,
                `${TAG} > componentDidMount > searchJobsFromURLandLocalStorageParams`,
                false,
                forceLocationPath,
                true,
                true
            )
        }
    }

export const getSelectDataThenSearchWithSavedParams = (
    forceLocationPath = null,
    language: string,
    lastParams: ISearchFilterParams,
    pageTab: EPageTab
) => {
    const callback = searchJobsFromURLandLocalStorageParams(forceLocationPath, lastParams, pageTab)
    searchResultsStore.dispatch(createGetSelectDataAction(callback, language, forceLocationPath))
}

export const getLocationsByLocationIdsAndDistrictIds = (
    locationIds: number[],
    districtIds: number[]
): TNestedGenericId[] => {
    const locations: TNestedGenericId[] = []
    try {
        const districts = initialDataProvider.getDistricts()

        const checkDistrictInLocations = (locationsId: number) => {
            let isInclude = false
            districtIds.forEach((id) => {
                const check = districts[locationsId].map((item) => item.id).includes(id)
                if (check) {
                    isInclude = true
                }
            })
            return isInclude
        }
        locationIds.forEach((id) => {
            if (!checkDistrictInLocations(id)) {
                locations.push({
                    parentId: id,
                    childrenIds: [-1]
                })
            } else {
                const filteredDistrict = districts[id]?.filter((d) => districtIds.includes(d.id))
                if (filteredDistrict && filteredDistrict.length > 0) {
                    locations.push({
                        parentId: id,
                        childrenIds: filteredDistrict.map((d) => d.id)
                    })
                }
            }
        })
        return locations
    } catch (error) {
        console.error(error)
        return locations
    }
}
