// Algolia Indexes
export const SORT_DEFAULT_INDEX = process.env.SORT_DEFAULT_INDEX ||process.env.ALGOLIA_DEFAULT_INDEX || ''
export const SORT_RELEVANCE_INDEX = process.env.SORT_RELEVANCE_INDEX || process.env.ALGOLIA_RELEVANCE_INDEX || ''
export const SORT_SALARY_INDEX = process.env.SORT_SALARY_INDEX || process.env.ALGOLIA_SALARY_INDEX || ''
export const SORT_POSTED_LATEST_INDEX = process.env.SORT_POSTED_LATEST_INDEX || process.env.ALGOLIA_POSTED_LATEST_INDEX || ''
export const SORT_POSTED_OLDEST_INDEX = process.env.SORT_POSTED_OLDEST_INDEX || process.env.ALGOLIA_POSTED_OLDEST_INDEX || ''

export const MANAGEMENT_JOB_DEFAULT = 'MANAGEMENT_JOB_DEFAULT'

export const MANAGEMENT_JOB_BY_DATE = 'MANAGEMENT_JOB_BY_DATE '
export const MANAGEMENT_JOB_BY_SALARY = 'MANAGEMENT_JOB_BY_SALARY '

// Others
/**
 * VNW-16105 [Web][Search result][Sort by default] Change order of search job results
 *
 * @author ThaiTQ
 * @since Apr 22 2022
 */
export const HITS_PER_PAGE = 50
export const HITS_PER_SUB_PAGE = 50
export const RESTRICT_SEARCHABLE_ATTRIBUTES = ['jobTitle', 'skills', 'company']
export const VNW_LOGIN_URL_EN = process.env.VNW_LOGIN_URL_EN
export const VNW_LOGIN_URL_VI = process.env.VNW_LOGIN_URL_VI
export const INTERVIEW_REQUEST_FORM_EN = process.env.INTERVIEW_REQUEST_FORM_EN || ''
export const INTERVIEW_REQUEST_FORM_VI = process.env.INTERVIEW_REQUEST_FORM_VI || ''

export const AI_VER_V1 = 'ai-ver1'
