export const PUT_SELECT_DATA = 'PUT_SELECT_DATA'
export const GET_SELECT_DATA = 'GET_SELECT_DATA'

// Get initial select data
export const createGetSelectDataAction = (callback?, language = 'vi', path = null) => ({
    type: GET_SELECT_DATA,
    callback: callback,
    language: language,
    path: path
})
