import { put, takeLatest } from 'redux-saga/effects'
import { GET_JOB_ALERT_LIST_FAILED, getJobAlertListSuccess, REQUEST_GET_JOB_ALERT_LIST } from '../jobAlertActions'
import jobAlertApi from './../services/jobAlertApi'
import * as Sentry from '@sentry/node'

export function* watchGetJobAlertList() {
    yield takeLatest(REQUEST_GET_JOB_ALERT_LIST, getJobAlertList)
}

function* getJobAlertList() {
    try {
        const list = yield jobAlertApi.getList()

        yield put(getJobAlertListSuccess(list))
    } catch (err) {
        Sentry.captureException(err)

        yield put({ type: GET_JOB_ALERT_LIST_FAILED, payload: '' })
    }
}
