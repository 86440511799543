const RECENT_SEARCH_STORAGE_KEY = process.env.RECENT_SEARCH_STORAGE_KEY || '_recent_searches_default'

export type TRecentSearch = {
    keyword: string
    totalJobs?: number
}

export function storeRecentSearch(searchItem: TRecentSearch) {
    if (searchItem.keyword !== '' && searchItem.keyword != null) {
        const recentSearchesStored: string | null = localStorage.getItem(RECENT_SEARCH_STORAGE_KEY)

        let recentSearches: TRecentSearch[] = []
        if (!!recentSearchesStored && recentSearchesStored !== '') {
            recentSearches = JSON.parse(recentSearchesStored)
        }

        // remove the same keyword in recent search list
        recentSearches = recentSearches.filter((item: any) => item.keyword != searchItem.keyword)
        recentSearches.push(searchItem)

        if (recentSearches.length > 5) {
            recentSearches.shift()
        }

        localStorage.setItem(RECENT_SEARCH_STORAGE_KEY, JSON.stringify(recentSearches))
        const event = new Event('RECENT_SEARCH_UPDATED')
        window.dispatchEvent(event)
    }
}

export function clearRecentSearch() {
    localStorage.setItem(RECENT_SEARCH_STORAGE_KEY, JSON.stringify([]))
    const event = new Event('RECENT_SEARCH_CLEARED')
    window.dispatchEvent(event)
}
