import { useHog } from '@microfrontends/react-components'
import React, { useEffect } from 'react'

type THogTrackingP = {
    eventName: string
    properties: any
}

const HogTracking = (props: THogTrackingP) => {
    const { eventName, properties } = props
    const { tracking } = useHog()

    useEffect(() => {
        if (properties) {
            tracking({
                eventName,
                properties
            })
        }
    }, [properties])

    return <></>
}

export default HogTracking
