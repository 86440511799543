import { Utils, dateToString } from '@microfrontends/react-components'
import React, { RefObject } from 'react'
import { connect } from 'react-redux'
import { MANAGEMENT_JOB_BY_DATE, MANAGEMENT_JOB_BY_SALARY, MANAGEMENT_JOB_DEFAULT } from '../../../../env'
import filterHelper from '../../helpers/filterHelper'
import { EPageTab } from '../../types/EPageTag'
import { ISEOSearch } from '../../types/ISearchFilterParams'
import withComponentTranslation from '../../utils/translatorHOC'
import JobAlerts from '../JobAlerts'
import vi from './vi.json'
import JobHeadStyles from './JobHead.css'
import { expandElementAnimation } from './expandElementAnimation'
import { ArrowDown, ArrowUp } from './images'
import QuickJobAlert from '../QuickJobAlert'
import { urlParamSync } from '../../helpers/urlParamSync'
import { ELanguage } from '../../utils/language'
import { internalSortByUrlParamMap } from '../SearchFilter/constants'
import { initialDataProvider } from '@microfrontends/app-shell-v2'
import { TSelectDataItem } from '@microfrontends/react-components/components/types'
import HogTracking from './HogTracking'
import SearchSorting, { EMostRelevantPositionType } from '../../../SearchSorting'
import { TAuthUser } from '../../../../handlers/auth/interface'

export const SORT_DEFAULT_INDEX =
    process.env.SORT_DEFAULT_INDEX || process.env.ALGOLIA_DEFAULT_INDEX || 'vnw_job_new_staging'
const SORT_RELEVANCE_INDEX =
    process.env.SORT_RELEVANCE_INDEX || process.env.ALGOLIA_RELEVANCE_INDEX || 'vnw_job_new_staging_rel'
const SORT_SALARY_INDEX = process.env.SORT_SALARY_INDEX || process.env.ALGOLIA_SALARY_INDEX || 'vnw_job_new_staging_sal'
const SORT_POSTED_LATEST_INDEX =
    process.env.SORT_POSTED_LATEST_INDEX || process.env.ALGOLIA_POSTED_LATEST_INDEX || 'vnw_job_new_staging_latest'
const SORT_POSTED_OLDEST_INDEX =
    process.env.SORT_POSTED_OLDEST_INDEX || process.env.ALGOLIA_POSTED_OLDEST_INDEX || 'vnw_job_new_staging_oldest'

export interface IJobHeadProps {
    searchResults: any
    searchConditions: any
    metaCategory: any
    language: ELanguage
    translate: (term: string) => string
    seoSearch: ISEOSearch
    showJobAlertModal: boolean
    onCloseJobAlertModal: () => void
    stopShowFloatingRef: RefObject<HTMLDivElement>
    showSearchBarWithFilters: () => void
    isOnCompanyProfiles: boolean
    pageTab: EPageTab
    onSkillTagClick: (keyword: string) => void
    skillTags: { key: string; doc_count: number }[]
    user: TAuthUser
}

export interface IJobHeadStates {
    isMobile: boolean
    isMobile768: boolean
    skillTagExpand: boolean
    showSkillTagToggle: boolean
    sortId: string | number
    keyword: string
    location: {
        id: number
        name: string
    }[]
    hogTracking: {
        keyword: string
        itemNum: number
    }
}

class JobHead extends React.Component<IJobHeadProps, IJobHeadStates> {
    oldParams: any
    expandRef: RefObject<HTMLDivElement>
    toggleExpandRef: RefObject<HTMLDivElement>
    skillTagsContainerRef: RefObject<HTMLDivElement>

    /**
     * 648 Jobs matches in IT - Software jobs now hiring in Ho Chi Minh
     */
    constructor(props) {
        super(props)
        this.state = {
            isMobile: false,
            isMobile768: false,
            skillTagExpand: false,
            showSkillTagToggle: false,
            sortId: props.seoSearch?.indexName || SORT_DEFAULT_INDEX,
            keyword: '',
            location: [],
            hogTracking: {
                keyword: '',
                itemNum: 0
            }
        }
        this.expandRef = React.createRef()
        this.toggleExpandRef = React.createRef()
        this.skillTagsContainerRef = React.createRef()
    }

    _handleGlobalSearchEvent(e) {
        const sortIdFromParams = internalSortByUrlParamMap[e.detail.params.indexName || SORT_DEFAULT_INDEX]
        if (sortIdFromParams !== this.state.sortId) {
            this.setState({ sortId: sortIdFromParams })
        }
    }

    renderJobCounting = () => {
        const { translate, seoSearch, searchConditions, searchResults, language } = this.props
        // location
        const locationIdList: number[] = []

        seoSearch.location &&
            seoSearch.location.map((item) => {
                locationIdList.push(item.id)
            })
        // category
        const categoryIdList: number[] = []
        seoSearch.category &&
            seoSearch.category.map((item) => {
                categoryIdList.push(item.id)
            })
        const isWebBrowser = typeof window !== 'object'
        const searchData =
            seoSearch && isWebBrowser
                ? {
                      categoryId: categoryIdList,
                      locationId: locationIdList,
                      userId: 0, // TODO still need to find employer id
                      nbHits: (seoSearch.searchResultData && seoSearch.searchResultData.nbHits) || 0
                  }
                : searchConditions

        const noOfJobs = searchResults.nbHits || searchData.nbHits

        if (!noOfJobs) {
            return <span>&nbsp;</span>
        }

        // search Job Keyword
        const searchedKeyword: string = searchResults.query || ''
        const locationIds = searchConditions?.locationId ?? []
        const districtIds = searchConditions?.districtId ?? []
        const locationNames = initialDataProvider.getLocationNameFromIds(locationIds)
        const locationText = locationNames.map((item: TSelectDataItem) =>
            language === ELanguage.VI ? item.name : item.nameEn
        )
        const districtName = districtIds.length <= 1 ? initialDataProvider.getDistrictNameFromIds(districtIds[0]) : null
        const nowTimeInString = dateToString(null)

        const renderHeadTitle = () => {
            const isManagementJob = this.props.pageTab === EPageTab.MANAGEMENT_JOBS

            let isRenderMoreDetail = searchedKeyword === ''
            if (locationIds.length > 1 || (searchedKeyword === '' && locationIds.length === 0)) {
                isRenderMoreDetail = false
            }

            if (!isRenderMoreDetail) {
                return (
                    <>
                        {isManagementJob ? (
                            <> {translate(`management job${noOfJobs > 1 ? 's' : ''}`)}</>
                        ) : (
                            translate(`job${noOfJobs > 1 ? 's' : ''}`)
                        )}
                        {searchedKeyword ? (
                            <strong title={searchedKeyword}>&nbsp;&quot;{searchedKeyword}&quot;</strong>
                        ) : (
                            ''
                        )}{' '}
                        {translate('matched')}
                    </>
                )
            }

            return (
                <>
                    {translate(`job${noOfJobs > 1 ? 's' : ''}`)} {translate('at')}{' '}
                    {districtName != null ? districtName?.name + `,` : seoSearch.districtName} {locationText}{' '}
                    {translate('matched')}, {translate('updated on')} {nowTimeInString}
                </>
            )
        }

        return (
            <span className='animated fadeIn'>
                <h1 className='job-matched'>
                    <span className='no-of-jobs'>
                        <strong>{`${noOfJobs} `}</strong>
                    </span>
                    {renderHeadTitle()}
                </h1>
            </span>
        )
    }

    handleSkillTagExpand = () => {
        this.setState({ skillTagExpand: !this.state.skillTagExpand })
    }

    handleSkillTagClick = (item, isMannageJob: boolean, index: number) => {
        // reset sortBy when change tag
        this.setState({ skillTagExpand: false })
        this.expandRef.current.classList.add('skill-tag-collapse')
        this.props.onSkillTagClick(item.key)

        // set sortId to default
        const sortId = isMannageJob ? MANAGEMENT_JOB_DEFAULT : SORT_DEFAULT_INDEX
        this.setState({
            ...this.state,
            sortId,
            hogTracking: {
                keyword: item.key,
                itemNum: index
            }
        })
    }

    checkResized = () => {
        if (window.innerWidth < 992) this.setState({ isMobile: true })
        else this.setState({ isMobile: false })
    }

    checkResized768 = () => {
        if (window.innerWidth < 768) this.setState({ isMobile768: true })
        else this.setState({ isMobile768: false })
    }

    handleSortOptions = () => {
        const { translate } = this.props
        const isManagementJob = this.props.pageTab === EPageTab.MANAGEMENT_JOBS

        const normalJobsSortOptions = [
            {
                id: SORT_DEFAULT_INDEX,
                name: translate('Default')
            },
            {
                id: SORT_RELEVANCE_INDEX,
                name: translate('Most relevant')
            },
            {
                id: SORT_SALARY_INDEX,
                name: translate('Salary (high - low)')
            },
            {
                id: SORT_POSTED_LATEST_INDEX,
                name: translate('Posted date (latest)')
            },
            {
                id: SORT_POSTED_OLDEST_INDEX,
                name: translate('Posted date (oldest)')
            }
        ]

        const managementJobsSortOptions = [
            {
                id: MANAGEMENT_JOB_DEFAULT,
                name: translate('Default')
            },
            {
                id: MANAGEMENT_JOB_BY_SALARY,
                name: translate('Salary (high - low)')
            },
            {
                id: MANAGEMENT_JOB_BY_DATE,
                name: translate('Posted date (latest)')
            }
        ]

        return isManagementJob ? managementJobsSortOptions : normalJobsSortOptions
    }

    createLinkSkillTag = (name: string, seoSearch: ISEOSearch, language: ELanguage) => {
        const searchDefault = filterHelper.getDefaultSearchFilterParams()

        const searchParams = {
            ...searchDefault,
            query: Utils.transformToLatin(name.toLocaleLowerCase()),
            jobLevelId: seoSearch.jobLevel.id,
            locationId: seoSearch.location.map(({ id }) => id),
            categoryId: seoSearch.category.map((item) => item.id)
        }

        const searchPath = urlParamSync.buildSearchPath(searchParams, seoSearch.selectData, language)
        const link = `${process.env.VNW_BASE_URL}${searchPath}`

        return link
    }

    mapLinkToSkillTags = (
        skillTags: { key: string; doc_count: number }[],
        seoSearch: ISEOSearch,
        language: ELanguage
    ) => {
        const skillTagsMapLink = skillTags?.map((item) => ({
            ...item,
            link: this.createLinkSkillTag(item.key, seoSearch, language)
        }))

        return skillTagsMapLink
    }

    render() {
        const { language, isOnCompanyProfiles, translate, searchResults, seoSearch } = this.props
        const isMannageJob = this.props.pageTab === EPageTab.MANAGEMENT_JOBS

        const jobCounting = this.renderJobCounting()
        const { nbHits = 0 } = searchResults
        const skillTags = this.mapLinkToSkillTags(this.props?.skillTags, seoSearch, language)

        return (
            <JobHeadStyles>
                <HogTracking
                    eventName='js_search_result_page:relevant_keywords_click'
                    properties={this.state.hogTracking.keyword ? this.state.hogTracking : null}
                />

                <div className='job-head' style={{ marginTop: isOnCompanyProfiles ? '16px' : '0' }}>
                    <div className='container-fluid job-head-component p-0'>
                        <div className='job-alerts-wrapper row'>
                            <div>
                                <JobAlerts
                                    language={language}
                                    showModal={this.props.showJobAlertModal || false}
                                    onCloseModal={this.props.onCloseJobAlertModal}
                                    stopShowFloatingRef={this.props.stopShowFloatingRef}
                                />
                                {jobCounting}
                            </div>
                            <div>
                                {nbHits > 0 && !this.state.isMobile ? <QuickJobAlert isMobileProp={false} /> : <></>}
                            </div>
                        </div>

                        <div
                            className='skill-tag-wrapper row'
                            style={{ display: skillTags?.length > 0 ? 'flex' : 'none' }}
                        >
                            <div className='skill-tag-toggle'>
                                <div className='toggle-name'>{translate('Relevant keywords')}</div>
                                <div className='toggle-arrow' ref={this.toggleExpandRef}>
                                    {this.state.skillTagExpand ? <ArrowUp /> : <ArrowDown />}
                                </div>
                            </div>

                            <div className='skill-tag-details skill-tag-collapse' ref={this.expandRef}>
                                <div className='skill-tag-container' ref={this.skillTagsContainerRef}>
                                    {skillTags?.slice(0, 15).map((item, idx) => (
                                        <div
                                            key={idx}
                                            className='tag-wrapper'
                                            onClick={() => this.handleSkillTagClick(item, isMannageJob, idx)}
                                        >
                                            {item.key}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='job-sorting-section'>
                    <SearchSorting
                        sortId={this.state.sortId}
                        language={language}
                        isManagementJob={this.props.pageTab === EPageTab.MANAGEMENT_JOBS}
                        isHighlightTooltip={false}
                        positionRender={EMostRelevantPositionType.OTHER}
                        setHighlightTooltip={() => {}}
                        userId={this.props.searchConditions?.userId || 0}
                        user={this.props.user}
                    />
                </div>
            </JobHeadStyles>
        )
    }

    componentDidMount(): void {
        window.addEventListener('resize', this.checkResized)
        window.addEventListener('resize', this.checkResized768)
        window.addEventListener('SEARCH', this._handleGlobalSearchEvent.bind(this))
        this.checkResized()
        this.checkResized768()

        this.toggleExpandRef.current.addEventListener('click', () => {
            this.setState({ skillTagExpand: !this.state.skillTagExpand })
            expandElementAnimation(this.expandRef.current, 'skill-tag-collapse')
        })
    }

    componentDidUpdate(prevProps): void {
        // update sort when change tab
        if (prevProps.pageTab !== 'UNSET' && this.props.pageTab !== prevProps.pageTab) {
            const isMannageJob = this.props.pageTab === EPageTab.MANAGEMENT_JOBS
            const sortId = isMannageJob ? MANAGEMENT_JOB_DEFAULT : SORT_DEFAULT_INDEX
            this.setState({ ...this.state, sortId })
        }

        if (this.skillTagsContainerRef.current.offsetHeight > 24) {
            this.toggleExpandRef.current.style.display = 'flex'
        } else {
            this.toggleExpandRef.current.style.display = 'none'
        }
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.checkResized)
        window.removeEventListener('resize', this.checkResized768)
    }
}

const mapStateToProps = (state) => {
    const searchResults = state.searchFilter.searchResult || {}
    const searchConditions = state.searchFilter.lastParams || {}
    const metaCategory = state.searchFilter.selectData || {}
    return {
        searchResults,
        searchConditions,
        metaCategory,
        pageTab: state.globalMisc.pageTab
    }
}

const componentWithTranslation = withComponentTranslation(vi)(JobHead)

export default connect(mapStateToProps)(componentWithTranslation)
