import { decrypt } from '../../../../../handlers/auth/cryptor'
import { ELanguage, TLanguage } from '../../../utils/language'
import { EJobAlertSendingPeriod, TJobAlertItem, TSubmitJobAlert, TSubmitUpdateJobAlert } from './../types'

const API_BASE = process.env.GATEWAY_API_URL || ''

class JobAlertApi {
    apiBase: string
    accessToken: string | null

    constructor(apiBase: string) {
        this.apiBase = apiBase

        this._callApi = this._callApi.bind(this)
    }

    _buildApiPath(path: string) {
        return `${this.apiBase}/${path}`
    }

    setAccessToken(encryptedToken: string | null) {
        if (encryptedToken) {
            this.accessToken = decrypt(encryptedToken)
        } else {
            this.accessToken = null
        }
    }

    async _callApi(url: string, method: string = 'get', data?: {}): Promise<any> {
        try {
            const defaultRequestOptions: any = {
                method: method,
                headers: {
                    'Content-Type': 'application/json'
                }
            }

            const requestOptions = Object.assign({}, defaultRequestOptions)
            if (data) {
                requestOptions.body = JSON.stringify(data)
            }

            if (this.accessToken) {
                requestOptions.headers.Authorization = `Bearer ${this.accessToken}`
            }

            const res = await fetch(url, requestOptions)

            if (res.status === 200) {
                const response = await this._handleResponse(res)
                return response
            } else {
                return []
            }
        } catch (ex) {
            console.error(ex)
            return []
        }
    }

    async _handleResponse(res: Response): Promise<TJobAlertItem[] | {}> {
        const resBody = await res.json()
        const data = resBody.data

        if (data) {
            if (Array.isArray(data)) {
                return data.map(
                    (item: any): TJobAlertItem => {
                        return this._transformJobAlertResponseData(item)
                    }
                )
            } else {
                if (data.type === 'jobAlert') {
                    return [this._transformJobAlertResponseData(data)]
                }

                return data.attributes ? [{ ...data.attributes }] : []
            }
        } else {
            return ['SUCCESS', 200].includes(resBody.Code) ? [resBody] : []
        }
    }

    async getList(): Promise<any[]> {
        const apiPath = this._buildApiPath(`job-alerts?v=${Date.now()}`)
        const jobAlertList = await this._callApi(apiPath)
        return jobAlertList
    }

    async createJobAlert(submitData: TSubmitJobAlert, language: TLanguage): Promise<any[]> {
        const isAnonymous = !this.accessToken

        const apiFunc = isAnonymous ? 'job-alerts-anonymous' : 'job-alerts'

        const apiPath = this._buildApiPath(apiFunc)

        const postData = this._transformJobAlertPostData(submitData, language)

        const result = await this._callApi(apiPath, 'post', postData)

        return result
    }

    async updateJobAlert(submitData: TSubmitUpdateJobAlert, language: TLanguage): Promise<any[]> {
        const apiPath = this._buildApiPath(`/job-alert/${submitData.id}`)

        const postData = this._transformJobAlertPostData(submitData, language)

        const result = await this._callApi(apiPath, 'put', postData)
        return result
    }

    async deleteJobAlert(id: number): Promise<any[]> {
        const apiPath = this._buildApiPath(`job-alert/${id}`)
        const result = await this._callApi(apiPath, 'delete')
        return result
    }

    async checkValidEmail(email: string): Promise<boolean> {
        const apiPath = this._buildApiPath(`check-disposable-email`)
        const result = await this._callApi(apiPath, 'post', {
            user_email: email
        })

        if (result.length > 0) {
            return result[0]?.validate_email
        }

        return false
    }

    _transformJobAlertPostData(submitJobAlert: TSubmitJobAlert, language: TLanguage) {
        return {
            user_email: submitJobAlert.userEmail,
            job_level_id: submitJobAlert.jobLevel,
            period_type_id: submitJobAlert.periodType,
            location: submitJobAlert.locations,
            industry: submitJobAlert.industries,
            salary_min: submitJobAlert.salary,
            keyword: submitJobAlert.jobTitle,
            language_id: language === ELanguage.EN ? 2 : 1
        }
    }

    _transformJobAlertResponseData(responseData): TJobAlertItem {
        const {
            user_email,
            keyword,
            job_level_id,
            location,
            industry,
            salary_min,
            period_type_id,
            create_date,
            view_result_en,
            view_result_vi
        } = responseData.attributes

        return {
            id: responseData.job_alert_id,
            userEmail: user_email,
            jobTitle: keyword,
            jobLevel: parseInt(job_level_id) || 0,
            locations: location || [],
            industries: industry || [],
            salary: parseInt(salary_min) || 0,
            periodType: parseInt(period_type_id) || EJobAlertSendingPeriod.Daily,
            createdDate: create_date,
            jobResultLink: {
                en: view_result_en,
                vi: view_result_vi
            }
        }
    }
}

export default new JobAlertApi(API_BASE)
