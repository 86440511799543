export const ArrowDown = () => {
    return (
        <svg width="12px" height="8px" viewBox="0 0 12 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>icons/icon-forms/icon-arrow-down@1x</title>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g id="Pages/SearchResult/Result-SubBar-Expanded" transform="translate(-794.000000, -28.000000)" stroke="#777777" strokeWidth="1.1">
                    <g id="Group-3" transform="translate(16.089730, 9.000000)">
                        <g id="Group-20" transform="translate(623.940778, 13.000000)">
                            <g id="Group-27" transform="translate(55.000000, 0.000000)">
                                <g id="icons/icon-forms/icon-arrow-down" transform="translate(99.500000, 7.500000)">
                                    <polyline id="Stroke-718" transform="translate(5.000000, 2.500000) rotate(90.000000) translate(-5.000000, -2.500000) " points="2.5 -2.5 7.5 2.5 2.5 7.5"></polyline>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const ArrowUp = () => {
    return (
        <svg width="12px" height="8px" viewBox="0 0 12 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <title>icons/icon-forms/icon-arrow-down 2@1x</title>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g id="Pages/SearchResult/Result-SubBar-Expanded" transform="translate(-793.000000, -69.000000)" stroke="#777777" strokeWidth="1.1">
                    <g id="Group-22" transform="translate(16.138231, 61.000000)">
                        <g id="icons/icon-forms/icon-arrow-down" transform="translate(782.932929, 12.000000) scale(1, -1) translate(-782.932929, -12.000000) translate(777.932929, 9.500000)">
                            <polyline id="Stroke-718" transform="translate(5.000000, 2.500000) rotate(90.000000) translate(-5.000000, -2.500000) " points="2.5 -2.5 7.5 2.5 2.5 7.5"></polyline>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}