import styled from 'styled-components'

export default styled.div`
    .job-head {
        background: #fff;
        margin-bottom: 16px;
        padding: 15px 15px 0 15px;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
    }

    .job-head-component {
        border: none;
        h1,
        h2 {
            font-size: 14px;
            font-weight: 400;
            display: inline;
        }
        .job-alerts-wrapper {
            padding: 0 0 15px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .job-sortBy {
                display: flex;
                justify-content: flex-end;
                .sort-label {
                    font-size: 12px;
                    padding-right: 5px;
                    display: flex;
                    align-items: center;
                }
            }
        }
        .skill-tag-wrapper {
            position: relative;
            display: flex;
            padding: 0 0 15px 0;
            margin: 0;

            .skill-tag-toggle {
                width: 100%;
                position: relative;

                .toggle-name {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                .toggle-arrow {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 20px;
                    height: 20px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: right;
                }
            }
            .skill-tag-details {
                padding: 0 50px 0 130px;
                height: auto;
                transition: height 0.3s ease-in-out;
                overflow: hidden;

                .skill-tag-container {
                    display: flex;
                    gap: 8px;
                    flex-wrap: wrap;

                    .tag-wrapper {
                        font-size: 12px;
                        line-height: 14px;
                        padding: 4px 8px;
                        border-radius: 18px;
                        background-color: #fbfbfb;
                        border: 1px solid #dddddd;
                        cursor: pointer;

                        a {
                            color: unset;
                            pointer-events: none;
                        }
                    }
                }
            }
            .skill-tag-collapse {
                height: 24px;
            }
        }

        .select-box {
            background: #fff;
            border-color: #dee2e6;
        }

        .job-matched {
            font-size: 14px;
            font-weight: 400;
            overflow: hidden;
            text-overflow: ellipsis;
            .no-of-jobs,
            strong {
                font-weight: 500;
            }
        }

        .job-criteria {
            padding-right: 20px;
            font-size: 14px;
            .criterion {
                color: #333;
                font-weight: 500;
            }
            .refine-filter-btn {
                margin-left: 10px;
                padding: 3px 4px;
                border: 1px solid #ff7d55;
                border-radius: 3px;
                font-size: 12px;
                font-weight: 700;
                color: #ff7d55;
                background: white;
                transition: all 0.3s;
                &:hover {
                    background-color: #ff7d55;
                    color: white;
                    border-color: #ff7d55;
                }
                &:focus {
                    outline: none;
                }
            }
        }

        .sort-select {
            .navi-select {
                .select-dropdown {
                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
            }
        }
    }

    @media all and (max-width: 1024px) {
        .job-head-component {
            .job-matched {
                font-size: 14px;
            }
        }
    }

    @media all and (max-width: 991px) {
        .job-head-component {
            border: none;
            background: none;
            font-size: 0.875rem;

            .wrapper-job-criteria {
                display: block;
                margin-bottom: 0;
                .job-matched {
                    margin-bottom: 0;
                }
                .job-criteria {
                    margin-left: 3px;
                }
            }

            h1 {
                font-size: 0.875rem;
            }

            &.mb-3 {
                margin-bottom: 0 !important;
            }

            .sort-select {
                .job-sorting {
                    position: relative;
                }

                .navi-select {
                    position: absolute;
                    top: -8px;
                    right: 0;
                    .select-area {
                        cursor: pointer;
                    }

                    .wrapper-select,
                    .select-box,
                    .select-icon {
                        opacity: 0;
                    }

                    .select-dropdown {
                        right: 0;
                        width: 235px;
                    }
                }
            }
            .job-alerts-wrapper {
                padding: 0 15px;
                height: 0;
                overflow: hidden;
            }

            &.search-status {
                .no-of-jobs {
                    font-weight: 600;
                }
                .search-condition {
                    color: #7c7c7c;
                }
            }

            .skill-tag-wrapper {
                .skill-tag-toggle {
                    .toggle-name {
                        top: 15px;
                        left: 15px;
                    }
                    .toggle-arrow {
                        top: 15px;
                        right: 15px;
                    }
                }
                .skill-tag-details {
                    padding: 40px 15px 0 15px;
                }
                .skill-tag-collapse {
                    height: 64px;
                }
            }
        }
        .job-head {
            margin: 0;
            padding: 0;
            border: none;
        }
    }

    @media all and (min-width: 768px) and (max-width: 991px) {
        .job-head-component {
            padding: 0;
        }
    }

    @media all and (max-width: 899px) {
        .overlay {
            background: black;
            opacity: 0.3;
            position: fixed;
            width: 100%;
            height: 100%;
            z-index: 4;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
        .sort-responsive {
            background: white;
            position: fixed;
            top: 35%;
            left: 15%;
            z-index: 5;
            box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0);
            width: 70%;
            .row {
                margin: 0;
                .wrap-header-sort {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: #555;
                    font-weight: bold;
                    padding: 15px;
                    background: #fcfcfc;
                    text-transform: uppercase;
                    border-bottom: 1px solid #f2f2f2;
                    h2 {
                        font-size: 18px;
                    }
                    svg {
                        cursor: pointer;
                    }
                }
                .wrap-list-sort {
                    padding: 0;
                    ul {
                        padding: 0 20px;
                        li {
                            list-style-type: none;
                            padding: 15px 0;
                            border-bottom: 1px solid #f2f2f2;
                            transition: all 0.3s;
                            cursor: pointer;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            color: #333;
                            font-weight: bold;
                            &:hover {
                                background: #f2fcfe;
                            }
                            &:last-child {
                                border-bottom: none;
                            }
                        }
                        .icon-check {
                            fill: #005aff;
                            stroke: #005aff;
                        }
                    }
                }
            }
        }
    }

    @media all and (min-width: 576px) and (max-width: 767px) {
        .sort-responsive {
            width: 65%;
        }
    }

    @media all and (max-width: 768px) {
        .sort-select {
            display: none !important;
        }
    }

    @media all and (max-width: 576px) {
        .sort-responsive {
            width: 70%;
        }
    }

    .job-sorting-section {
        margin-bottom: 16px;

        .sort-wrapped {
            display: flex;
            align-items: center;
            gap: 8px;

            > div {
                font-size: 12px;
                margin-right: 0;
            }
        }

        .sort-item-wrapped {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        @media all and (max-width: 768px) {
            display: none !important;
        }
    }
`
