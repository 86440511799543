import styled from 'styled-components'

export default styled.div`
    .job-alerts {
        .job-alerts-btn-open {
            color: #ff7d55;
            border: 1px solid #ff7d55;
            min-width: 120px;
            &:hover {
                color: white;
                background: #ff7d55;
            }
        }

        .wraper-floating-icon {
            position: fixed;
            z-index: 10;
            right: 20px;
            bottom: 100px;
            width: 60px;
            padding-top: 5px;
            padding-right: 5px;
            padding-left: 5px;
            height: 60px;
            border-radius: 50px;
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
            background: white;
            transition: all 0.3s;
            display: block;
            overflow: hidden;
            cursor: pointer;
            .mailbox-floating {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                width: 50px;
                height: 50px;
                background: linear-gradient(90deg, #cee5f5 0%, #ccdeff 100%);
                flex-shrink: 0;
                svg {
                    height: 30px;
                    width: 30px;
                }
            }
            .extend-jobAlert-Icon {
                display: flex;
                padding-left: 10px;
                padding-right: 10px;
                .job-alert-text {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #333;
                    font-size: 13px;
                    font-weight: 600;
                    width: 150px;
                    line-height: 20px;
                }
                .email-jobs-btn {
                    color: #ff7d55;
                    border: 1px solid #ff7d55;
                    font-size: 12px;
                    font-weight: 600;
                    width: 120px;
                    height: 37px;
                    &:hover {
                        color: white;
                        background: #ff7d55;
                    }
                }
            }
        }
        .addPosition {
            display: flex;
            justify-items: center;
            align-items: center;
            padding-top: 0;
        }

        .btn-sm {
            padding-left: 16px !important;
            padding-right: 16px !important;
        }

        @media all and (max-width: 320px) {
            .wraper-floating-icon {
                &.extended {
                    padding: 8px 4px !important;

                    .extend-jobAlert-Icon {
                        flex-basis: 82% !important;
                        display: flex;

                        .job-alert-text {
                            width: auto;
                            margin-right: 6px;
                            flex-basis: 55% !important;
                        }

                        .email-jobs-btn {
                            padding-left: 8px;
                            padding-right: 8px;
                        }
                    }
                }
            }
        }

        @media all and (max-width: 768px) {
            .wraper-floating-icon {
                right: 0;
                left: 100%;
                margin-left: -70px;

                &.extended {
                    width: 100%;
                    left: 0;
                    margin-left: 0;
                    bottom: 0;
                    border-radius: 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 6px 10px;
                    height: auto;
                    transform: translateY(66px);

                    &:hover {
                        width: 100%;
                    }

                    .extend-jobAlert-Icon {
                        padding: 0;
                        justify-content: center;
                        flex-basis: 83.33%;
                        justify-content: space-between;
                        display: flex;

                        &:hover {
                            visibility: visible;
                        }

                        .job-alert-text {
                            flex-basis: 58.33%;
                            font-weight: normal;
                            margin-right: auto;
                            margin-left: 2px;
                            justify-content: start;
                        }

                        .email-jobs-btn {
                            padding: 6px 10px;
                            font-size: 12px;
                            height: auto;
                            width: auto;
                        }
                    }

                    &.show {
                        transform: translateY(0);
                    }
                }

                .extend-jobAlert-Icon {
                    display: none;
                }

                &.no-float {
                    position: relative;
                    top: 0;
                    left: 0;
                    transform: translateX(0);
                    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
                    background: white;
                    margin-top: 8px;
                    z-index: 0;
                }
            }
        }
    }

    @media all and (min-width: 768px) {
        // .wraper-floating-icon{
        //     &:hover{
        //         width:375px;
        //     }
        // }
    }
    @media all and (max-width: 768px) {
        .wraper-floating-icon {
            &:hover {
                width: 60px;
            }
            .job-alerts {
                .floating-icon {
                    bottom: 20px;
                }
            }
            .mailbox-floating {
                cursor: pointer;
            }
        }
    }
`
