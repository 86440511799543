import { combineReducers } from 'redux'
import jobAlertsReducer from './components/JobAlerts/jobAlertReducer'
import searchFilterReducer from './components/SearchFilter/searchFilterReducer'
import globalMiscReducer from './global/searchResultGlobalMiscReducer'

const rootReducer = combineReducers({
    searchFilter: searchFilterReducer,
    jobAlert: jobAlertsReducer,
    globalMisc: globalMiscReducer
})

export type TRootState = ReturnType<typeof rootReducer>

export default rootReducer
