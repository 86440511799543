import * as Sentry from '@sentry/node'
import isEmpty from 'lodash/isEmpty'
import { decrypt } from '../../../handlers/auth/cryptor'

/**
 * WHAT: this is a global service that helps check special job types, e.g. headHuntJob, management jobs, etc...
 */
class SaveJobService {
    saveJob(jobId: number, token: string, language: string) {
        const data = {
            jobId: jobId
        }
        const decriptToken = decrypt(token)
        const saveJob: Promise<object> = fetch(`${process.env.VNW_SAVE_JOB_API_URL}`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Accept-Language': language,
                'Content-Type': 'application/json',
                Authorization: `Bearer ${decriptToken}`
            },
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (res.status !== 200) console.log('status code ' + res.status)
                else return res.json()
            })
            .catch((error) => {
                console.error(`[SaveJobService] Error while trying to SAVE job or to convert the response to json`)
                Sentry.captureException(error)
            })
            .then((data) => {
                if (!isEmpty(data)) {
                    return data.meta
                }
            })
            .catch((error) => {
                const errorMessage = `[SaveJobService] Error when SAVE job ${error.message}`
                console.error(errorMessage)
                Sentry.captureException(error)
            })

        return saveJob
    }

    unSaveJob(jobId: number, token: string, language: string) {
        const data = {
            jobId: jobId
        }
        const decriptToken = decrypt(token)
        const unSaveJob: Promise<object> = fetch(`${process.env.VNW_UN_SAVE_API_URL}`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Accept-Language': language,
                'Content-Type': 'application/json',
                Authorization: `Bearer ${decriptToken}`
            },
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (res.status !== 200) console.log('status code ' + res.status)
                else return res.json()
            })
            .catch((error) => {
                console.error(`[SaveJobService] Error while trying to UN-SAVE job or to convert the response to json`)
                Sentry.captureException(error)
            })
            .then((data) => {
                if (!isEmpty(data)) {
                    return data.meta
                }
            })
            .catch((error) => {
                const errorMessage = `[SaveJobService] Error when UN-SAVE job ${error.message}`
                console.error(errorMessage)
                Sentry.captureException(error)
            })

        return unSaveJob
    }
}

const saveJobService = new SaveJobService()
export default saveJobService
