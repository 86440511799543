import { TJobAlertItem, TSubmitJobAlert, TSubmitUpdateJobAlert } from './types'
import { TLanguage } from '../../utils/language'

/**
 * Types definition
 */

export type TGetJobAlertListSuccessAction = {
    type: typeof GET_JOB_ALERT_LIST_SUCCESS
    payload: TJobAlertItem[]
}

export type TRequestCreateNewJobAlertAction = {
    type: typeof REQUEST_CREATE_JOB_ALERT
    payload: {
        item: TSubmitJobAlert
        language: TLanguage
    }
}

export type TCreateNewJobAlertSuccessAction = {
    type: typeof CREATE_JOB_ALERT_SUCCESS
    payload: TJobAlertItem
}

export type TIndexedPayload = {
    item: TJobAlertItem | TSubmitJobAlert | TSubmitUpdateJobAlert | number | null
    index: number
}

export type TRequestUpdateJobAlertAction = {
    type: typeof REQUEST_UPDATE_JOB_ALERT
    payload: TIndexedPayload & { language: TLanguage }
}

export type TUpdateJobAlertSuccessAction = {
    type: typeof UPDATE_JOB_ALERT_SUCCESS
    payload: TIndexedPayload
}

export type TRequestDeleteJobAlertAction = {
    type: typeof REQUEST_DELETE_JOB_ALERT
    payload: TIndexedPayload
}

export type TDeleteJobAlertSuccessAction = {
    type: typeof DELETE_JOB_ALERT_SUCCESS
    payload: TIndexedPayload
}

// this is for general actions to handle simple case, avoid using this as possible
export type TJobAlertAction = {
    type: string
    payload: string
}

/**
 * Action definition
 */

// Get list actions
export const REQUEST_GET_JOB_ALERT_LIST = 'REQUEST_GET_JOB_ALERT_LIST'
export const GET_JOB_ALERT_LIST_SUCCESS = 'GET_JOB_ALERT_LIST_SUCCESS'
export const GET_JOB_ALERT_LIST_FAILED = 'GET_JOB_ALERT_LIST_FAILED'

// Create actions
export const REQUEST_CREATE_JOB_ALERT = 'REQUEST_CREATE_JOB_ALERT'
export const CREATE_JOB_ALERT_SUCCESS = 'CREATE_JOB_ALERT_SUCCESS'
export const CREATE_JOB_ALERT_FAILED = 'CREATE_JOB_ALERT_FAILED'

// Update actions
export const REQUEST_UPDATE_JOB_ALERT = 'REQUEST_UPDATE_JOB_ALERT'
export const UPDATE_JOB_ALERT_SUCCESS = 'UPDATE_JOB_ALERT_SUCCESS'
export const UPDATE_JOB_ALERT_FAILED = 'UPDATE_JOB_ALERT_FAILED'

// Delete actions
export const REQUEST_DELETE_JOB_ALERT = 'REQUEST_DELETE_JOB_ALERT'
export const DELETE_JOB_ALERT_SUCCESS = 'DELETE_JOB_ALERT_SUCCESS'
export const DELETE_JOB_ALERT_FAILED = 'DELETE_JOB_ALERT_FAILED'

/**
 * Action generator function
 */

export const requestGetJobAlertList = () => ({
    type: REQUEST_GET_JOB_ALERT_LIST
})

export const getJobAlertListSuccess = (jobAlertList: TJobAlertItem[]): TGetJobAlertListSuccessAction => ({
    type: GET_JOB_ALERT_LIST_SUCCESS,
    payload: jobAlertList
})

export const requestCreateNewJobAlert = (
    JobAlertItem: TSubmitJobAlert,
    language: TLanguage
): TRequestCreateNewJobAlertAction => ({
    type: REQUEST_CREATE_JOB_ALERT,
    payload: {
        item: JobAlertItem,
        language: language
    }
})

export const createNewJobAlertSuccess = (newlyCreateJobAlert: TJobAlertItem): TCreateNewJobAlertSuccessAction => ({
    type: CREATE_JOB_ALERT_SUCCESS,
    payload: newlyCreateJobAlert
})

export const requestUpdateJobAlert = (
    JobAlertItem: TSubmitUpdateJobAlert,
    index: number,
    language: TLanguage
): TRequestUpdateJobAlertAction => ({
    type: REQUEST_UPDATE_JOB_ALERT,
    payload: {
        item: JobAlertItem,
        index: index,
        language: language
    }
})

export const updateJobAlertSuccess = (updatedJobAlert: TJobAlertItem, index: number): TUpdateJobAlertSuccessAction => ({
    type: UPDATE_JOB_ALERT_SUCCESS,
    payload: {
        item: updatedJobAlert,
        index: index
    }
})

export const requestDeleteJobAlert = (jobAlertId: number, index: number): TRequestDeleteJobAlertAction => ({
    type: REQUEST_DELETE_JOB_ALERT,
    payload: {
        item: jobAlertId,
        index: index
    }
})

export const deleteJobAlertSuccess = (index: number): TDeleteJobAlertSuccessAction => ({
    type: DELETE_JOB_ALERT_SUCCESS,
    payload: {
        item: null,
        index: index
    }
})

// Define abstract action type - Using in saga worker function for type check
export type jobAlertAction =
    | TGetJobAlertListSuccessAction
    | TCreateNewJobAlertSuccessAction
    | TJobAlertAction
    | TUpdateJobAlertSuccessAction
    | TDeleteJobAlertSuccessAction
