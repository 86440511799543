import MailboxIcon from './mailboxIcon'
import React from 'react'
import withComponentTranslation from '../../utils/translatorHOC'
import vi from './vi.json'
import { debounce, throttle } from '../../../../helpers/common'
import JobAlertsStyles from './JobAlerts.css'
import { Button } from '@microfrontends/react-components'

/**
 This is the Floating Element Icon on the right side.
 Clicking on the this icon should show the create job alert modal
 */

class FloatingIcon extends React.PureComponent<any, any> {
    currentScrollPosition: number
    currentShowFloatingPostion: number

    constructor(props) {
        super(props)

        this.state = {
            isMobile: false,
            isDisplayExtend: false
        }

        this.handleExtendOnScroll = throttle(this.handleExtendOnScroll.bind(this), 800)
        this.handleScrollPassAnchor = debounce(this.handleScrollPassAnchor.bind(this), 10)

        this.currentScrollPosition = 0
        this.currentShowFloatingPostion = 0
    }

    handleMouseEnterBtn = () => {
        if (window.innerWidth > 768) {
            setTimeout(() => {
                this.setState({ isDisplayExtend: true }, () => {
                    if (typeof document !== 'undefined') {
                        const floatingIconBlock: HTMLElement = document.getElementsByClassName(
                            'wraper-floating-icon'
                        )[0] as HTMLElement
                        if (floatingIconBlock) {
                            floatingIconBlock.style.width = '375px'
                        }
                    }
                })
            }, 350)
        }
    }

    handleMouseLeaveBtn = () => {
        if (window.innerWidth > 768) {
            setTimeout(() => {
                this.setState({ isDisplayExtend: false }, () => {
                    if (typeof document !== 'undefined') {
                        const floatingIconBlock: HTMLElement = document.getElementsByClassName(
                            'wraper-floating-icon'
                        )[0] as HTMLElement
                        if (floatingIconBlock) {
                            floatingIconBlock.style.width = '60px'
                        }
                    }
                })
            }, 100)
        }
    }

    handleClickWhenMobile = () => {
        const { handleClick } = this.props
        if (window.innerWidth <= 768) {
            handleClick()
        }
    }

    checkResized = () => {
        if (window.innerWidth <= 768) this.setState({ isMobile: true })
        else this.setState({ isMobile: false })
    }

    handleExtendOnScroll() {
        const isScrollDown = window.scrollY > this.currentScrollPosition
        const scrollFarEnough = Math.abs(window.scrollY - this.currentShowFloatingPostion) >= window.innerHeight
        this.currentScrollPosition = window.scrollY

        let scrollPassAnchor = false
        try {
            scrollPassAnchor =
                this.props.stopShowFloatingRef.current.getBoundingClientRect().top - window.innerHeight <= 0
        } catch (err) {
            console.error(err)
        }

        if (isScrollDown && scrollFarEnough && !scrollPassAnchor) {
            if (!this.state.isDisplayExtend) {
                this.setState({
                    isDisplayExtend: true
                })

                this.currentShowFloatingPostion = window.scrollY

                setTimeout(() => {
                    this.setState({
                        isDisplayExtend: false
                    })
                }, 5000)
            }
        }
    }

    handleScrollPassAnchor() {
        let scrollPassAnchor = false
        try {
            scrollPassAnchor =
                this.props.stopShowFloatingRef.current?.getBoundingClientRect().top - window.innerHeight <= 300
        } catch (err) {
            console.error(err)
        }

        if (scrollPassAnchor && this.state.isDisplayExtend) {
            this.setState({
                isDisplayExtend: false
            })
        }
    }

    componentDidMount(): void {
        if (!this.props.noFloat) {
            window.addEventListener('resize', this.checkResized)
            if (window.innerWidth <= 768) {
                document.addEventListener('scroll', this.handleExtendOnScroll)
                document.addEventListener('scroll', this.handleScrollPassAnchor)
            }
        }
    }

    componentWillUnmount(): void {
        if (!this.props.noFloat) {
            window.removeEventListener('resize', this.checkResized)
            if (window.innerWidth <= 768) {
                document.removeEventListener('scroll', this.handleExtendOnScroll)
                document.addEventListener('scroll', this.handleScrollPassAnchor)
            }
        }
    }

    render() {
        const { handleClick, translate, noFloat } = this.props
        const { isDisplayExtend } = this.state
        return (
            <JobAlertsStyles>
                <div
                    className={`wraper-floating-icon extended ${noFloat ? 'no-float' : ''} ${
                        isDisplayExtend ? 'addPosition show' : ''
                    }`}
                    onMouseEnter={this.handleMouseEnterBtn}
                    onMouseLeave={this.handleMouseLeaveBtn}
                    onClick={this.handleClickWhenMobile}
                >
                    <span className='mailbox-floating'>
                        <MailboxIcon />
                    </span>
                    <div
                        className='extend-jobAlert-Icon align-items-center'
                        style={{ display: isDisplayExtend ? 'flex' : 'none' }}
                    >
                        <div className='job-alert-text'>
                            {translate('Receive job alerts for jobs like this search!')}
                        </div>
                        <div className='btn-wrapper'>
                            <Button type='primary' onClick={handleClick}>
                                {translate('Send me jobs')}
                            </Button>
                        </div>
                    </div>
                </div>
            </JobAlertsStyles>
        )
    }
}

export default withComponentTranslation(vi)(FloatingIcon)
