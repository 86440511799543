import styled, { css } from 'styled-components'

export const SwitcherJobAlertWrapper = styled.div<{ isNewUI: boolean }>`
    ${({ isNewUI }) => {
        if (!isNewUI) {
            return ''
        }
        return css`
            display: flex;
            span {
                margin-right: 4px;
            }
        `
    }}
`
