import isEmpty from 'lodash/isEmpty'
import { put, takeEvery } from 'redux-saga/effects'
import specialJobService, { IBadgeItemData } from '../../../../services/specialJobService'
import { GET_SPECIAL_JOBS, PUT_SPECIAL_JOBS, TGetSpecialJobActionType } from './specialJobAction'
import { TJobInvitation } from '@microfrontends/react-components/components/ApplyJobs/types'

export interface ISpecialJobParams {
    jobIds: Array<number>
    token: string
    language: string
    hits: Array<any>
    type: TGetSpecialJobActionType
}

interface ISearchFilterJobAction {
    type: string
    params: ISpecialJobParams
}

export function* watchGetSpecialJob() {
    yield takeEvery(GET_SPECIAL_JOBS, getSpecialJob)
}

export function* getSpecialJob(action: ISearchFilterJobAction) {
    const { params } = action
    const { token, jobIds, hits, type } = params

    if (token === '') {
        return
    }
    const result = yield specialJobService.getBadgesData(token, jobIds) // Get Special Jobs
    let flattenItems: {
        id: number
        applied: boolean
        saved: boolean
        matching: boolean,
        redirectTo: string,
        userBlackList: boolean
        viewed: boolean
        jobInvitation: TJobInvitation
    }[]
    let transform: Array<any> = []

    if (typeof result !== 'undefined' && !isEmpty(result.data)) {
        flattenItems = !isEmpty(result.data)
            ? result.data.map((item: IBadgeItemData) => {
                  const { applied, headHunter, saved, topManagement, matching, redirectTo, userBlackList, viewed, jobInvitation } = item.attributes
                  return {
                      id: item.id,
                      applied,
                      headHunter,
                      saved,
                      topManagement,
                      matching,
                      redirectTo,
                      userBlackList,
                      viewed,
                      jobInvitation
                  }
              })
            : []

        transform = hits.map((hit) => {
            const { jobId } = hit
            const flattedBadgeItem: any = !isEmpty(flattenItems) ? flattenItems.find((item) => item.id === jobId) : {}
            if (!isEmpty(flattedBadgeItem)) {
                return Object.assign({}, hit, {
                    isApplied: flattedBadgeItem.applied,
                    isSaved: flattedBadgeItem.saved,
                    isMatching: flattedBadgeItem.matching,
                    isBlackList: flattedBadgeItem.userBlackList,
                    redirectTo: flattedBadgeItem.redirectTo,
                    isViewed: flattedBadgeItem.viewed,
                    jobInvitation: flattedBadgeItem.jobInvitation
                })
            }
        })

        yield put({ type: PUT_SPECIAL_JOBS, payload: { data: transform, type } })
    }
    
}
