import isEmpty from 'lodash/isEmpty'
import { debounce, put } from 'redux-saga/effects'
import saveJobService from '../../../../services/saveJobService'
import { ISaveJobParams } from '../../../../types/ISaveJobParams'
import { Utils } from '@microfrontends/react-components'

import { PUT_SAVE_JOB, PUT_UNSAVE_JOB, SAVE_JOB, UNSAVE_JOB } from './saveJobsActions'
import {ELanguage} from '@microfrontends/app-shell-v2'
import React from 'react'

interface ISearchFilterJobAction {
    type: string
    params: ISaveJobParams
}

interface TToastMess {
    title: string,
    content: React.ReactNode
}

export function* watchSaveJob() {
    yield debounce(0, SAVE_JOB, saveJobs)
}

export function* watchUnsaveJob() {
    yield debounce(0, UNSAVE_JOB, unSaveJob)
}

export function* saveJobs(action: ISearchFilterJobAction) {
    const { params } = action
    const { jobId, token, language } = params

    // Save job API
    const result = yield saveJobService.saveJob(jobId, token, language)

    // Handle the result
    if (!isEmpty(result) && result.code === 200 && result.message === 'success') {
        yield put({
            type: PUT_SAVE_JOB,
            payload: {status: 'success', jobId}
        })
        const result: TToastMess = {
            'title': 'Thành công',
            'content': <div>Bạn đã lưu việc làm thành công, xem <a href="/quan-ly-nghe-nghiep/viec-lam-cua-toi?section=saved">Việc làm của tôi</a></div>
        }
        if (language === ELanguage.EN) {
            result.title = 'Success'
            result.content = <div>You have saved job, view <a href="/my-career-center/my-jobs?section=saved">My jobs</a></div>
        }
        Utils.showToast(result?.title, result?.content)
        return
    }
    yield put({
        type: PUT_SAVE_JOB,
        payload: { status: 'failed', jobId }
    })
}

export function* unSaveJob(action: ISearchFilterJobAction) {
    const { params } = action
    const { jobId, token, language } = params

    // Un-save job API
    const result = yield saveJobService.unSaveJob(jobId, token, language)
    // Handle the result
    if (!isEmpty(result) && result.code === 200 && result.message === 'success') {
        yield put({
            type: PUT_UNSAVE_JOB,
            payload: {status: 'success', jobId}
        })

        const result: TToastMess = {
            'title': 'Thành công',
            'content': <div>Bạn vừa xóa 1 công việc</div>
        }
        if (language === ELanguage.EN) {
            result.title = 'Success'
            result.content = <div>Just removed 1 save job</div>
        }
        Utils.showToast(result.title, result.content)

        return
    }
    yield put({
        type: PUT_UNSAVE_JOB,
        payload: { status: 'success', jobId }
    })

}
