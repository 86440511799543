import { SEARCH_PARAMS, Utils } from '@microfrontends/react-components'
import searchResultsStore from '../searchResultsStore'
import { EPageTab } from '../types/EPageTag'
import { ISearchFilterParams } from '../types/ISearchFilterParams'
import { IUrlSyncOptions } from '../types/IUrlSyncOptions'
import { ELanguage } from '../utils/language'
import { decodeKeyword, encodeKeyword, slugify } from '../utils/utilities'
import { TSelectData } from './filterHelper'
import { listJobFunctionIdFromCategoryId } from './constant'
import { SORT_DEFAULT_INDEX } from '../components/JobHead'
import { readableSortByUrlParamMap, sortByUrlParam } from '../components/SearchFilter/constants'
import { TOrderedSelectDataItem } from '@microfrontends/react-components/components/types'
import { getLocationsByLocationIdsAndDistrictIds } from '../../../handlers/search-filter/helpers'
import { initialDataProvider } from '@microfrontends/app-shell-v2'

type TQueryParam = {
    text: string
    key: string
    value: number[] | string[]
}

/**
 *  Search url consists of 3 parts
 *  - First part: Keyword or job text
 *  - Middle part: Category / Location text (include translation) with code
 *  - Ending part: Language code
 *
 * @param searchParams
 * @param selectData
 */
class UrlParamSync {
    _buildSearchSlugText(
        keyword: string,
        categorySlug: string,
        locationSlug: string,
        language: ELanguage,
        isUrgentJob: boolean,
        typeWorkingSlug: string,
        hasAnyFilter: boolean,
        districtSlug?: string,
        districtId?: string
    ): string {
        let slug = ''

        const isEn = language === ELanguage.EN

        const AT = isEn ? 'at' : 'nganh'
        const IN = isEn ? 'in' : 'tai'
        const JOBS = isEn ? 'jobs' : 'viec-lam'
        const URGENT = isEn ? 'urgent' : 'tuyen-gap'

        if (keyword !== '') {
            let filterSlug = typeWorkingSlug

            if (categorySlug !== '') {
                filterSlug += `${filterSlug !== '' ? '-' : ''}${AT}-${categorySlug}`
            }

            if (locationSlug !== '') {
                if (districtId !== '') {
                    filterSlug += `${filterSlug !== '' ? '-' : ''}${IN}-${
                        districtSlug && districtSlug !== '' ? districtSlug + `-` : ``
                    }${locationSlug}`
                } else {
                    filterSlug += `${filterSlug !== '' ? '-' : ''}${IN}-${locationSlug}`
                }
            }

            slug += filterSlug !== '' ? `${keyword}+${filterSlug}` : `${keyword}+`
        } else {
            const prefixSlug = this.joinPath(
                isEn ? [typeWorkingSlug, categorySlug, JOBS] : [JOBS, typeWorkingSlug, categorySlug]
            )

            slug = hasAnyFilter ? prefixSlug : ''

            if (categorySlug !== '' && locationSlug !== '') {
                slug = `${prefixSlug}-${IN}-${locationSlug}`
            } else {
                if (locationSlug !== '') {
                    slug = `${isEn ? '' : `${prefixSlug}-`}${
                        districtSlug && districtSlug !== '' ? districtSlug + `-` : ``
                    }${locationSlug}${isEn ? `-${prefixSlug}` : ''}`
                }
            }
        }

        if (isUrgentJob) {
            slug = `${slug}-${URGENT}-uj`
        }

        return slug
    }

    _buildSearchCode(searchParams: Partial<ISearchFilterParams>): string {
        const { categoryId = -1, locationId = -1, jobLevelId = -1, typeWorkingId = -1 } = searchParams
        let categoryCode = ''
        let locationCode = ''
        const jobLevelCode = jobLevelId > 0 ? 'l' + jobLevelId : ''
        const typeWorkingCode = typeWorkingId > 0 ? 't' + typeWorkingId : ''
        // Build location Code
        if (typeof locationId === 'object' && locationId.length > 0) {
            locationCode = 'v' + locationId.join('.')
        }
        // Build categofu Code
        if (typeof categoryId === 'object' && categoryId.length > 0) {
            categoryCode = 'i' + categoryId.join('.')
        }
        return categoryCode + locationCode + jobLevelCode + typeWorkingCode
    }

    private _hasInputSearchFilter(searchParams: Partial<ISearchFilterParams>) {
        return {
            keyword: searchParams.query && searchParams.query !== '',
            location: searchParams.locationId && searchParams.locationId.length > 0,
            category: searchParams.categoryId && searchParams.categoryId.length > 0,
            isUrgentJob: searchParams.isUrgentJob === true,
            typeWorkingId: searchParams.typeWorkingId && searchParams.typeWorkingId > -1,
            level: searchParams.jobLevelId && searchParams.jobLevelId > -1,
            districtId: searchParams.districtId && searchParams.districtId.length > 0
        }
    }

    /**
     * VNW-10532 [To ES7] [Web][Search filter]_Allow to select multiple categories and locations
     *
     * Split param. Because we are using multiple param value in each param
     * so old regex will not work
     *
     *
     * @author ThienTD
     * @since Feb 17, 2022
     */
    /**
     * Split url params string to each own key, value
     * @param str param query string (Ex: i35.55v29.24l8e5)
     * @return TQueryParam[] array of object TQueryParam
     */
    splitParams(str: string): TQueryParam[] {
        const returnData: { key: string; index: number }[] = []
        // Default category (i), location (v), userId (e), job Level (l), type working (t), sorting
        const splitByArray: string[] = ['e', 'i', 'l', 'v', 't', 'sorting', 'd']
        // Get each type index of character
        splitByArray.map((item) => {
            const idx: number = str.indexOf(item)
            if (idx !== -1) {
                returnData.push({
                    key: item,
                    index: idx
                })
            }
        })
        // Sort array for easier to split
        returnData.sort((itemA, itemB) => {
            return itemA.index > itemB.index ? 1 : -1
        })
        const splittedArray: TQueryParam[] = []
        // Do split
        returnData.map((item, index) => {
            const nextItem = returnData[index + 1]
            const subStrText = str.substring(item.index, nextItem !== undefined ? nextItem.index : str.length)
            // Remove first character (splitByArray character)
            const arrayValue: string[] = subStrText.replace(item.key, '').split('.')
            const valueSplitted: number[] = []
            // Do parse to integer
            arrayValue.map((itemId) => {
                if (itemId !== '') {
                    valueSplitted.push(isNaN(itemId as any) ? (itemId as any) : parseInt(itemId))
                }
            })
            splittedArray.push({
                text: subStrText,
                key: item.key,
                value: valueSplitted
            })
        })
        return splittedArray
    }

    /**
     * Parse URL to searchParams
     * @param providedPath - URL, so we could parse it
     * @return SearchParams
     */
    collectSearchConditionFromUrl(
        providedPath: string = '',
        districts?: { [key: string]: TOrderedSelectDataItem[] }
    ): Partial<ISearchFilterParams> {
        const searchCondition: Partial<ISearchFilterParams> = {
            query: '',
            categoryId: [],
            locationId: [],
            districtId: [],
            userId: 0,
            jobLevelId: -1,
            page: 0,
            indexName: SORT_DEFAULT_INDEX,
            districtSlug: ''
        }
        try {
            const windowPathName = typeof window !== 'undefined' ? window.location.pathname : ''
            const path = providedPath === '' ? windowPathName : providedPath

            const decodedPath = decodeURIComponent(path)
            let safePath = decodedPath.replace(/\//g, '') // remove slash "/" in url if having

            /**
             * VNW-20789 [Search] Search with incorrect keywords
             *
             * replace " + " to "%20%2b%20" (There are 2 space at before, after at plus sign)
             * This is used for keep keyword not being splitted
             *
             * @author ThienTD
             * @since Mar 30, 2023
             */
            if (safePath.indexOf(' + ') > 0) {
                safePath = safePath.replace(' + ', '%20%2b%20')
            }

            // Check if param page is exist in providedPath
            if (providedPath.indexOf('page=') > 0) {
                // Get position of Question Mark
                const indexOfQuestionMark = providedPath.indexOf('?')
                // substring url from question mark to end
                const rawParams = providedPath.substring(indexOfQuestionMark + 1, providedPath.length)
                const paramsArray = rawParams.split('&')
                let pageParamValue = 0
                for (let i = 0; i < paramsArray.length; i++) {
                    const paramInfo = paramsArray[i].split('=')
                    if (paramInfo[0] !== undefined && paramInfo[0] === 'page') {
                        // Because ES is using index from 0
                        // So we have to minus 1
                        pageParamValue = parseInt(paramInfo[1]) - 1
                        if (pageParamValue < 0) {
                            pageParamValue = 0
                        }
                        break
                    }
                }

                if (!Number.isNaN(pageParamValue) && pageParamValue > 0) {
                    searchCondition.page = pageParamValue
                }
            }
            const keywordRegex = new RegExp(/-kv|-kw/)

            if (keywordRegex.test(safePath)) {
                let keywordInUrl = safePath.replace(keywordRegex, '')
                if (keywordInUrl.indexOf('?') > 0) {
                    const keywordArray = keywordInUrl.split('?')
                    keywordInUrl = keywordArray.length > 0 ? keywordArray[0] : keywordInUrl
                }
                if (keywordInUrl.includes('-uj')) keywordInUrl = keywordInUrl.replace(/-(urgent|tuyen-gap)-uj/, '')
                searchCondition.query = decodeKeyword(keywordInUrl)
            }

            const hasFilterRegex = new RegExp(/-vn(\?.+)?$|-en(\?.+)?$/)
            if (hasFilterRegex.test(safePath)) {
                const urlPartsWithKeyword = safePath.split('+')
                /**
                 * VNW-20789 [Search] Search with incorrect keywords
                 *
                 * replace back "%20%2b%20" to " + " (There are 2 space at before, after at plus sign)
                 * This is used for readable keyword
                 *
                 * @author ThienTD
                 * @since Mar 30, 2023
                 */
                const parsedKeyword = urlPartsWithKeyword[0]?.replace('%20%2b%20', ' + ')
                let filterPart = safePath

                if (urlPartsWithKeyword.length > 1) {
                    // we expect an url has + mark, then the string can split and will result in array that has more than 1 element
                    // TODO: refactor the below replace methods into one method
                    searchCondition.query = parsedKeyword
                        .split('-')
                        .join(' ')
                        .replace(/\+\+/g, '_plus_plus')
                        .replace(/C_plus_plus|c_plus_plus/, 'C++')
                        .replace(/C_sharp|c_sharp/, 'C#')

                    filterPart = urlPartsWithKeyword[1] || ''
                }
                // Refactor from old code
                // This block of old code causing indexOf undefined
                const urlParts = filterPart?.split('?')[0]?.split('-') || []

                const lastIndex = urlParts.length
                const thirdLastPart = urlParts[lastIndex - 3]
                const secondLastPart = urlParts[lastIndex - 2]

                const filterCode =
                    thirdLastPart && thirdLastPart.includes('d') && secondLastPart
                        ? secondLastPart + thirdLastPart
                        : secondLastPart || ''

                const splittedParams = this.splitParams(filterCode)

                splittedParams.map((paramItem) => {
                    if (paramItem.key === 'i') {
                        searchCondition.categoryId = paramItem.value as number[]
                    } else if (paramItem.key === 'v') {
                        searchCondition.locationId = paramItem.value as number[]
                    } else if (paramItem.key === 'l') {
                        // jobLevelId doesn't support multiple value
                        searchCondition.jobLevelId = (
                            paramItem.value[0] !== undefined ? paramItem.value[0] : -1
                        ) as number
                    } else if (paramItem.key === 't') {
                        // typeWorkingId doesn't support multiple value
                        searchCondition.typeWorkingId = (
                            paramItem.value[0] !== undefined ? paramItem.value[0] : -1
                        ) as number
                    } else if (paramItem.key === 'sorting') {
                        searchCondition.indexName = sortByUrlParam[paramItem.value[0] as string]
                    }
                })
                if (searchCondition.locationId && searchCondition.locationId.length > 0) {
                    const locationFromId = initialDataProvider.getLocationNameFromId(searchCondition.locationId[0])
                    const districtsMatchWithLocationId =
                        districts && searchCondition.locationId[0] && districts[searchCondition.locationId[0]]
                            ? districts[searchCondition.locationId[0]]
                            : []

                    // Get district slug
                    const districtMatchWithPath = districtsMatchWithLocationId.filter((district) => {
                        const locationText = `${slugify(district.name)}-${slugify(locationFromId.name)}`
                        return safePath.includes(locationText)
                    })

                    if (districtMatchWithPath && districtMatchWithPath.length > 0) {
                        searchCondition.districtId = districtMatchWithPath.map((item) => item.id) ?? []
                        searchCondition.districtSlug = slugify(districtMatchWithPath[0].name) ?? ''
                    }
                    const locations = getLocationsByLocationIdsAndDistrictIds(
                        searchCondition.locationId || [],
                        searchCondition.districtId || []
                    )
                    searchCondition.locations = locations ?? []
                }
            }

            // Find employer ID from all possible url patterns
            const findUserId = decodedPath.match(/-e\d+-(en|vn)/g)
            if (findUserId) {
                const userId =
                    Array.isArray(findUserId) && findUserId.length > 0
                        ? Number(findUserId[0].replace(/[^0-9]/g, ''))
                        : -1
                searchCondition.userId = userId
            }

            // Urgent Job
            if (safePath.match(/urgent-uj|tuyen-gap-uj|viec-can-tuyen-gap|urgent-jobs/)) {
                searchCondition.isUrgentJob = true
            } else {
                searchCondition.isUrgentJob = false
            }

            return searchCondition
        } catch (error) {
            console.error(error)
            return searchCondition
        }
    }

    getLanguageFromPath(path: string = '', context: any = null): ELanguage {
        if (/-en$/.test(path)) return ELanguage.EN
        if (/-en\?/.test(path)) return ELanguage.EN
        if (/-kw$/.test(path)) return ELanguage.EN
        if (/-kw\?/.test(path)) return ELanguage.EN
        if (path === process.env.ROUTE_SEARCH_RESULT) return ELanguage.EN
        if (path.includes('/salary/')) return ELanguage.EN
        if (path.includes('/interview/')) return ELanguage.EN
        if (path.includes('all-jobs')) return ELanguage.EN
        if (path.includes(`${process.env.ROUTE_SEARCH_JOBS}?`)) return ELanguage.EN
        if (path.includes('/urgent-jobs')) return ELanguage.EN
        if (path.includes('/jobs')) return ELanguage.EN
        if (path.includes('/management-jobs')) return ELanguage.EN
        if (path.includes('/index.json')) {
            const refererUrl = context?.req?.headers?.referer
            if (refererUrl.includes('/salary/')) return ELanguage.EN
            if (refererUrl.includes('/interview/')) return ELanguage.EN
        }

        return ELanguage.VI
    }

    buildUrlSyncOptions(language: string = 'en', forceSyncUrl: boolean = true): IUrlSyncOptions {
        return {
            syncUrl: forceSyncUrl,
            allJobsPath:
                language === 'en' ? process.env.ROUTE_SEARCH_RESULT || '' : process.env.ROUTE_SEARCH_RESULT_VI || '',
            language: language
        }
    }

    removeQueryParam = (query: string) => {
        if (query === undefined || typeof query !== 'string') {
            return ''
        }
        const indexOfQuestionMark = query.indexOf('?')
        if (indexOfQuestionMark > 0) {
            const slicedResult = query.slice(0, indexOfQuestionMark)
            return slicedResult
        }
        return query
    }

    /**
     * Convert searchParams to URL
     * @param searchParams
     * @param selectData
     * @param language
     * @param providedPath
     * @return URL
     */
    buildSearchPath(
        searchParams: Partial<ISearchFilterParams>,
        selectData: TSelectData,
        language: ELanguage,
        providedPath?: string,
        districtSlug?: string,
        districtId?: any
    ) {
        if (Object.keys(selectData).length === 0) {
            return ''
        }
        let searchPath = ''

        const removedQueryParam = this.removeQueryParam(searchParams.query)
        const keyword = encodeKeyword(removedQueryParam || '')
        const langCode = language
        const isEn = langCode === ELanguage.EN
        const suffixPath: string = isEn ? 'en' : 'vn'
        const suffixOnlyKeyword = isEn ? 'kw' : 'kv'
        const isManagementJob = searchResultsStore.getState().globalMisc.pageTab === EPageTab.MANAGEMENT_JOBS

        // Management Job
        if (isManagementJob) {
            return langCode === ELanguage.EN ? '/management-jobs' : '/viec-lam-quan-ly'
        }

        // detect if search params has keyword, category, location has filtered data rather than default values
        const searchStatus = this._hasInputSearchFilter(searchParams)

        const { isUrgentJob: isUrgentJobStatus, keyword: keywordStatus, ...searchStatusOnlyFilter } = searchStatus
        const hasFilter = Object.values(searchStatusOnlyFilter).some((hasSet) => !!hasSet)

        let isExceptionCase = false
        let exceptionCaseSearchPath = ''
        // Exceptional case: Only keyword provided
        if (searchStatus.keyword && !hasFilter) {
            isExceptionCase = true
            if (searchStatus.isUrgentJob) {
                const URGENT = isEn ? 'urgent' : 'tuyen-gap'
                exceptionCaseSearchPath = `/${keyword}-${suffixOnlyKeyword}-${URGENT}-uj`
            } else {
                exceptionCaseSearchPath = `/${keyword}-${suffixOnlyKeyword}`
            }
        }

        if (isExceptionCase) {
            return exceptionCaseSearchPath
        }

        searchPath = this.determineUrgentJobPage(searchStatus, providedPath)
        if (!searchStatus.keyword && !hasFilter && searchStatus.isUrgentJob) {
            return isEn ? '/urgent-jobs' : '/viec-can-tuyen-gap'
        }

        const categoryId = searchParams.categoryId || []

        let categorySlug = ''
        if (typeof categoryId === 'object') {
            categoryId.map((item, index) => {
                let slugText = ''
                if (
                    selectData.categoryById !== undefined &&
                    selectData.categoryById[item] !== undefined &&
                    selectData.categoryById[item]?.slug !== undefined
                ) {
                    slugText = selectData.categoryById[item]?.slug
                }
                categorySlug += `${index > 0 ? '-' : ''}${slugText}`
            })
        } else {
            const categoryIdInt = categoryId as number
            if (
                selectData.categoryById !== undefined &&
                selectData.categoryById[categoryIdInt] !== undefined &&
                selectData.categoryById[categoryIdInt]?.slug !== undefined
            ) {
                categorySlug += `${selectData.categoryById[categoryIdInt]?.slug}`
            }
        }

        const locationId = searchParams.locationId || []
        let locationSlug = ''
        if (typeof locationId === 'object') {
            locationId.map((item, index) => {
                let slugText = ''
                if (
                    selectData.locationById !== undefined &&
                    selectData.locationById[item] !== undefined &&
                    selectData.locationById[item]?.slug !== undefined
                ) {
                    slugText = selectData.locationById[item]?.slug
                }

                locationSlug += `${index > 0 ? '-' : ''}${slugText}`
            })
        } else {
            const locationIdInt = locationId as number
            if (
                selectData.locationById !== undefined &&
                selectData.locationById[locationIdInt] !== undefined &&
                selectData.locationById[locationIdInt]?.slug !== undefined
            ) {
                locationSlug += selectData.locationById[locationIdInt]?.slug
            }
        }

        const isUrgentJob = searchParams.isUrgentJob || false

        const typeWorkingId = searchParams.typeWorkingId || -1
        const typeWorkingSlug = this.buildTypeWorkingSlug(typeWorkingId, selectData.typeWorking, language)
        const searchSlug = this._buildSearchSlugText(
            keyword,
            categorySlug,
            locationSlug,
            langCode,
            isUrgentJob,
            typeWorkingSlug,
            hasFilter,
            districtSlug,
            districtId
        )
        const searchCodeStr = this._buildSearchCode(searchParams) // v29
        if (searchSlug !== '' || searchCodeStr !== '') {
            searchPath = `/${this.composePath(searchSlug, searchCodeStr, suffixPath)}`
        }

        searchPath = searchPath.replace('+-', '+')
        if (searchPath === '') {
            searchPath = language === 'vi' ? process.env.ROUTE_SEARCH_RESULT_VI : process.env.ROUTE_SEARCH_RESULT
        }

        return searchPath
    }

    buildSearchSlugPath(
        searchParams: Partial<ISearchFilterParams>,
        selectData: TSelectData,
        language: ELanguage,
        providedPath?: string
    ) {
        if (Object.keys(selectData).length === 0) {
            return ''
        }
        let searchPath = ''

        const removedQueryParam = this.removeQueryParam(searchParams.query)
        const keyword = encodeKeyword(removedQueryParam || '')
        const langCode = language
        const isEn = langCode === ELanguage.EN
        const suffixPath: string = isEn ? 'en' : 'vn'
        const suffixOnlyKeyword = isEn ? 'kw' : 'kv'
        const isManagementJob = searchResultsStore.getState().globalMisc.pageTab === EPageTab.MANAGEMENT_JOBS

        // Management Job
        if (isManagementJob) {
            return langCode === ELanguage.EN ? '/management-jobs' : '/viec-lam-quan-ly'
        }

        // detect if search params has keyword, category, location has filtered data rather than default values
        const searchStatus = this._hasInputSearchFilter(searchParams)

        const { isUrgentJob: isUrgentJobStatus, keyword: keywordStatus, ...searchStatusOnlyFilter } = searchStatus
        const hasFilter = Object.values(searchStatusOnlyFilter).some((hasSet) => !!hasSet)

        let isExceptionCase = false
        let exceptionCaseSearchPath = ''
        // Exceptional case: Only keyword provided
        if (searchStatus.keyword && !hasFilter) {
            isExceptionCase = true
            if (searchStatus.isUrgentJob) {
                const URGENT = isEn ? 'urgent' : 'tuyen-gap'
                exceptionCaseSearchPath = `/${keyword}-${suffixOnlyKeyword}-${URGENT}-uj`
            } else {
                exceptionCaseSearchPath = `/${keyword}-${suffixOnlyKeyword}`
            }
        }

        if (isExceptionCase) {
            return exceptionCaseSearchPath
        }

        searchPath = this.determineUrgentJobPage(searchStatus, providedPath)
        if (!searchStatus.keyword && !hasFilter && searchStatus.isUrgentJob) {
            return isEn ? '/urgent-jobs' : '/viec-can-tuyen-gap'
        }

        const categoryId = searchParams.categoryId || []

        let categorySlug = ''
        if (typeof categoryId === 'object') {
            categoryId.map((item, index) => {
                let slugText = ''
                if (
                    selectData.categoryById !== undefined &&
                    selectData.categoryById[item] !== undefined &&
                    selectData.categoryById[item]?.slug !== undefined
                ) {
                    slugText = selectData.categoryById[item]?.slug
                }
                categorySlug += `${index > 0 ? '-' : ''}${slugText}`
            })
        } else {
            const categoryIdInt = categoryId as number
            if (
                selectData.categoryById !== undefined &&
                selectData.categoryById[categoryIdInt] !== undefined &&
                selectData.categoryById[categoryIdInt]?.slug !== undefined
            ) {
                categorySlug += `${selectData.categoryById[categoryIdInt]?.slug}`
            }
        }

        const locationId = searchParams.locationId || []
        let locationSlug = ''
        if (typeof locationId === 'object') {
            locationId.map((item, index) => {
                let slugText = ''
                if (
                    selectData.locationById !== undefined &&
                    selectData.locationById[item] !== undefined &&
                    selectData.locationById[item]?.slug !== undefined
                ) {
                    slugText = selectData.locationById[item]?.slug
                }

                locationSlug += `${index > 0 ? '-' : ''}${slugText}`
            })
        } else {
            const locationIdInt = locationId as number
            if (
                selectData.locationById !== undefined &&
                selectData.locationById[locationIdInt] !== undefined &&
                selectData.locationById[locationIdInt]?.slug !== undefined
            ) {
                locationSlug += selectData.locationById[locationIdInt]?.slug
            }
        }

        const isUrgentJob = searchParams.isUrgentJob || false

        const typeWorkingId = searchParams.typeWorkingId || -1
        const typeWorkingSlug = this.buildTypeWorkingSlug(typeWorkingId, selectData.typeWorking, language)

        const slugDist = searchParams?.districtSlug

        let searchSlug = ''

        const AT = isEn ? 'at' : 'nganh'
        const IN = isEn ? 'in' : 'tai'
        const JOBS = isEn ? 'jobs' : 'viec-lam'
        const URGENT = isEn ? 'urgent' : 'tuyen-gap'

        if (keyword !== '') {
            let filterSlug = typeWorkingSlug

            if (categorySlug !== '') {
                filterSlug += `${filterSlug !== '' ? '-' : ''}${AT}-${categorySlug}`
            }

            if (locationSlug !== '') {
                filterSlug += `${filterSlug !== '' ? '-' : ''}${IN}-${
                    slugDist != '' ? slugDist + `-` : ``
                }${locationSlug}`
            }
            searchSlug += filterSlug !== '' ? `${keyword}+${filterSlug}` : `${keyword}+`
        } else {
            const prefixSlug = this.joinPath(
                isEn ? [typeWorkingSlug, categorySlug, JOBS] : [JOBS, typeWorkingSlug, categorySlug]
            )

            searchSlug = hasFilter ? prefixSlug : ''

            if (categorySlug !== '' && locationSlug !== '') {
                searchSlug = `${prefixSlug}-${IN}-${locationSlug}`
            } else {
                if (locationSlug !== '') {
                    searchSlug = `${isEn ? '' : `${prefixSlug}-`}${
                        slugDist && slugDist !== '' ? slugDist + `-` : ``
                    }${locationSlug}${isEn ? `-${prefixSlug}` : ''}`
                }
            }
        }

        if (isUrgentJob) {
            searchSlug = `${searchSlug}-${URGENT}-uj`
        }

        const searchCodeStr = this._buildSearchCode(searchParams)

        if (searchSlug !== '' || searchCodeStr !== '') {
            searchPath = `/${this.composePath(searchSlug, searchCodeStr, suffixPath)}`
        }

        searchPath = searchPath.replace('+-', '+')
        if (searchPath === '') {
            searchPath = language === 'vi' ? process.env.ROUTE_SEARCH_RESULT_VI : process.env.ROUTE_SEARCH_RESULT
        }

        return searchPath
    }

    buildSearchUrl(searchParams: Partial<ISearchFilterParams>, language: ELanguage) {
        const isManagementJob = searchResultsStore.getState().globalMisc.pageTab === EPageTab.MANAGEMENT_JOBS
        if (isManagementJob) {
            return language === ELanguage.EN ? '/management-jobs' : '/viec-lam-quan-ly'
        }

        const params = {}
        if (searchParams.query) {
            params[SEARCH_PARAMS.QUERY] = searchParams.query.replace(/ /g, '-')
        }
        if ((searchParams.locationId || []).length > 0) {
            params[SEARCH_PARAMS.LOCATION] = searchParams.locationId.join('.')
        }

        if ((searchParams.districtId || []).length > 0) {
            params['d'] = searchParams.districtId.join('.')
        }

        if ((searchParams.jobFunctionId || []).length > 0) {
            const groupJobFunctionId = []
            const jobFunctionId = []

            searchParams.jobFunctionId.map((item) => {
                groupJobFunctionId.push(item.parentId)
                item.childrenIds.map((child) => child !== -1 && jobFunctionId.push(child))
            })

            if (groupJobFunctionId.length > 0) {
                params[SEARCH_PARAMS.GROUP_JOB_FUNCTION] = groupJobFunctionId.join('.')
            }
            if (jobFunctionId.length > 0) {
                params[SEARCH_PARAMS.JOB_FUNCTION] = jobFunctionId.join('.')
            }
        }
        if ((searchParams.companyIndustries || []).length > 0) {
            params[SEARCH_PARAMS.INDUSTRY] = searchParams.companyIndustries.join('.')
        }
        if (searchParams.typeWorkingId > -1) {
            params[SEARCH_PARAMS.WORKING_TYPE] = searchParams.typeWorkingId
        }
        if (searchParams.jobLevelId > -1) {
            params[SEARCH_PARAMS.JOB_LEVEL] = searchParams.jobLevelId
        }
        if (searchParams.salaryId > -1) {
            params[SEARCH_PARAMS.SALARY] = searchParams.salaryId
        }
        if (searchParams.isUrgentJob) {
            params[SEARCH_PARAMS.URGENT] = String(searchParams.isUrgentJob)
        }
        if (searchParams.page > 0) {
            params[SEARCH_PARAMS.PAGE] = searchParams.page + 1
        }
        // #VNW-26233 add sorting param on url. Should be readable indexName
        if (
            searchParams.indexName &&
            ![SORT_DEFAULT_INDEX, sortByUrlParam[SORT_DEFAULT_INDEX]].includes(searchParams.indexName) &&
            sortByUrlParam[searchParams.indexName]
        ) {
            params[SEARCH_PARAMS.SORTING] = readableSortByUrlParamMap[searchParams.indexName]
        }

        if (Object.keys(params).length > 0) {
            const paramsString = '?' + new URLSearchParams(params).toString()
            const baseUrl = language === ELanguage.VI ? process.env.ROUTE_SEARCH_JOBS_VI : process.env.ROUTE_SEARCH_JOBS

            return baseUrl + paramsString
        } else {
            return language === ELanguage.VI ? process.env.ROUTE_SEARCH_RESULT_VI : process.env.ROUTE_SEARCH_RESULT
        }
    }

    getAllUrlParams(url: string) {
        // get query string from url (optional) or window
        var queryString = url ? url.split('?')[1] : window.location.search.slice(1)

        // we'll store the parameters here
        var obj = {}

        // if query string exists
        if (queryString) {
            // stuff after # is not part of query string, so get rid of it
            queryString = queryString.split('#')[0]

            // split our query string into its component parts
            var arr = queryString.split('&')

            for (var i = 0; i < arr.length; i++) {
                // separate the keys and the values
                var a = arr[i].split('=')

                // set parameter name and value (use 'true' if empty)
                var paramName = a[0]
                var paramValue = typeof a[1] === 'undefined' ? true : a[1]

                // (optional) keep case consistent
                paramName = paramName.toLowerCase()
                if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase()

                // if the paramName ends with square brackets, e.g. colors[] or colors[2]
                if (paramName.match(/\[(\d+)?\]$/)) {
                    // create key if it doesn't exist
                    var key = paramName.replace(/\[(\d+)?\]/, '')
                    if (!obj[key]) obj[key] = []

                    // if it's an indexed array e.g. colors[2]
                    if (paramName.match(/\[\d+\]$/)) {
                        // get the index value and add the entry at the appropriate position
                        var index = /\[(\d+)\]/.exec(paramName)[1]
                        obj[key][index] = paramValue
                    } else {
                        // otherwise add the value to the end of the array
                        obj[key].push(paramValue)
                    }
                } else {
                    // we're dealing with a string
                    if (!obj[paramName]) {
                        // if it doesn't exist, create property
                        obj[paramName] = paramValue
                    } else if (obj[paramName] && typeof obj[paramName] === 'string') {
                        // if property does exist and it's a string, convert it to an array
                        obj[paramName] = [obj[paramName]]
                        obj[paramName].push(paramValue)
                    } else {
                        // otherwise add the property
                        obj[paramName].push(paramValue)
                    }
                }
            }
        }

        return obj
    }

    collectFilterDataFromUrl(path: string = '', selectData: TSelectData): Partial<ISearchFilterParams> {
        const urlParams = this.getAllUrlParams(path)
        const groupJobFunction =
            urlParams[SEARCH_PARAMS.GROUP_JOB_FUNCTION]?.split('.').map((i: number) => Number(i)) || []
        const jobFuntion = urlParams[SEARCH_PARAMS.JOB_FUNCTION]?.split('.').map((i: number) => Number(i)) || []

        const jobFunctionId = groupJobFunction.map((id: number) => {
            const jobFunctionItem = {
                parentId: id,
                childrenIds: []
            }

            if (selectData?.jobFunction?.child[id]) {
                selectData.jobFunction.child[id].map((item) => {
                    if (jobFuntion.includes(item.id)) {
                        jobFunctionItem.childrenIds.push(item.id)
                    }
                })
            }
            // Select all job function (-1) if job function wasn't seleted
            if (jobFunctionItem.childrenIds.length === 0) {
                jobFunctionItem.childrenIds.push(-1)
            }

            return jobFunctionItem
        })
        const districtId = urlParams[SEARCH_PARAMS.DISTRICT]?.split('.')?.map((i: number) => Number(i)) || []
        const locationId = urlParams[SEARCH_PARAMS.LOCATION]?.split('.')?.map((i: number) => Number(i)) || []
        const locations = getLocationsByLocationIdsAndDistrictIds(locationId, districtId)

        // get district slug
        let districtSlug = ''
        const singleLocationId = locationId?.[0]
        const districts = singleLocationId ? selectData.districts[singleLocationId] : []
        if (districts && districtId.length > 0) {
            const districtMathWithPath = districts.find((district) => district.id === districtId[0])
            if (districtMathWithPath) {
                districtSlug = slugify(districtMathWithPath.name)
            }
        }

        const data = {
            query: decodeURIComponent(urlParams[SEARCH_PARAMS.QUERY] || '').replace(/-/g, ' '),
            locationId,
            districtId,
            districtSlug,
            locations,
            categoryId: urlParams[SEARCH_PARAMS.CATEGORY]?.split('.').map((i: number) => Number(i)) || [],
            jobFunctionId,
            companyIndustries: urlParams[SEARCH_PARAMS.INDUSTRY]?.split('.').map((i: number) => Number(i)) || [],
            typeWorkingId: Number(urlParams[SEARCH_PARAMS.WORKING_TYPE] || -1),
            jobLevelId: Number(urlParams[SEARCH_PARAMS.JOB_LEVEL] || -1),
            salaryId: Number(urlParams[SEARCH_PARAMS.SALARY] || -1),
            isUrgentJob: urlParams[SEARCH_PARAMS.URGENT],
            page: Number(urlParams[SEARCH_PARAMS.PAGE] || 1) - 1,
            indexName: urlParams[SEARCH_PARAMS.SORTING] || sortByUrlParam[SORT_DEFAULT_INDEX]
        }
        return data
    }

    composePath(slug: string, code: string, suffix: string) {
        return this.joinPath([slug, code, suffix])
    }

    joinPath(pathComponents: string[]) {
        return pathComponents.filter((str) => str !== '').join('-')
    }

    hasAnyFilter(searchParams: Partial<ISearchFilterParams>) {
        const searchStatus = this._hasInputSearchFilter(searchParams)
        const { isUrgentJob: isUrgentJobStatus, keyword: keywordStatus, ...searchStatusOnlyFilter } = searchStatus
        return Object.values(searchStatusOnlyFilter).some((hasSet) => !!hasSet)
    }

    determineUrgentJobPage(searchStatus, providedPath: string | undefined) {
        let searchPath = ''

        if (typeof window === 'undefined' || typeof window === null) {
            return searchPath
        }

        if (providedPath !== undefined && providedPath) {
            if (providedPath.match(/viec-can-tuyen-gap|urgent-jobs/) && searchStatus.isUrgentJob) {
                searchPath = providedPath
            }
        } else {
            if (
                window &&
                window.location &&
                window.location.pathname &&
                window.location.pathname.match(/viec-can-tuyen-gap|urgent-jobs/) &&
                searchStatus.isUrgentJob
            ) {
                searchPath = window.location.pathname
            }
        }

        return searchPath
    }

    buildTypeWorkingSlug(
        typeWorkingId: number,
        typeWorkingSelectData: TSelectData['typeWorking'],
        language: ELanguage
    ) {
        const typeWorkingItem = typeWorkingSelectData.find((item) => item.id === typeWorkingId)
        const typeWorkingViSlug = slugify(Utils.transformToLatin(typeWorkingItem?.name || ''))
        const typeWorkingSlug = (
            (language === ELanguage.EN ? typeWorkingItem?.nameEn : typeWorkingViSlug) || ''
        ).toLowerCase()

        return typeWorkingSlug
    }

    mappingCategoryIdToJobFunctionId(categoryIds: number[]) {
        const jobFunctionId = []

        categoryIds.map((categoryId) => {
            const id = listJobFunctionIdFromCategoryId[categoryId] || -1
            const isExistId = jobFunctionId.find((item) => item.parentId === id)

            if (!isExistId) {
                jobFunctionId.push({
                    parentId: id,
                    childrenIds: [-1]
                })
            }
        })

        return jobFunctionId
    }

    buildCanonicalPath(
        searchParams: Partial<ISearchFilterParams>,
        selectData: TSelectData,
        language: ELanguage,
        providedPath?: string
    ) {
        let query = searchParams.query

        if (searchParams.jobFunctionId.length > 0 && query === '') {
            const jobFunction = searchParams.jobFunctionId[0]
            const jobFunctionItems = selectData?.jobFunction?.child[jobFunction?.parentId]
            // choose first children of first parent
            let jobFunctionItem = jobFunctionItems && jobFunctionItems.length > 0 ? jobFunctionItems[0] : null
            // if not select all children (= -1), choose first children fo list
            if (jobFunction.childrenIds[0] !== -1) {
                jobFunctionItem = selectData.jobFunction.child[jobFunction.parentId].find(
                    (child) => child.id === jobFunction.childrenIds[0]
                )
            }
            if (jobFunctionItem !== null) {
                const jobFunctionName = language === ELanguage.EN ? jobFunctionItem.nameEn : jobFunctionItem.name
                query = Utils.transformToLatin(jobFunctionName).replace(/[^a-z]/g, ' ')
            }
        }
        if (searchParams.categoryId.length > 0 && query === '') {
            const categoryItem = selectData.category.find((i) => i.id === searchParams.categoryId[0])
            const categoryName = language === ELanguage.EN ? categoryItem?.nameEn : categoryItem?.name
            if (categoryName) {
                query = Utils.transformToLatin(categoryName).replace(/[^a-z]/g, ' ')
            }
        }
        if (searchParams.companyIndustries.length > 0 && query === '') {
            const companyIndustry = selectData.companyIndustry.find((i) => i.id === searchParams.companyIndustries[0])
            const companyIndustryName = language === ELanguage.EN ? companyIndustry.nameEn : companyIndustry.name
            query = Utils.transformToLatin(companyIndustryName).replace(/[^a-z]/g, ' ')
        }
        if (searchParams.jobLevelId > -1 && query === '') {
            const jobLevelItem = selectData.level.find((i) => i.id === searchParams.jobLevelId)
            const jobLevelName = language === ELanguage.EN ? jobLevelItem.nameEn : jobLevelItem.name
            query = Utils.transformToLatin(jobLevelName).replace(/[^a-z]/g, ' ')
        }
        if (searchParams.typeWorkingId > -1 && query === '') {
            const typeWorkingItem = selectData.typeWorking.find((i) => i.id === searchParams.typeWorkingId)
            const typeWorkingName = language === ELanguage.EN ? typeWorkingItem.nameEn : typeWorkingItem.name
            query = Utils.transformToLatin(typeWorkingName)
        }

        const canonicalParams = {
            ...searchParams
        }

        canonicalParams.jobFunctionId = []
        canonicalParams.categoryId = []
        canonicalParams.companyIndustries = []
        canonicalParams.jobLevelId = -1
        canonicalParams.typeWorkingId = -1
        canonicalParams.locationId = (searchParams.locationId || []).length > 0 ? [searchParams.locationId[0]] : []
        canonicalParams.isUrgentJob = false
        canonicalParams.query = query
        canonicalParams.districtId =
            (searchParams.locationId || []).length >= 1 && searchParams?.districtId.length >= 1
                ? searchParams?.districtId
                : []

        if (searchParams.locationId.length > 0) {
            for (const locationId of searchParams.locationId) {
                const districts = selectData.districts[locationId]
                if (districts && canonicalParams.districtId.length > 0) {
                    const districtId = canonicalParams.districtId[0]
                    const districtMathWithPath = districts.find((district) => district.id === districtId)
                    if (districtMathWithPath) {
                        canonicalParams.districtSlug = slugify(districtMathWithPath.name)
                    }
                }
            }
        }
        return this.buildSearchSlugPath(canonicalParams, selectData, language, providedPath)
    }
}

export const urlParamSync = new UrlParamSync()
