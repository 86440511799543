const translations = {
    'Job By': 'Sắp xếp theo',
    All: 'Tất cả',
    "Last update": "Ngày cập nhật",
    'Most relevant': 'Liên quan nhất',
    'Salary (high - low)': 'Lương (cao - thấp)',
    'Posted date (latest)': 'Ngày đăng (mới nhất)',
    'Posted date (oldest)': 'Ngày đăng (cũ nhất)',
    'Select to prioritize viewing most relevant jobs': 'Chọn để ưu tiên xem các việc làm liên quan nhất',
    'Search setting': 'Cài đặt tìm kiếm',
    'Your search result will always be sorted by most relevant.':
        'Kết quả tìm kiếm của bạn sẽ luôn được sắp xếp theo thứ tự liên quan nhất.',
    Successfully: 'Thành công',
    'You have changed setting successfully, view ': 'Bạn đã cài đặt tìm kiếm thành công, xem ',
    'Account setting': 'Quản lý tài khoản',
    '/account-settings': '/quan-ly-tai-khoan'
}

export default translations
