import React from 'react'

export default function MailBoxIcon() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' width='48px' height='48px'>
            <path fill='#005AFF' d='M45,34V18c0-4.418-3.582-8-8-8H16c-4.418,0-8,3.582-8,8v16H45z' />
            <path
                fill='#005AFF'
                d='M11,33c0,0.552-0.448,1-1,1H4c-0.552,0-1-0.448-1-1l0,0c0-0.552,0.448-1,1-1h6C10.552,32,11,32.448,11,33L11,33z'
            />
            <path fill='#0D47A1' d='M16,12c-3.309,0-6,2.691-6,6v14h12V18C22,14.691,19.309,12,16,12z' />
            <path fill='#FF3D00' d='M28 4H34V8H28z' />
            <path fill='#FF3D00' d='M28 6H30V17H28z' />
            <path fill='#FF3D00' d='M29 16A2 2 0 1 0 29 20A2 2 0 1 0 29 16Z' />
            <path fill='#DD2C00' d='M29 17A1 1 0 1 0 29 19A1 1 0 1 0 29 17Z' />
            <path fill='#FFCC80' d='M25 34H30V44H25z' />
            <path fill='#FFA726' d='M25 34H30V36H25z' />
            <path fill='#CFD8DC' d='M13,21v9c0,1.025,0.975,2,2,2h7V19h-7C13.975,19,13,19.975,13,21z' />
            <path fill='#78909C' d='M13,21v1l9,7v-2l-8.782-6.831C13.083,20.43,13,20.711,13,21z' />
        </svg>
    )
}
