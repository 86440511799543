import {TNestedGenericId} from '@microfrontends/react-components';

type JobAlertResponse = {
    meta?: {
        code?: number
        message?: string
    }
    data?: {
        job_alert_id?: number
    }
    Code?: number
}

type THeader = {
    Authorization?: string
    'Content-Type': string
}

type TResponse = {
    meta?: {
        code?: number
        message?: string
    }
    status?: number | string
    success?: boolean
    redirectUrl?: string
    data?: any
    code?: number
    attachedResume?: any
}

type TPostJobAlert = {
    keyword: string
    // eslint-disable-next-line camelcase
    salary_min: number
    // eslint-disable-next-line camelcase
    job_level?: number[]
    // eslint-disable-next-line camelcase
    job_level_id?: number
    location: number[]
    industry: number[]
    status: number
    // eslint-disable-next-line camelcase
    period_type_id: number
    // eslint-disable-next-line camelcase
    notification_by: number
    // eslint-disable-next-line camelcase
    user_email: string
    // eslint-disable-next-line camelcase
    language_id: number
    company_industry: number[]
    job_function: TNestedGenericId[]
    salaryPeriodId: number
    salaryCurrencyId: number
}

const createJobAlert = async (
    postData: Object,
    url: string,
    apiTokenAccess: string,
    isAnonymous: boolean
): Promise<JobAlertResponse> => {
    const headerData: THeader = {
        'Content-Type': 'application/json'
    }
    if (!isAnonymous) {
        headerData.Authorization = `Bearer ${apiTokenAccess}`
    }
    const response = await fetch(url, {
        method: 'POST',
        headers: headerData,
        body: JSON.stringify(postData)
    })
    return await response.json()
}

const deleteJobAlert = async (url: string, apiTokenAccess: string): Promise<JobAlertResponse> => {
    const response = await fetch(url, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${apiTokenAccess}`,
            'Content-Type': 'application/json'
        }
    })
    return await response.json()
}

const getJobLevelList = async (url: string): Promise<TResponse> => {
    const response = await fetch(url, {
        method: 'GET'
    })
    return await response.json()
}

export const jobAlertService = {
    createJobAlert,
    deleteJobAlert,
    getJobLevelList
}

export type {
    JobAlertResponse,
    TResponse,
    TPostJobAlert
}
