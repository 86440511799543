import { ISearchFilterParams } from '../../../../types/ISearchFilterParams'
import { IUrlSyncOptions } from '../../../../types/IUrlSyncOptions'

export const SEARCH_JOBS = 'SEARCH_JOBS'
export const PUT_SEARCH_JOBS = 'PUT_SEARCH_JOBS'
export const PUT_RESET_DATA = 'PUT_RESET_DATA'
export const PUT_UPDATE_PARAMS = 'UPDATE_PARAMS'
export const PUT_UPDATE_PARTIAL_PARAMS = 'PUT_UPDATE_PARTIAL_PARAMS'
export const PUT_OUT_STANDING_JOBS = 'PUT_OUT_STANDING_JOBS'
export const INFINITY_JOBS = 'INFINITY_JOBS'
export const APPEND_SEARCH_JOBS = 'APPEND_SEARCH_JOBS'
export const APPEND_OUTSTANDING_JOBS = 'APPEND_OUTSTANDING_JOBS'
export const PUT_TOP_PRIORITY_JOB = 'PUT_TOP_PRIORITY_JOB'

// Get jobs with queries
export const createSearchJobsAction = (
    params: Partial<ISearchFilterParams>,
    urlSyncOptions: IUrlSyncOptions = { syncUrl: true, allJobsPath: '', language: 'vi', isUpdateNewURL: true }
) => ({
    type: SEARCH_JOBS,
    params: params,
    urlSyncOptions: urlSyncOptions
})

export type TInfinityJobsActionParams = {
    type: string
    target: 'jobs' | 'outstanding'
    page: number
}

export const createInfinityJobsAction = ({ target, page }: Omit<TInfinityJobsActionParams, 'type'>) => ({
    type: INFINITY_JOBS,
    target,
    page
})
