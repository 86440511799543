import { TAuthUser } from '../../../../handlers/auth/interface'
import {
    CREATE_JOB_ALERT_FAILED,
    CREATE_JOB_ALERT_SUCCESS,
    DELETE_JOB_ALERT_FAILED,
    DELETE_JOB_ALERT_SUCCESS,
    GET_JOB_ALERT_LIST_FAILED,
    GET_JOB_ALERT_LIST_SUCCESS,
    jobAlertAction,
    TIndexedPayload,
    UPDATE_JOB_ALERT_FAILED,
    UPDATE_JOB_ALERT_SUCCESS
} from './jobAlertActions'
import { EJobAlertActionName, TJobAlertItem } from './types'

export type TJobAlertLastAction = {
    name: EJobAlertActionName | ''
    isSuccess: boolean
    ts: number
    message?: string
}

export type JobAlertState = {
    items: TJobAlertItem[]
    user: TAuthUser | null
    email: string // email is using for anonymous job alert.
    lastAction: TJobAlertLastAction
}

const defaultState: JobAlertState = {
    items: [],
    user: null,
    email: '',
    lastAction: {
        name: '',
        isSuccess: false,
        ts: 0
    }
}

export default function jobAlertReducer(state: JobAlertState = defaultState, action: jobAlertAction) {
    const { type, payload } = action

    const nowTS = Math.floor(Date.now() / 1000)

    switch (type) {
        case GET_JOB_ALERT_LIST_SUCCESS: {
            return {
                ...state,
                items: payload,
                lastAction: {
                    name: EJobAlertActionName.ShowList,
                    isSuccess: true,
                    ts: nowTS
                }
            }
        }

        case GET_JOB_ALERT_LIST_FAILED: {
            return {
                ...state,
                lastAction: {
                    name: EJobAlertActionName.ShowList,
                    isSuccess: false,
                    ts: nowTS
                }
            }
        }

        case CREATE_JOB_ALERT_SUCCESS: {
            return {
                ...state,
                items: [...state.items, payload],
                lastAction: {
                    name: EJobAlertActionName.Create,
                    isSuccess: true,
                    ts: nowTS
                }
            }
        }

        case CREATE_JOB_ALERT_FAILED: {
            return {
                ...state,
                lastAction: {
                    name: EJobAlertActionName.Create,
                    isSuccess: false,
                    ts: nowTS,
                    message: payload
                }
            }
        }

        case UPDATE_JOB_ALERT_SUCCESS: {
            const { item: updatedItem, index: updatedIndex } = payload as TIndexedPayload

            return {
                ...state,
                items: state.items.map((item, index) => {
                    if (index !== updatedIndex) {
                        return item
                    }

                    const updatedItemObj = updatedItem as TJobAlertItem

                    return {
                        ...item,
                        ...updatedItemObj
                    }
                }),
                lastAction: {
                    name: EJobAlertActionName.Update,
                    isSuccess: true,
                    ts: nowTS
                }
            }
        }

        case UPDATE_JOB_ALERT_FAILED: {
            return {
                ...state,
                lastAction: {
                    name: EJobAlertActionName.Update,
                    isSuccess: false,
                    ts: nowTS,
                    message: payload
                }
            }
        }

        case DELETE_JOB_ALERT_SUCCESS: {
            const { index: deletedIndex } = payload as TIndexedPayload

            return {
                ...state,
                items: state.items.filter((_, index) => {
                    return index !== deletedIndex
                }),
                lastAction: {
                    name: EJobAlertActionName.Delete,
                    isSuccess: true,
                    ts: nowTS
                }
            }
        }

        case DELETE_JOB_ALERT_FAILED: {
            return {
                ...state,
                lastAction: {
                    name: EJobAlertActionName.Delete,
                    isSuccess: false,
                    ts: nowTS
                }
            }
        }

        default:
            return state
    }
}
