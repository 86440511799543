import cookie from 'js-cookie'

import RecommendService from '../../../services/recommendService'

export async function fetchRecommendJobs(
    userId: number = 0,
    langCode: string = 'vi'
) {
    const recommendService = new RecommendService(
        process.env.VNW_PREDICTIONIO_URL || ''
    )
    const jobId = getLastViewJobId()

    const jobs = await recommendService.getRecommendJobs(
        jobId,
        userId,
        20,
        'get',
        langCode
    )
    return jobs
}

export function getLastViewJobId() {
    const lastViewCookie = cookie.get('VNW_LAST_JOB_SEEN')
    if (lastViewCookie) {
        const lastViewJobData = decodeURIComponent(lastViewCookie).split(',')
        const jobId = lastViewJobData[0] ? parseInt(lastViewJobData[0]) : 0
        // other than that case, return job Id
        return jobId
    }

    return 0
}
