import { EPageTab } from '../types/EPageTag'

export const IS_ROBOT_REQUESTING: string = 'IS_ROBOT_REQUESTING'
export const SET_AUTH_USER: string = 'SET_AUTH_USER'
export const SET_DETECTED_LOCATION: string = 'SET_DETECTED_LOCATION'
export const SET_PAGE_TAB: string = 'SET_PAGE_TAB'
export const SET_RESTRICT_ROUTES: string = 'SET_RESTRICT_ROUTES'
export const SET_LANGUAGE: string = 'SET_LANGUAGE'
export const SET_IS_REMOVE_LOCATION = 'SET_IS_REMOVE_LOCATION'

export const isRobotRequestionAction = (isRobotRequesting) => {
    return {
        type: IS_ROBOT_REQUESTING,
        payload: { isRobotRequesting }
    }
}

export const setPageTabAction = (pageTab: EPageTab) => {
    return {
        type: SET_PAGE_TAB,
        payload: pageTab
    }
}

export const setRestrictRoutesAction = (routes: string[]) => {
    return {
        type: SET_RESTRICT_ROUTES,
        payload: routes
    }
}

export const setIsRemoveLocation = (isRemove: boolean) => {
    return {
        type: SET_IS_REMOVE_LOCATION,
        payload: {
            isRemoveLocation: isRemove
        }
    }
}
