export enum ESearchSetting {
    DEFAULT = 0,
    RELEVANT = 1
}

export type TAuthUser = {
    id: number
    userName: string
    userId: number
    firstName: string
    lastName: string
    profilePicture: string
    jobTitle: string
    accessToken: string
    refreshToken: string
    industryList: Array<any>
    jobLevelId: Array<any>
    searchSettingSortType: ESearchSetting
}
