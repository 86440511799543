import isEmpty from 'lodash/isEmpty'
import { debounce, put } from 'redux-saga/effects'
import { TSelectData } from '../../../../helpers/filterHelper'
import initialDataService, { IInitialSelectData } from '../../../../services/initialDataService'
import { EPageTab } from '../../../../types/EPageTag'
import { ISearchFilterParams } from '../../../../types/ISearchFilterParams'
import { ELanguage } from '../../../../utils/language'
import { slugify } from '../../../../utils/utilities'
import { GET_SELECT_DATA, PUT_SELECT_DATA } from './initialDataActions'
import { initialDataProvider } from '@microfrontends/app-shell-v2'

const { getInitialSelectData } = initialDataService

interface IGetSelectDataAction {
    type: string
    params: any
    callback?: any
    path?: string | null
    language: ELanguage
    lastParams: ISearchFilterParams
    pageTab: EPageTab
}

type TSlugItem = {
    name: string
    slug: string
}

export function* watchGetData() {
    yield debounce(0, GET_SELECT_DATA, getSelectData)
}

export function* getSelectData(action: IGetSelectDataAction) {
    const { callback, language, path } = action

    try {
        // Get initial select data using our API
        const rawSelectData = yield getInitialSelectData(language)

        // Process the result
        if (rawSelectData.status !== 200) {
            const statusCode = rawSelectData.status ? rawSelectData.status.code : 520
            console.log('status code ' + statusCode)
            console.log('error text ' + rawSelectData.error)
        } else {
            const selectData = preprocessInitialSelectData(rawSelectData, language)
            yield put({ type: PUT_SELECT_DATA, payload: selectData })
            if (callback) callback(path)
        }
    } catch (err) {
        // TODO: [Open] dispatch an action to inform user that the site is not working at the moment ?
        if (err instanceof Error) {
            console.error('Error when getting select data', err.message)
        }
    }
}

// TODO: [Refactor] Simplify this function
export const preprocessInitialSelectData = (rawSelectData, language) => {
    const selectData = {
        location: rawSelectData.data.city.data.relationships.data,
        jobLevel: rawSelectData.data.jobLevel.data.relationships.data,
        category: rawSelectData.data.industry.data.relationships.data,
        salary: rawSelectData.data.salary.data.relationships.data,
        benefit: rawSelectData.data.benefit.data.relationships.data,
        typeWorking: rawSelectData.data.typeWorking.data.relationships.data,
        companySize: rawSelectData.data.companySize.data.relationships.data,
        districts: rawSelectData.data.district.data.relationships.data
    }

    const parsedSelectData: any = Object.entries(selectData).reduce((total, current: Array<any>): any => {
        const parsedLanguage =
            Array.isArray(current[1]) && !isEmpty(current[1])
                ? current[1].map((item: any) => {
                      if (current[0] === 'salary') {
                          // case Salary
                          let parsedName = ''
                          let ids: Array<number> = []
                          switch (item.attributes.minSalary) {
                              case 0:
                                  ids = [0, 500]
                                  parsedName = '<= $500'
                                  break
                              case 500:
                                  ids = [500, 1000]
                                  parsedName = '$500-$1000'
                                  break
                              case 1000:
                                  ids = [1000, 1500]
                                  parsedName = '$1000-$1500'
                                  break
                              case 1500:
                                  ids = [1500, 2000]
                                  parsedName = '$1500-$2000'
                                  break
                              case 2000:
                                  ids = [2000, 3000]
                                  parsedName = '$2000-$3000'
                                  break
                              case 3000:
                                  ids = [3000, 0]
                                  parsedName = '>=$3000'
                                  break
                              default:
                                  ids = [-1, -1]
                                  parsedName = ''
                          }
                          return {
                              id: ids,
                              name: parsedName
                          }
                      } else {
                          // others

                          return {
                              id: item.id,
                              name: language === 'en' ? item.attributes.nameEn : item.attributes.nameVi,
                              nameEn: item.attributes.nameEn
                          }
                      }
                  })
                : []

        return (total = Object.assign({}, total, {
            [current[0]]: parsedLanguage
        }))
    }, [])

    // Calculate categories by id
    const categoryById: TSlugItem[] = []
    parsedSelectData.category.forEach((item: { id: number; name: string; nameEn: string }) => {
        const { id, nameEn, name } = item
        const metaName = language === 'en' ? nameEn : name
        const newItem = {
            name: metaName,
            slug: slugify(metaName)
        }
        categoryById[id] = newItem
    })

    // Calculate locations by id
    const locationById: TSlugItem[] = []
    parsedSelectData.location.forEach((item: { id: number; name: string; nameEn: string }) => {
        const { id, nameEn, name } = item
        const metaName = language === 'en' ? nameEn : name
        const newItem = {
            name: metaName,
            slug: slugify(metaName),
            id: id
        }
        locationById[id] = newItem
    })

    // Calculate district by id
    const districtId: TSlugItem[] = []
    parsedSelectData.districts.forEach((item: { id: number; name: string; nameEn: string }) => {
        const { id, nameEn, name } = item
        const metaName = language === 'en' ? nameEn : name
        const newItem = {
            id: id,
            name: metaName,
            slug: slugify(metaName)
        }
        districtId[id] = newItem
    })

    const filteredData = {
        typeWorking: parsedSelectData.typeWorking,
        salary: parsedSelectData.salary,
        level: parsedSelectData.jobLevel,
        category: parsedSelectData.category,
        location: parsedSelectData.location,
        benefit: parsedSelectData.benefit,
        companySize: parsedSelectData.companySize,
        locationById,
        categoryById,
        companyIndustry: initialDataProvider.getIndustries(),
        jobFunction: initialDataProvider.getJobFunction(),
        districtId: districtId,
        districts: initialDataProvider.getDistricts()
    }

    return filteredData
}

export type TProcessedInitialSelectData = ReturnType<typeof preprocessInitialSelectData>

export async function getProcessedInitialSelectData(language: ELanguage): Promise<TSelectData> {
    const rawSelectData: IInitialSelectData = await getInitialSelectData(language) // Initial Data
    const processedData: TProcessedInitialSelectData = preprocessInitialSelectData(rawSelectData, language)
    return processedData
}
