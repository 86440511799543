/* eslint-disable prettier/prettier */
export const LANG_ID_VI = 1
export const LANG_ID_EN = 2
export const LANG_CODE_VI = 'vi'
export const LANG_CODE_EN = 'en'

export enum ELanguage {
    EN = 'en',
    VI = 'vi'
}

export type TLanguage = ELanguage

export const transformCodeToId = (code: string): number => {
    return code === LANG_CODE_EN ? LANG_ID_EN : LANG_ID_VI
}

export const transformIdToCode = (id: number): string => {
    return id === LANG_ID_EN ? LANG_CODE_EN : LANG_CODE_VI
}
