import { useSelector } from "react-redux"
import { TRootState } from "../../searchResultsRootReducers"
import { useEffect } from "react"
import { TJobAlertItem } from "../JobAlerts/types"
import { DEFAULT_MAX_JOB_ALERT } from "../JobAlerts"
import { getDayDifference } from "../../helpers/datetime"
import { NUMBER_DAY_OF_IGNORE, getLocalStorageKeyword, saveLocalStorage } from "../../helpers/jobAlertKeyword"

export type TExpireKeyword = {
    keyword: string
    expiredDate: string
}

interface INextPageJobAlertProps {
    isModalShow: boolean
    jobAlerts: TJobAlertItem[]
    getListApiCalled: boolean
    getJobAlerts: () => void
    showJobAlert: () => void
}

const NextPageJobAlert = (props: INextPageJobAlertProps) => {
    const { isModalShow, jobAlerts, getListApiCalled, getJobAlerts, showJobAlert } = props

    const { globalMisc, searchFilter } = useSelector((state: TRootState) => state)

    const checkIsIgnoreKeyword = (expireKeywordItem: TExpireKeyword) => {
        if (!expireKeywordItem) {
            return false
        }

        const today = new Date()
        const expiredDate = new Date(expireKeywordItem.expiredDate)

        const dayDiff = getDayDifference(expiredDate, today)

        const isHideJobAlert = dayDiff <= NUMBER_DAY_OF_IGNORE

        if (!isHideJobAlert) {
            // Update keyword with new expired date
            saveLocalStorage(expireKeywordItem.keyword)
        }

        return isHideJobAlert
    }

    useEffect(() => {
        // Don't show when: not in page larger than 1
        // Didn't have query
        // Didn't have selected locations
        // Didn't logged in
        if (searchFilter.lastParams.query === '' ||
            searchFilter.lastParams.page === 0 ||
            globalMisc.user === null
        ) {
            return
        }

        if (!getListApiCalled) {
            getJobAlerts()
            return
        }
        const keywordItem = getLocalStorageKeyword(searchFilter.lastParams.query)
        const isIgnore = checkIsIgnoreKeyword(keywordItem)

        // Prevent max job alerts
        if (jobAlerts.length >= DEFAULT_MAX_JOB_ALERT || isModalShow || isIgnore) {
            return
        }

        const filteredJobAlertWithTitle = jobAlerts.filter((item) => item.jobTitle === searchFilter.lastParams.query)
        // Job alert already exists!
        if (filteredJobAlertWithTitle.length > 0) {
            return
        }

        showJobAlert()
    }, [searchFilter.lastParams.page, searchFilter.lastParams.query, searchFilter.lastParams.locationId, jobAlerts, globalMisc.user, getListApiCalled])

    return <></>
}

export default NextPageJobAlert
