export const GET_SPECIAL_JOBS = 'GET_SPECIAL_JOBS'
export const PUT_SPECIAL_JOBS = 'PUT_SPECIAL_JOBS'

export type TGetSpecialJobActionType = 'jobs' | 'outstanding-jobs' | 'top-priority-jobs'
export function createGetSpecialJobAction(
    token: string,
    jobIds: Array<number>,
    hits: Array<any>,
    type: TGetSpecialJobActionType = 'jobs'
) {
    return {
        type: GET_SPECIAL_JOBS,
        params: {
            token: token,
            jobIds: jobIds,
            hits: hits,
            type: type
        }
    }
}
