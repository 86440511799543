import { TJobInvitation } from '@microfrontends/react-components/components/ApplyJobs/types'
import {
    MANAGEMENT_JOB_BY_DATE,
    MANAGEMENT_JOB_BY_SALARY,
    SORT_POSTED_LATEST_INDEX,
    SORT_POSTED_OLDEST_INDEX,
    SORT_RELEVANCE_INDEX,
    SORT_SALARY_INDEX,
    HITS_PER_PAGE,
    SORT_DEFAULT_INDEX
} from '../../../env'
import { IBenefit } from '../components/JobList/JobItem'
import { urlParamSync } from '../helpers/urlParamSync'
import { ISearchFilterParams } from '../types/ISearchFilterParams'
import { ELanguage } from '../utils/language'
import { ESearchSetting } from '../../../handlers/auth/interface'

const X_SOURCE = 'Page-Container'
const API_BASE = process.env.VNW_JOBSEARCH_URL || ''
const BASE_MS = process.env.MICROSERVICE_DOMAIN || ''
const JOB_LEVEL_MANAGER = 7
const JOB_LEVEL_DIRECTOR_AND_ABOVE = 3
const EMPTY_RESPONSE = {
    hits: [],
    hitsPerPage: 0,
    nbHits: 0,
    nbPage: 0,
    page: 0
}
const API_UPDATE_SEACH_SETTING = `${process.env.MICROSERVICE_DOMAIN}/api-gateway/v1.0/save-search-setting`

export interface IClientSearchParams {
    query: string
    params: QueryParameters
    user: any
}

interface QueryParameters {
    userId?: number
    query?: string
    filter?: object[]
    exclude?: object[]
    ranges?: object[]
    order?: object[]
    hitsPerPage?: number
    page?: number
    retrieveFields?: Array<string>
    summaryVersion?: string
    isTopPriority?: boolean
}

export type TSkills = {
    skillId: number
    skillName: string
    skillWeight: number
}

export interface Job {
    benefits: Partial<IBenefit[]>
    jobTitle: string
    salaryMax: number
    isSalaryVisible: boolean
    jobLevelVI: string
    isShowLogo: boolean
    salaryMin: number
    companyLogo: string
    userId: number
    jobLevel: string
    jobId: number
    companyId: number
    publishedDate: number
    isAnonymous: boolean
    alias: string
    expiredDate: number
    categoryVIs: string[]
    categoryIds: number[]
    categories: string[]
    company: string
    jobSalary: number
    onlineDate: number
    locationVIs: string[]
    locationIds: number[]
    locations: string[]
    serviceCode: string[]
    isUrgentJob: boolean
    isPremium: boolean
    isHeadhuntJob?: boolean
    isTopManagementJob?: boolean // use in tab management jobs
    isBoldAndRedJob: boolean
    isPriorityJob: boolean
    visibilityDisplay: 1 | 0
    isShowLogoInSearchResult?: boolean
    priorityOrder?: number
    skills: TSkills[]
    profilePublishedSiteMask: 0 | 1
    address: string
    jobDescription: string
    jobRequirement: string
    onlineServiceCode?: string
    jobInvitation?: TJobInvitation
    summary?: string[]
}

export interface ResponseJobSearch {
    hits: any[]
    hitsPerPage: number
    nbHits: number
    nbPage: number
    page: number
    params: string
    query: string
}

interface Filter {
    field?: string
    value?: string
}

interface Order {
    field?: string
    value?: string
}

interface Range {
    field: string
    value: string
    operator: string | null
}

class JobSearchService {
    private readonly _defaultJob: Job

    constructor() {
        this._defaultJob = {
            benefits: [],
            expiredDate: 0,
            jobTitle: '',
            visibilityDisplay: 0,
            categoryVIs: [],
            salaryMax: 0,
            isSalaryVisible: false,
            jobLevelVI: '',
            isShowLogo: false,
            isHeadhuntJob: false,
            isUrgentJob: false,
            isPriorityJob: false,
            alias: '',
            company: '',
            categories: [],
            salaryMin: 0,
            locationVIs: [],
            jobSalary: 0,
            companyLogo: '',
            locationIds: [],
            userId: 0,
            jobLevel: '',
            onlineDate: 0,
            jobId: 0,
            companyId: 0,
            categoryIds: [],
            locations: [],
            priorityOrder: 0,
            publishedDate: 0,
            isPremium: false,
            isBoldAndRedJob: false,
            serviceCode: [],
            isAnonymous: false,
            isShowLogoInSearchResult: false,
            skills: [],
            profilePublishedSiteMask: 0,
            address: '',
            jobDescription: '',
            jobRequirement: ''
        }
    }

    async search(filterParams: ISearchFilterParams): Promise<ResponseJobSearch> {
        /**
         * [Production - Anonymous job] Not hide anonymous job on company job list
         *
         * @author Tinh Tran
         * @since Mar 24, 2022
         * Set isAnonymous is true in company-profile page
         */
        if (filterParams.userId > 0) {
            filterParams.isAnonymous = false
        }
        const searchParams = this._transformParams(filterParams)

        return this._fetchJobs(API_BASE + '/v1.0/search', 'post', searchParams)
    }

    async searchByJobId(jobId: number): Promise<ResponseJobSearch> {
        const filters: object[] = []
        filters.push(this._formatFilterES('jobId', jobId.toString()))

        const retrieveFields = ['jobId', 'industriesV3', 'workingLocations']

        const searchParams = {
            query: '',
            params: {
                userId: 0,
                query: '',
                filter: filters,
                ranges: [],
                order: [],
                hitsPerPage: 1,
                page: 0,
                retrieveFields,
                summaryVersion: ''
            },
            user: -1
        }
        return this._fetchJobs(API_BASE + '/v1.0/search', 'post', searchParams)
    }

    async jobCounts(filterParams: ISearchFilterParams): Promise<{ count: number }> {
        let langCode = ELanguage.VI
        if (filterParams.userId > 0) {
            filterParams.isAnonymous = false
        }
        const searchParams = this._transformParams(filterParams)
        if (typeof window !== 'undefined') {
            langCode = urlParamSync.getLanguageFromPath(window.location.pathname)
        }
        const response = await fetch(API_BASE + '/v1.0/count-jobs', {
            method: 'POST',
            headers: {
                'Accept-Language': langCode,
                'X-Source': X_SOURCE,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(searchParams.params)
        })
        if (response.ok) {
            const data = await response.json()
            if (data.data) {
                return data.data
            }
            return { count: 0 }
        }
        return {
            count: 0
        }
    }

    async _fetchJobs(url: string, method: string, searchParams: IClientSearchParams): Promise<any> {
        if (searchParams.user?.userId) {
            searchParams.params.userId = searchParams.user?.userId
        }
        let langCode = ELanguage.VI
        if (typeof window !== 'undefined') {
            langCode = urlParamSync.getLanguageFromPath(window.location.pathname)
        }

        const requestOptions = {
            method: method,
            headers: {
                'Accept-Language': langCode,
                'X-Source': X_SOURCE,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(searchParams.params)
        }

        try {
            const response = await fetch(url, requestOptions)

            if (!response.ok) {
                const message = `An error has occurred: ${response.status}`
                throw new Error(message)
            }

            if (response.ok) {
                const result = await response.json()
                if (result) {
                    const responseJobSearch: ResponseJobSearch = {
                        hits: result.data,
                        hitsPerPage: result.meta.hitsPerPage,
                        nbHits: result.meta.nbHits,
                        nbPage: result.meta.nbPages,
                        page: result.meta.page,
                        params: JSON.stringify(searchParams.params),
                        query: searchParams.query
                    }

                    const handler = {
                        get: (target, name) => {
                            const defaultValue: any = this._defaultJob[name]
                            if (name in target) {
                                return target[name]
                            } else {
                                return defaultValue
                            }
                        }
                    }

                    const proxies = responseJobSearch.hits.map((job) => {
                        return new Proxy(job, handler)
                    })

                    return Object.assign({}, responseJobSearch, {
                        hits: proxies
                    })
                }
                console.error("Can't get data from response")
            }
            const message = `An error has occurred: ${response?.status}`
            throw new Error(message)
        } catch (e) {
            console.error('Something error when call job search api :: ' + e)
            return { ...EMPTY_RESPONSE, params: JSON.stringify(searchParams.params), query: searchParams.query }
        }
    }

    async searchFilterTags(params, isManagementJob) {
        if (params.userId > 0) {
            params.isAnonymous = false
        }
        if (isManagementJob) {
            params.jobLevelId = [7, 3]
        }

        const searchParams = this._transformParams(params)

        return this._fetchSkillTag(API_BASE + '/v1.0/skill', 'post', searchParams)
    }

    async _fetchSkillTag(url: string, method: string, searchParams: IClientSearchParams): Promise<any> {
        const requestOptions = {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(searchParams.params)
        }

        try {
            const response = await fetch(url, requestOptions)

            if (!response.ok) {
                const message = `An error has occurred: ${response.status}`
                throw new Error(message)
            }

            if (response.ok) {
                const { data } = await response.json()
                return data
            }
        } catch (e) {
            console.error('Something error when call job search api :: ' + e)
        }
    }

    _formatRangeES(fieldName: string, value: string, operator: string = ''): Range {
        const range: Range = {
            field: fieldName,
            value: value,
            operator: operator
        }

        return range
    }

    _formatFilterES(fieldName: string, value: string): Filter {
        return {
            field: fieldName,
            value: value
        }
    }

    _formatOrderES(fieldName: string, value: string): Order {
        return {
            field: fieldName,
            value: value
        }
    }

    // create query before search job
    _transformParams(params: ISearchFilterParams): IClientSearchParams {
        const {
            salary,
            jobLevelId,
            locationId,
            jobFunctionId = [],
            companyIndustries = [],
            benefitIds,
            locations,
            page,
            userId,
            typeWorkingId,
            isUrgentJob,
            isManagementJob,
            isAnonymous,
            indexName,
            hitsPerPage = HITS_PER_PAGE,
            isTopPriority
        } = params
        const filters: object[] = []
        const orders: object[] = []
        const ranges: object[] = []
        const summaryVersion = ''

        if (Array.isArray(benefitIds) && benefitIds.length) {
            benefitIds.map((benefitId) => {
                filters.push(this._formatFilterES('benefits.benefitId', benefitId.toString()))
            })
        }

        if (Array.isArray(companyIndustries) && companyIndustries.length > 0) {
            companyIndustries.map((industryId) => {
                filters.push(this._formatFilterES('industriesV3.industryV3Id', industryId.toString()))
            })
        }

        if (jobFunctionId.length > 0) {
            filters.push(this._formatFilterES('jobFunction', JSON.stringify(jobFunctionId)))
        }

        if (locationId.length > 0) {
            const locationIdFilter = locationId.filter((location) => !isNaN(location) && typeof location !== 'string')
            if (locationIdFilter.length > 0) {
                filters.push(this._formatFilterES('workingLocations.cityId', locationIdFilter.join(',')))
            }
            // district should come with location if they have one
            const mappedLocations: { cityId: number; districtId: number[] }[] = []

            locations &&
                locations.map((cityInfo) => {
                    mappedLocations.push({
                        cityId: cityInfo.parentId,
                        districtId: cityInfo.childrenIds
                    })
                })
            if (mappedLocations.length > 0) {
                filters.push(this._formatFilterES('workingLocations.districtId', JSON.stringify(mappedLocations)))
            }
        }

        if (jobLevelId !== -1) filters.push(this._formatFilterES('jobLevelId', jobLevelId.toString()))

        if (typeWorkingId !== -1) filters.push(this._formatFilterES('typeWorkingId', typeWorkingId.toString()))

        if (userId) filters.push(this._formatFilterES('userId', userId.toString()))
        /*
         * Because isAnonymous is default true and we don't want filter with it, so we only want filter with false value
         */
        if (isAnonymous !== undefined && !isAnonymous)
            filters.push(this._formatFilterES('isAnonymous', isAnonymous.toString()))
        /**
         * VNW-18143 Add service Urgent Job -M
         *
         * Because UJ and UJ-M are same rule search on Web
         *
         * @author Thai Tran
         * @since Otc 6, 2022
         */
        if (isUrgentJob) {
            filters.push(this._formatFilterES('simpleServices', '"isUrgentJob","isUrgentJobM"'))
        }
        /*
         * Job Search > Tab Management Jobs isManagementJob = true
         * https://navigosgroup.atlassian.net/wiki/spaces/VIET/pages/2069200909/Management+Job+Tab
         */
        if (isManagementJob) {
            filters.push(this._formatFilterES('jobLevelId', JOB_LEVEL_MANAGER + ',' + JOB_LEVEL_DIRECTOR_AND_ABOVE))
        }

        const checkSalary = typeof salary === 'object' && salary.some((item) => item !== -1)
        if (checkSalary) {
            switch (salary[0]) {
                case 0:
                    ranges.push(this._formatRangeES('salaryUSD', '500', '<='))
                    break
                case 500:
                    ranges.push(this._formatRangeES('salaryUSD', '500', '>='))
                    ranges.push(this._formatRangeES('salaryUSD', '1000', '<='))
                    break
                case 1000:
                    ranges.push(this._formatRangeES('salaryUSD', '1000', '>='))
                    ranges.push(this._formatRangeES('salaryUSD', '1500', '<='))
                    break
                case 1500:
                    ranges.push(this._formatRangeES('salaryUSD', '1500', '>='))
                    ranges.push(this._formatRangeES('salaryUSD', '2000', '<='))
                    break
                case 2000:
                    ranges.push(this._formatRangeES('salaryUSD', '2000', '>='))
                    ranges.push(this._formatRangeES('salaryUSD', '3000', '<='))
                    break
                case 3000:
                    ranges.push(this._formatRangeES('salaryUSD', '3000', '>='))
                    break
                default:
            }
        }

        // sort with id from Frontend via indexName
        if (indexName === SORT_SALARY_INDEX) orders.push(this._formatOrderES('salary', 'desc'))
        if (indexName === SORT_POSTED_LATEST_INDEX) orders.push(this._formatOrderES('approvedOn', 'desc'))
        if (indexName === SORT_POSTED_OLDEST_INDEX) orders.push(this._formatOrderES('approvedOn', 'asc'))
        if (indexName === SORT_RELEVANCE_INDEX) orders.push(this._formatOrderES('relevant', 'asc'))
        /**
         * VNW-15162 [JobSearch] Management Sort Invalid
         *
         * @author ThaiTQ
         * @since Dec 12, 2021
         */
        if (indexName === MANAGEMENT_JOB_BY_DATE) orders.push(this._formatOrderES('approvedOn', 'desc'))
        if (indexName === MANAGEMENT_JOB_BY_SALARY) orders.push(this._formatOrderES('salary', 'desc'))
        if (isManagementJob) orders.push(this._formatOrderES('topManagement', 'desc'))

        const retrieveFields = [
            'address',
            'benefits',
            'jobTitle',
            'salaryMax',
            'isSalaryVisible',
            'jobLevelVI',
            'isShowLogo',
            'salaryMin',
            'companyLogo',
            'userId',
            'jobLevel',
            'jobLevelId',
            'jobId',
            'jobUrl',
            'companyId',
            'approvedOn',
            'isAnonymous',
            'alias',
            'expiredOn',
            'industries',
            'industriesV3',
            'workingLocations',
            'services',
            'companyName',
            'salary',
            'onlineOn',
            'simpleServices',
            'visibilityDisplay',
            'isShowLogoInSearch',
            'priorityOrder',
            'skills',
            'profilePublishedSiteMask',
            'jobDescription',
            'jobRequirement',
            'prettySalary',
            'requiredCoverLetter',
            'languageSelectedVI',
            'languageSelected',
            'languageSelectedId',
            'typeWorkingId',
            'createdOn',
            'isAdrLiteJob'
        ]

        const queryParams: QueryParameters = {
            userId: userId,
            query: params.query || '',
            filter: filters,
            ranges: ranges,
            order: orders,
            hitsPerPage: hitsPerPage,
            page: page,
            /**
             * VNW-15616: [Web] Improve query jobsearch
             *
             * @author ThaiTQ
             * @since Mar 4, 2022
             */
            retrieveFields,
            summaryVersion,
            isTopPriority
        }

        return {
            query: params.query,
            params: queryParams,
            user: params.user
        }
    }

    searchRelatedJob(keyword, categoryId: number[] = [], jobLevelId = -1, perPage: number = 20) {
        const filterParams: ISearchFilterParams = {
            indexName: '',
            benefitIds: [],
            categoryId: categoryId,
            isUrgentJob: false,
            jobLevelId: jobLevelId,
            companyIndustries: [],
            locationId: [],
            districtId: [],
            jobFunctionId: [],
            locations: [],
            typeWorkingId: -1,
            userId: 0,
            query: keyword,
            hitsPerPage: perPage,
            salaryId: -1
        }
        return this.search(filterParams)
    }

    updateSearchSetting = async (language: ELanguage, accessToken: string, sortingId: ESearchSetting) => {
        const res: any = await fetch(API_UPDATE_SEACH_SETTING, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
                'Accept-Language': language
            },
            body: JSON.stringify({
                sorting_by_relevance: sortingId
            })
        })
            .then((res) => res.json())
            .catch((e) => console.log(e))

        if (res?.status?.code === 200) {
            return {
                data: true,
                error: ''
            }
        } else {
            return {
                data: false,
                error: res?.message
            }
        }
    }

    async getPriorityJob(filterParams: ISearchFilterParams): Promise<ResponseJobSearch> {
        filterParams.indexName = SORT_DEFAULT_INDEX
        const jobs = await this.search(filterParams)
        return jobs
    }

    async getRecommendJob(params: {
        user?: string
        jobId?: string
        version: 'v6' | 'v2'
        limit: string
        appId: string
        source: string
    }) {
        let query = ''
        Object.keys(params).forEach((key) => {
            let prefix = '&'
            if (query === '') {
                prefix = '?'
            }
            if (params[key]) {
                query += `${prefix}${key}=${params[key]}`
            }
        })
        try {
            const response = await fetch(BASE_MS + '/api-gateway/v1.0/job/recommend-jobs' + query)
            if (response.ok) {
                const data = await response.json()
                return data?.data ?? []
            }
            throw new Error('Get recommend job failed' + response.statusText)
        } catch (error) {
            console.error('error: ', error)
        }
    }
}

export default new JobSearchService()
