// eslint-disable-next-line no-unused-vars
import React, { RefObject, CSSProperties } from 'react'
import { connect } from 'react-redux'
import { JobAlert } from '@microfrontends/react-components'
// eslint-disable-next-line no-unused-vars
import { TAuthUser } from '../../../../handlers/auth/interface'
import withComponentTranslation from '../../utils/translatorHOC'
import { requestGetJobAlertList } from './jobAlertActions'
import FloatingIcon from './JobAlertFloatingIcon'
import jobAlertApi from './services/jobAlertApi'
import { EJobAlertActionName } from './types'
import vi from './vi.json'
import { isDesktop } from '../../utils/utilities'
import isEmpty from 'lodash/isEmpty'
import SaveLoadingIcon from '../JobList/JobItem/SaveLoadingIcon'
import JobAlertsStyles from './JobAlerts.css'
import { initialDataProvider } from '@microfrontends/app-shell-v2'
import NextPageJobAlert from '../NextPageJobAlert'
import { removeExpireKeyword, saveLocalStorage } from '../../helpers/jobAlertKeyword'

export const DEFAULT_MAX_JOB_ALERT = 10
export const ON_CREATED_JOB_ALERT = 'ON_CREATED_JOB_ALERT'

type TSelectItem = {
    id: number
    text: string
}

type JobAlertsCompProps = {
    user: TAuthUser | null
    getListSuccess: boolean
    listJobAlert: Array<any>
    getJobAlertList: () => void
    language: string
    translate: (transTerm: string) => string
    showModal: boolean
    onCloseModal: () => void
    stopShowFloatingRef: RefObject<HTMLDivElement> | null
    level: Array<any>
    category: Array<any>
    location: Array<any>
    industries: Array<any>
    jobFunctionData: any
    lastParams: any
}

type JobAlertsCompState = {
    isModalShow: boolean
    destroyModalBody: boolean
    userEmail: string
    isMaxList: boolean
}

/**
 * This is the element put inside the jobList header.
 * Clicking on the primary button should show the modal to create job alert
 */

class JobAlerts extends React.PureComponent<JobAlertsCompProps, JobAlertsCompState> {
    getListApiCalled: boolean
    destroyBodyTimeout: any // by destroying the body, we could reset the states of all components inside it.

    constructor(props) {
        super(props)

        this.state = {
            isModalShow: false,
            destroyModalBody: true,
            userEmail: '',
            isMaxList: false
        }

        this.showModal = this.showModal.bind(this)
        this.hideModal = this.hideModal.bind(this)
        this.handleClickFloatingIcon = this.handleClickFloatingIcon.bind(this)

        this.getListApiCalled = false
    }

    static defaultProps: JobAlertsCompProps = {
        user: null,
        getListSuccess: false,
        listJobAlert: [],
        getJobAlertList: () => {},
        language: 'vi',
        translate: () => {
            return ''
        },
        showModal: false,
        onCloseModal: () => {},
        stopShowFloatingRef: null,
        level: [],
        location: [],
        category: [],
        lastParams: {},
        industries: [],
        jobFunctionData: {
            parent: [],
            child: {}
        }
    }

    componentDidUpdate(prevProps: Readonly<JobAlertsCompProps>, prevState: Readonly<JobAlertsCompState>) {
        const { listJobAlert, user } = this.props

        if (listJobAlert.length >= DEFAULT_MAX_JOB_ALERT) {
            this.setState({
                isMaxList: true
            })
        }
        if (!prevState.isModalShow && this.state.isModalShow && !this.getListApiCalled) {
            const isEmptyUser = isEmpty(user)
            if (!isEmptyUser) {
                this.props.getJobAlertList()
            }
        }

        if (!prevProps.getListSuccess && this.props.getListSuccess) {
            this.getListApiCalled = true
        }

        if (prevProps.showModal !== this.props.showModal) {
            this.setState({
                isModalShow: this.props.showModal,
                destroyModalBody: !this.props.showModal
            })
        }
    }

    showModal() {
        this.setState({ isModalShow: true, destroyModalBody: false })
        clearTimeout(this.destroyBodyTimeout)
    }

    hideModal() {
        this.setState({ isModalShow: false })
        this.destroyBodyTimeout = setTimeout(() => {
            this.setState({ destroyModalBody: true })
        }, 300)
        this.props.onCloseModal()
    }

    handleClickFloatingIcon() {
        const { user } = this.props

        const isEmptyUser = isEmpty(user)
        if (!isEmptyUser) {
            this.props.getJobAlertList()
        }
        this.showModal()
    }

    onHandleAddEditJobAlert = (value: boolean, jobAlertId: number) => {
        if (!value) {
            return
        }
        const { query } = this.props.lastParams
        // if success then reload job alert list
        this.setState({ isModalShow: false })
        this.props.onCloseModal()
        const createdJobAlertEvent = new CustomEvent(ON_CREATED_JOB_ALERT, {
            detail: {
                jobAlertId
            }
        })
        window.dispatchEvent(createdJobAlertEvent)
        removeExpireKeyword(query)

        // Fetching job alert list again
        this.props.getJobAlertList()
    }

    onHandleDeleteJobAlert = (value) => {
        if (!value) {
            return
        }
        // if success then reload job alert list
        this.setState({ isModalShow: false })
        this.props.onCloseModal()
        // Fetching job alert list again
        this.props.getJobAlertList()
    }

    onCloseJobAlert = () => {
        const { query } = this.props.lastParams

        saveLocalStorage(query)
        this.props.onCloseModal()
    }

    renderJobAlertPopup = () => {
        const { isModalShow, isMaxList } = this.state
        const {
            language,
            user,
            translate,
            category,
            location,
            getListSuccess,
            lastParams,
            industries,
            jobFunctionData
        } = this.props

        const isEmptyUser = isEmpty(user)
        const { query, jobLevelId, categoryId, locationId, companyIndustries } = lastParams
        const animationModal: CSSProperties = {
            display: 'block',
            visibility: isModalShow ? 'visible' : 'hidden',
            transition: 'all .3s linear'
        }

        let categorySelectData: TSelectItem[] = []
        let locationSelectData: TSelectItem[] = []

        if (category !== undefined) {
            categorySelectData = category.map((item) => {
                return {
                    id: item.id,
                    text: language === 'en' ? item.nameEn : item.name
                }
            })
        }
        if (location !== undefined) {
            locationSelectData = location.map((item) => {
                return {
                    id: item.id,
                    text: language === 'en' ? item.nameEn : item.name
                }
            })
        }
        if (!isEmptyUser && !getListSuccess) {
            return (
                <>
                    <div
                        className={`align-items-center modal fade ${isModalShow ? 'show' : ''}`}
                        style={animationModal}
                        role='dialog'
                    >
                        <div
                            className={`modal-dialog ${isEmpty(user) ? 'modal-dialog-email' : 'modal-dialog-user'}`}
                            role='document'
                        >
                            <div className='modal-content'>
                                <div className='modal-header'>
                                    <h6 className='modal-title'>
                                        <span>{translate('Create Job Alerts')}</span>
                                    </h6>
                                    <button
                                        onClick={this.hideModal}
                                        type='button'
                                        className='close'
                                        data-dismiss='modal'
                                        aria-label='Close'
                                    >
                                        <span aria-hidden='true'>&times;</span>
                                    </button>
                                </div>
                                <div className='modal-body'>
                                    <div className='text-center mt-4 mb-5 text-muted animated fadeIn'>
                                        <div className='lead'>
                                            <SaveLoadingIcon />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isModalShow && <div className='modal-backdrop fade show' />}
                </>
            )
        }

        return (
            <>
                {isMaxList && (
                    <>
                        <div
                            className={`align-items-center modal fade ${isModalShow ? 'show' : ''}`}
                            style={animationModal}
                            role='dialog'
                        >
                            <div
                                className={`modal-dialog ${isEmpty(user) ? 'modal-dialog-email' : 'modal-dialog-user'}`}
                                role='document'
                            >
                                <div className='modal-content'>
                                    <div className='modal-header'>
                                        <h6 className='modal-title'>
                                            <span>{translate('Create Job Alerts')}</span>
                                        </h6>
                                        <button
                                            onClick={this.hideModal}
                                            type='button'
                                            className='close'
                                            data-dismiss='modal'
                                            aria-label='Close'
                                        >
                                            <span aria-hidden='true'>&times;</span>
                                        </button>
                                    </div>
                                    <div className='modal-body'>
                                        <div className='text-center mt-4 mb-5 text-muted animated fadeIn'>
                                            <div className='lead'>
                                                {translate('You have created maximum number of')}{' '}
                                                <strong>{DEFAULT_MAX_JOB_ALERT}</strong> {translate('job alerts')}.
                                            </div>
                                            <div>
                                                {translate('Please')}{' '}
                                                <a
                                                    href={translate('/my-career-center/my-job-alert')}
                                                    className='switch-to-job-alert-manager-link'
                                                >
                                                    {translate('delete at least one')}
                                                </a>{' '}
                                                {translate('to continue')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isModalShow && <div className='modal-backdrop fade show' />}
                    </>
                )}

                {!isMaxList && isModalShow && (
                    <JobAlert
                        key={`job_alert_${isModalShow ? 'open' : 'close'}`}
                        isOpen={isModalShow}
                        onToggleChange={(result) => {
                            if (!result && isModalShow) {
                                this.setState({
                                    isModalShow: result
                                })
                            }
                        }}
                        language={language}
                        isLoggedIn={!isEmptyUser}
                        isMobile={!isDesktop()}
                        jobLocationOptions={locationSelectData}
                        jobCategoryOptions={categorySelectData}
                        jobIndustryOptions={industries}
                        jobFunctionOptions={jobFunctionData}
                        title={translate('Create job alert')}
                        jobAlertInfo={{
                            userEmail: user?.userName,
                            jobTitle: query ?? '',
                            jobLocation: typeof locationId === 'object' ? locationId : [],
                            jobCategory: typeof categoryId === 'object' ? categoryId : [],
                            jobLevel: jobLevelId ? [jobLevelId] : [],
                            jobIndustry: companyIndustries || []
                        }}
                        apiJobAlert={`${jobAlertApi.apiBase}/job-alerts`}
                        apiJobAlertAnonymous={`${jobAlertApi.apiBase}/job-alerts-anonymous`}
                        apiTokenAccess={jobAlertApi.accessToken || ''}
                        onAddEditJobAlert={(value, jobAlertId) => this.onHandleAddEditJobAlert(value, jobAlertId)}
                        onDeleteJobAlert={(value) => this.onHandleDeleteJobAlert(value)}
                        onClose={this.onCloseJobAlert}
                        msBaseUrl={process.env.MICROSERVICE_DOMAIN ?? ''}
                    />
                )}
            </>
        )
    }

    render() {
        const { language } = this.props

        return (
            <JobAlertsStyles>
                <div className='job-alerts'>
                    {this.renderJobAlertPopup()}
                    {isDesktop() ? (
                        <FloatingIcon
                            handleClick={this.handleClickFloatingIcon}
                            language={language}
                            stopShowFloatingRef={this.props.stopShowFloatingRef}
                        />
                    ) : null}
                </div>
                <NextPageJobAlert
                    isModalShow={this.state.isModalShow}
                    jobAlerts={this.props.listJobAlert}
                    getListApiCalled={this.getListApiCalled}
                    getJobAlerts={this.props.getJobAlertList}
                    showJobAlert={() => this.setState({ isModalShow: true })}
                />
            </JobAlertsStyles>
        )
    }
}

const mapStateToProps = (state) => {
    const { jobAlert, globalMisc, searchFilter } = state
    const { selectData, lastParams } = searchFilter
    const { level, category, location } = selectData
    const user = globalMisc.user

    if (user) {
        jobAlertApi.setAccessToken(user.accessToken || null)
    }

    return {
        getListSuccess:
            jobAlert.lastAction.name === EJobAlertActionName.ShowList ? jobAlert.lastAction.isSuccess : false,
        listJobAlert: jobAlert.items,
        user: user,
        lastParams: lastParams,
        level: level,
        category: category,
        location: location,
        industries: initialDataProvider.getIndustries(),
        jobFunctionData: initialDataProvider.getJobFunction()
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getJobAlertList: () => dispatch(requestGetJobAlertList())
    }
}

const componentWithTranslation = withComponentTranslation(vi)(JobAlerts)
export default connect(mapStateToProps, mapDispatchToProps)(componentWithTranslation)
