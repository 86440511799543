import React, { useRef, useEffect, useState } from 'react'
import { colors, ELanguage, Images, Paragraph, TagBasic, Tooltip, useHog } from '@microfrontends/react-components'
import { MANAGEMENT_JOB_BY_DATE, MANAGEMENT_JOB_BY_SALARY, MANAGEMENT_JOB_DEFAULT } from '../../env'
import { fireGlobalSearchEvent } from '../SearchResults/helpers/filterHelper'
import { HighlightContainer, HighlightTagBasic, SortingBar, TooltipDes } from './styles'
import { isMobile } from '../SearchResults/utils/utilities'
import translations from './translations'
import { setCookie } from '../../helpers/common'
import { useDispatch, useSelector } from 'react-redux'
import { TRootState } from '../SearchResults/searchResultsRootReducers'
import { ESearchSetting, TAuthUser } from '../../handlers/auth/interface'
import jobSearchService from '../SearchResults/services/jobSearchService'
import { decrypt } from '../../handlers/auth/cryptor'
import { SET_AUTH_USER } from '../SearchResults/global/searchResultGlobalMiscActions'

export type TSortingProps = {
    sortId: string | number
    isManagementJob: boolean
    language: ELanguage
    isHighlightTooltip?: boolean
    setHighlightTooltip?: () => void
    positionRender: EMostRelevantPositionType
    tooltipMobileRef?: React.MutableRefObject<any>
    userId?: number
    user: TAuthUser
}

export type TSortOptions = {
    id: string
    name: string
    isHighlight?: boolean
}

export const HIGHTLIGHT_SORTING_TOOLTIP_NAME = 'highlightSortingTooltip'
export enum ESortingHighlightTooltip {
    SHOW = 'show',
    HIDDEN = 'hidden'
}

export enum EMostRelevantPositionType {
    FILTER_BAR = 1,
    OTHER = 2
}

export enum ETooltipType {
    IN_DAY = 1,
    ALL = 45
}

export const SORT_DEFAULT_INDEX = process.env.SORT_DEFAULT_INDEX || process.env.ALGOLIA_DEFAULT_INDEX
const SORT_RELEVANCE_INDEX = process.env.SORT_RELEVANCE_INDEX || process.env.ALGOLIA_RELEVANCE_INDEX
const SORT_SALARY_INDEX = process.env.SORT_SALARY_INDEX || process.env.ALGOLIA_SALARY_INDEX
const SORT_POSTED_LATEST_INDEX = process.env.SORT_POSTED_LATEST_INDEX || process.env.ALGOLIA_POSTED_LATEST_INDEX
const SORT_POSTED_OLDEST_INDEX = process.env.SORT_POSTED_OLDEST_INDEX || process.env.ALGOLIA_POSTED_OLDEST_INDEX

const SearchSorting = (props: TSortingProps) => {
    const {
        sortId,
        isManagementJob,
        language,
        isHighlightTooltip = false,
        setHighlightTooltip,
        positionRender,
        userId = 0
    } = props
    const dispatch = useDispatch()
    const { lastParams } = useSelector((state: TRootState) => state.searchFilter)
    const { user } = useSelector((state: TRootState) => state.globalMisc)
    const { tracking } = useHog()

    const [searchSetting, setSearchSetting] = useState(user?.searchSettingSortType)

    const tooltipRef = useRef(null)

    const translate = (text: string): string => {
        if (language === ELanguage.EN) {
            return text
        } else {
            return translations[text]
        }
    }

    const sortOptions = () => {
        const normalJobsSortOptions: TSortOptions[] = [
            {
                id: SORT_DEFAULT_INDEX,
                name: translate('Last update')
            },
            {
                id: SORT_RELEVANCE_INDEX,
                name: translate('Most relevant'),
                isHighlight: true
            },
            {
                id: SORT_SALARY_INDEX,
                name: translate('Salary (high - low)')
            },
            {
                id: SORT_POSTED_LATEST_INDEX,
                name: translate('Posted date (latest)')
            },
            {
                id: SORT_POSTED_OLDEST_INDEX,
                name: translate('Posted date (oldest)')
            }
        ]

        const managementJobsSortOptions: TSortOptions[] = [
            {
                id: MANAGEMENT_JOB_DEFAULT,
                name: translate('All')
            },
            {
                id: MANAGEMENT_JOB_BY_SALARY,
                name: translate('Salary (high - low)')
            },
            {
                id: MANAGEMENT_JOB_BY_DATE,
                name: translate('Posted date (latest)')
            }
        ]
        return isManagementJob ? managementJobsSortOptions : normalJobsSortOptions
    }

    const handleSort = (sortId: string | number) => {
        if (user && sortId === SORT_RELEVANCE_INDEX && searchSetting !== ESearchSetting.RELEVANT) {
            handleTurnOnSearchSetting()
        }
        handleSortSearchJob(sortId)
    }

    const handleSortSearchJob = (sortId: string | number) => {
        const params = {
            userId: userId,
            indexName: (sortId as string) || SORT_DEFAULT_INDEX,
            page: 0
        }

        switch (positionRender) {
            case EMostRelevantPositionType.FILTER_BAR:
                if (sortId === SORT_RELEVANCE_INDEX) {
                    setCookie(HIGHTLIGHT_SORTING_TOOLTIP_NAME, ESortingHighlightTooltip.HIDDEN, ETooltipType.ALL)
                    if (sortId !== lastParams.indexName && isHighlightTooltip) {
                        tracking({
                            eventName: 'js_search_result_page:click_sort_relevant_tooltip'
                        })
                    }
                }
                break
            case EMostRelevantPositionType.OTHER:
                setHighlightTooltip()
                break
        }
        fireGlobalSearchEvent(params, 'JobHead > sortBy')
    }

    const handleTurnOnSearchSetting = async () => {
        const { data: success } = await jobSearchService.updateSearchSetting(
            language,
            decrypt(user.accessToken),
            ESearchSetting.RELEVANT
        )
        if (success) {
            tracking({
                eventName: 'js_search_result_page:success_sort_relevant'
            })
            dispatch({
                type: SET_AUTH_USER,
                payload: {
                    ...user,
                    searchSettingSortType: ESearchSetting.RELEVANT
                }
            })
        }
        setSearchSetting(ESearchSetting.RELEVANT)
    }

    const handleHighlightTooltip = (type: ETooltipType) => {
        setCookie(HIGHTLIGHT_SORTING_TOOLTIP_NAME, ESortingHighlightTooltip.HIDDEN, type)
        setHighlightTooltip()
    }

    useEffect(() => {
        if (!isHighlightTooltip) {
            return
        }

        const isDisplayHighlight: boolean = isHighlightTooltip && sortId != SORT_RELEVANCE_INDEX
        if (!isDisplayHighlight) {
            return
        }
        tracking({
            eventName: 'js_search_result_page:appear_sort_relevant_tooltip'
        })
    }, [isHighlightTooltip, sortId])

    const renderTooltipDes = () => {
        return (
            <TooltipDes>
                <Paragraph name={'body'} size={'medium'} color={colors.grey[100]}>
                    {translate('Select to prioritize viewing most relevant jobs')}
                </Paragraph>
                <div onClick={() => handleHighlightTooltip(ETooltipType.IN_DAY)}>
                    <Images.CloseIcon width={18} height={18} fill={colors.grey['100']} />
                </div>
            </TooltipDes>
        )
    }

    return (
        <SortingBar isMobile={isMobile()} sortingPosition={positionRender}>
            <div className='title'>{translate('Job By')}</div>
            <div className='sort-item-wrapped'>
                {sortOptions().map((option: TSortOptions) => {
                    // only click on position EMostRelevantClickType.FILTER_BAR, disabled highlight ETooltipType.ALL
                    // else sortId is most relevant not show tooltip, highlight.
                    const isDisplayHighlight: boolean =
                        isHighlightTooltip && option?.isHighlight && sortId != SORT_RELEVANCE_INDEX
                    return isDisplayHighlight ? (
                        <HighlightContainer>
                            <HighlightTagBasic onClick={() => handleSort(option.id)}>
                                <svg height='100%' width='100%'>
                                    <rect
                                        rx='4'
                                        ry='4'
                                        className={'line'}
                                        height='100%'
                                        width='100%'
                                        strokeLinejoin={'round'}
                                    />
                                </svg>
                                <TagBasic
                                    key={option.id}
                                    isActive={sortId === option.id}
                                    value={option.id}
                                    onClick={() => handleSort(option.id)}
                                >
                                    {option.name}
                                </TagBasic>
                            </HighlightTagBasic>
                            <div ref={tooltipRef} className={'tooltipContains'}>
                                <Tooltip
                                    isShow={isHighlightTooltip}
                                    description={renderTooltipDes()}
                                    customStyle={{
                                        marginTop: '20px',
                                        padding: '15px',
                                        borderRadius: '12px',
                                        boxShadow: '0px 4px 40px 0px rgba(0, 0, 0, 0.16);',
                                        bgColor: colors.primary[40]
                                    }}
                                />
                            </div>
                        </HighlightContainer>
                    ) : (
                        <TagBasic
                            className={`${positionRender === EMostRelevantPositionType.FILTER_BAR ? 'customBg' : ''}`}
                            key={option.id}
                            isActive={sortId === option.id}
                            value={option.id}
                            onClick={() => handleSort(option.id)}
                        >
                            {option.name}
                        </TagBasic>
                    )
                })}
            </div>
        </SortingBar>
    )
}

export default SearchSorting
