export const jobKeywords = [
    '2d design',
    '3d artist',
    '3d designer',
    'admin',
    'affiliate marketing',
    'an ninh mạng',
    'autocad 2d',
    'b2b',
    'b2b sales',
    'bác sĩ siêu âm',
    'báo cáo sự cố',
    'bếp trưởng',
    'biên dịch tiếng anh',
    'biên phiên dịch tiếng anh',
    'biên phiên dịch tiếng hàn',
    'biên tập viên',
    'biên tập viên sách',
    'bim modeler',
    'blockchain',
    'brand marketing',
    'business advisor',
    'business analysis',
    'business assistant',
    'business consultant',
    'business intelligence',
    'business operation',
    'cad operator',
    'cán bộ dự án',
    'channel sales',
    'chỉ huy trưởng công trình',
    'chứng từ xuất nhập khẩu',
    'content manager',
    'content marketing',
    'content media',
    'content writer',
    'công nhân hóa chất',
    'công nhân kỹ thuật',
    'công nhân sản xuất',
    'creative marketing',
    'data analyst',
    'dịch thuật',
    'digital marketing',
    'dinh dưỡng viên',
    'dược sĩ đại học',
    'đăng ký thuốc',
    'đầu bếp',
    'điện tử viễn thông',
    'điều dưỡng viên',
    'điều hành tour',
    'điều phối viên dự án',
    'điều tra viên',
    'e-commerce',
    'event marketing',
    'facebook marketing',
    'gia công cơ khí',
    'giám sát an toàn lao động',
    'giám sát bán hàng',
    'giám sát bán hàng siêu thị',
    'giám sát kho',
    'giám sát kinh doanh',
    'giám sát sản xuất',
    'giám sát thi công',
    'giám sát thi công nội thất',
    'giám sát tín dụng',
    'giảng viên',
    'giảng viên CNTT',
    'giảng viên dạy ielts',
    'giảng viên tiếng nhật',
    'giáo viên',
    'giáo viên dạy toán',
    'giáo viên mầm non',
    'giáo viên tiếng anh mầm non',
    'giáo viên tiếng nhật',
    'giáo viên tin học',
    'graphic designer',
    'graphic designer 2d',
    'hóa dầu',
    'họa sĩ',
    'họa viên kết cấu',
    'họa viên kiến trúc',
    'hse',
    'hsse',
    'ielts teacher',
    'iot',
    'iso',
    'it helpdesk',
    'java',
    'java backend developer',
    'java spring',
    'javascript',
    'kế toán',
    'kế toán bán hàng',
    'kế toán công nợ',
    'kế toán công trình',
    'kế toán doanh thu',
    'kế toán giá thành',
    'kế toán kho',
    'kế toán nội bộ',
    'kế toán quản trị',
    'kế toán thanh toán',
    'kế toán thuế',
    'kế toán tổng hợp',
    'kế toán xây dựng',
    'khảo sát thị trường',
    'khuôn mẫu',
    'kiểm nghiệm viên vi sinh',
    'kiểm soát nội bộ',
    'kiểm toán viên',
    'kiến trúc sư',
    'kiến trúc sư cảnh quan',
    'kiến trúc sư quy hoạch',
    'kỹ sư',
    'kỹ sư an toàn lao động',
    'kỹ sư an toàn thông tin',
    'kỹ sư bảo trì',
    'kỹ sư cấp thoát nước',
    'kỹ sư cầu đường',
    'kỹ sư công nghệ sinh học',
    'kỹ sư cơ điện',
    'kỹ sư cơ điện tử',
    'kỹ sư cơ khí',
    'kỹ sư cơ khí chế tạo máy',
    'kỹ sư dự án',
    'kỹ sư dự toán xây dựng',
    'kỹ sư địa chất',
    'kỹ sư điện',
    'kỹ sư điện lạnh',
    'kỹ sư điện nước',
    'kỹ sư điện tử',
    'kỹ sư điện tử viễn thông',
    'kỹ sư điều khiển tự động hóa',
    'kỹ sư đường ống',
    'kỹ sư giao thông',
    'kỹ sư hạ tầng',
    'kỹ sư hệ thống điện',
    'kỹ sư hóa',
    'kỹ sư kết cấu',
    'kỹ sư kết cấu thép',
    'kỹ sư kết cấu xây dựng',
    'kỹ sư lâm nghiệp',
    'kỹ sư lập trình nhúng',
    'kỹ sư lập trình plc',
    'kỹ sư M&E',
    'kỹ sư máy xây dựng',
    'kỹ sư mep',
    'kỹ sư môi trường',
    'kỹ sư nhiệt lạnh',
    'kỹ sư nông nghiệp',
    'kỹ sư phòng cháy chữa cháy',
    'kỹ sư qs',
    'kỹ sư quản lý dự án',
    'kỹ sư sản xuất',
    'kỹ sự thiết kế',
    'kỹ sư thiết kế cấp thoát nước',
    'kỹ sư thiết kế cầu đường',
    'kỹ sư thiết kế cơ khí',
    'kỹ sư thiết kế điện',
    'kỹ sư thực phẩm',
    'kỹ sư trắc địa',
    'kỹ sư trưởng tòa nhà',
    'kỹ thuật ép nhựa',
    'kỹ thuật viên',
    'kỹ thuật y sinh',
    'kỹ thuật phòng thí nghiệm',
    'kỹ thuật viên xét nghiệm',
    'lab technician',
    'lái xe',
    'lái xe b2',
    'lập trình cnc',
    'lập trình game',
    'lập trình viên',
    'lập trình viên android',
    'lập trình viên front end',
    'lập trình viên java',
    'lập trình viên php',
    'lập trình viên python',
    'lập trình web',
    'lễ tân',
    'lễ tân khách sạn',
    'lễ tân tòa nhà',
    'logistics',
    'luật sư',
    'machine learning',
    'marketing online',
    'model',
    'nha khoa',
    'objective C',
    'phiên dịch tiếng hàn',
    'phiên dịch tiếng nhật',
    'phiên dịch tiếng thái lan',
    'phiên dịch tiếng trung',
    'phiên dịch viên tiếng nhật',
    'photographer',
    'photoshop',
    'physical design',
    'plc',
    'pr marketing',
    'presales',
    'product marketing',
    'python',
    'qa tester',
    'quản lý an ninh',
    'quản lý bán hàng',
    'quản lý chi nhánh',
    'quản lý cửa hàng',
    'quản lý đất đai',
    'quản lý đơn hành',
    'quản lý giáo dục',
    'quản lý kho',
    'quản lý kinh doanh',
    'quản lý nhà hàng',
    'quản lý nhân sự',
    'quản lý phòng thí nghiệm',
    'quản lý siêu thị',
    'quản ly spa',
    'quản lý tòa nhà',
    'quản lý trình dược viên',
    'quản lý trường mầm non',
    'quản lý vận hành',
    'quản lý vùng',
    'quản trị cơ sở dữ liệu',
    'quản trị fanpage',
    'quản trị kinh doanh',
    'quản trị mạng',
    'quỹ đầu tư',
    'social media',
    'software developer',
    'sourcing',
    'tài trợ thương mại',
    'talent sourcing',
    'thanh toán quốc tế',
    'thiết bị y tế',
    'thiết kế cảnh quan',
    'thiết kế khuôn',
    'thiết kế thời trang',
    'thiết kế web',
    'thông dịch viên tiếng hàn',
    'thủ kho',
    'thủy sản',
    'thư ký chăn nuôi',
    'thư ký hội đồng quản trị',
    'thư ký kinh doanh',
    'thư ký tiếng trung',
    'thư ký văn phòng',
    'thư ký y khoa',
    'thức ăn chăn nuôi',
    'tiếng hàn',
    'tiếng nga',
    'tiếng pháp',
    'tiếng thái',
    'trade marketing',
    'trắc đạc',
    'trình dược viên etc',
    'trợ lý marketing',
    'trợ lý nhân sự',
    'truyền thông nội bộ',
    'video editor',
    'web developer',
    'y tá',
    'y tá điều dưỡng'
]

/**
 * Exclued Spamming Search Keywords (Google)
 */
export const excludeKeywords = ['Z400.NET', 'qc90.com', 'k8io.vip', 'qh015']

export const listJobFunctionIdFromCategoryId = {
    1: 2,
    2: 20,
    3: 17,
    4: 3,
    5: 4,
    6: 9,
    7: 4,
    8: 6,
    10: 7,
    11: 6,
    12: 1,
    16: 9,
    17: 29,
    19: 13,
    21: 25,
    22: 19,
    23: 12,
    24: 14,
    25: 16,
    26: 27,
    27: 17,
    28: 9,
    30: 23,
    32: 24,
    33: 21,
    34: 21,
    35: 5,
    36: 13,
    37: 15,
    39: 30,
    41: 5,
    42: 10,
    43: 9,
    47: 20,
    48: 18,
    49: 13,
    52: 26,
    53: 13,
    54: 11,
    55: 5,
    56: 10,
    57: 5,
    58: 2,
    59: 10,
    62: 24,
    63: 7,
    64: 9,
    65: 9,
    66: 9,
    67: 9,
    68: 9,
    69: 4,
    70: 27,
    72: 18,
    73: 15,
    74: 24,
    75: 24,
    76: 9,
    77: 9,
    78: 19,
    79: 19,
    80: 28,
    81: 28,
    82: 8,
    83: 4,
    84: 22,
    156: 24,
    157: 10
}

export const shuffleArray = (array: any[]) => {
    if (!Array.isArray(array)) return []

    let currentIndex = array.length,
        randomIndex

    // While there remain elements to shuffle.
    while (currentIndex > 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex--

        // And swap it with the current element.
        ;[array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
    }

    return array
}

export const ADR1_SETTING = 'ADR1_SETTING'
export const ADR2_SETTING = 'ADR2_SETTING'

export const RB_SETTING = 'RB_SETTING'
export const RB1_SETTING = 'RB1_SETTING'
export const RB2_SETTING = 'RB2_SETTING'

export const XALO_LEAD_SETTING = 'XALO_LEAD_SETTING'

export const AMOUNT_SEEN_JOB_TRACKING = 15
export const MS_BASE_URL = process.env.MICROSERVICE_DOMAIN || ''
export const APP_ID = process.env.VNW_APP_ID || ''
