import { ELanguage } from '@microfrontends/react-components'
import dateFormat from 'dateformat'

/**
 * Transform our params to params that are readable by Algolia
 * TODO: [Refactor] Move this method somewhere close to the algolia service, as this module contains general utilities
 * @param params
 */

export function getCookie(name) {
    const nameEQ = name + '='
    if (typeof document !== 'undefined') {
        const ca = document.cookie.split(';')
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i]
            while (c.charAt(0) === ' ') c = c.substring(1, c.length)
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
        }
    }
    return null
}

export function getUrlParameter(name) {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
    const results = typeof location !== 'undefined' ? regex.exec(location.search) : null
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export function encodeKeyword(rawKeyword: string) {
    var blackList = [
        { regex: /C#/gi, replace: 'C_sharp' },
        {
            regex: /C\+\+/gi,
            replace: 'C_plus_plus'
        },
        { regex: /[[\]\\/:~^?!()><"'@%|{}`*#]/gi, replace: ' ' },
        { regex: /&/gi, replace: encodeURIComponent('&') }
    ]
    var tempkeyword

    for (var i in blackList) {
        rawKeyword = rawKeyword.replace(blackList[i].regex, blackList[i].replace)
    }
    do {
        tempkeyword = rawKeyword
        rawKeyword = tempkeyword.replace('  ', ' ')
    } while (tempkeyword !== rawKeyword)

    rawKeyword = rawKeyword.replace(/[-]{1,}/g, '--')

    rawKeyword = rawKeyword.trim()
    return rawKeyword.replace(/ /g, '-')
}

export function decodeKeyword(encodedKeyword: string) {
    return encodedKeyword
        .split(/[-]{2,}/g)
        .join('[TEMP DOUBLE HYPHEN]')
        .split('-')
        .join(' ')
        .split('[TEMP DOUBLE HYPHEN]')
        .join('-')
        .replace(/\+\+/g, '_plus_plus')
        .replace(/C_plus_plus|c_plus_plus/, 'C++')
        .replace(/C_sharp|c_sharp/, 'C#')
}

export function preprocessKeyword(rawKeyword) {
    var blackList = [
        { regex: /C#/gi, replace: 'C_sharp' },
        {
            regex: /C\+\+/gi,
            replace: 'C_plus_plus'
        },
        { regex: /[[\]\\/:~^?!()&><"'@%|{}`*#]/gi, replace: ' ' }
    ]
    var tempkeyword

    for (var i in blackList) {
        rawKeyword = rawKeyword.replace(blackList[i].regex, blackList[i].replace)
    }
    do {
        tempkeyword = rawKeyword
        rawKeyword = tempkeyword.replace('  ', ' ')
    } while (tempkeyword !== rawKeyword)
    rawKeyword = rawKeyword.trim()
    return rawKeyword
}

/**
 * Clean slugs by removing spaces, invalid characters and dashes
 * @param slug - the slug you wanna clean
 */
export function slugify(slug: string = '') {
    slug = slug.replace(/^\s+|\s+$/g, '') // trim
    slug = slug.toLowerCase()

    // remove accents
    slug = transformToLatin(slug)

    slug = slug
        .replace(/[^a-z0-9 -]/g, '-') // invalid chars to -
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-') // collapse dashes

    return slug
}

/**
 * Transform all characters with accent into latin, e.g. `Ô kìa Con vịt` to `o kia con vit`
 * @param text - the text you want to transform
 */
export function transformToLatin(text: string) {
    text = text.toString().toLowerCase().trim()

    const sets = [
        { to: 'a', from: '[ÀÁÂÃÄÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶἀ]' },
        { to: 'c', from: '[ÇĆĈČ]' },
        { to: 'd', from: '[ÐĎĐÞ]' },
        { to: 'e', from: '[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]' },
        { to: 'g', from: '[ĜĞĢǴ]' },
        { to: 'h', from: '[ĤḦ]' },
        { to: 'i', from: '[ÌÍÎÏĨĪĮİỈỊ]' },
        { to: 'j', from: '[Ĵ]' },
        { to: 'ij', from: '[Ĳ]' },
        { to: 'k', from: '[Ķ]' },
        { to: 'l', from: '[ĹĻĽŁ]' },
        { to: 'm', from: '[Ḿ]' },
        { to: 'n', from: '[ÑŃŅŇ]' },
        { to: 'o', from: '[ÒÓÔÕÖØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]' },
        { to: 'oe', from: '[Œ]' },
        { to: 'p', from: '[ṕ]' },
        { to: 'r', from: '[ŔŖŘ]' },
        { to: 's', from: '[ßŚŜŞŠȘ]' },
        { to: 't', from: '[ŢŤ]' },
        { to: 'u', from: '[ÙÚÛÜŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]' },
        { to: 'w', from: '[ẂŴẀẄ]' },
        { to: 'x', from: '[ẍ]' },
        { to: 'y', from: '[ÝŶŸỲỴỶỸ]' },
        { to: 'z', from: '[ŹŻŽ]' },
        { to: '-', from: "[·/_,:;']" }
    ]

    sets.forEach((set) => {
        text = text.replace(new RegExp(set.from, 'gi'), set.to)
    })

    return text
}

/**
 * Just a helper function to help create an html object quickly whenever you need to use `dangerouslySetInnerHTML`
 * @param HTMLMarkup
 */
export function createMarkup(HTMLMarkup: string) {
    return { __html: HTMLMarkup }
}

/**
 * Return `true` if the screen is desktop: screen size >= 992
 */
export function isDesktop(): boolean {
    if (typeof window !== 'undefined') {
        // Note that using window.innerWidth will return double the value of the width on some iOS versions
        return document.documentElement.clientWidth >= 992
    }
    return false
}

/**
 * Return `true` if the screen is either phone or tablet: screen size < 992px
 */
export function isMobile(): boolean {
    if (typeof window !== 'undefined') {
        // Note that using window.innerWidth will return double the value of the width on some iOS versions
        return document.documentElement.clientWidth < 992
    }
    return false
}

/**
 * Return `true` if the screen is phone: screen size < 768px
 */
export function isPhone(): boolean {
    if (typeof window !== 'undefined') {
        // Note that using window.innerWidth will return double the value of the width on some iOS versions
        return document.documentElement.clientWidth < 768
    }
    return false
}

export function isSmallPhone(): boolean {
    if (typeof window !== 'undefined') {
        // Note that using window.innerWidth will return double the value of the width on some iOS versions
        return document.documentElement.clientWidth <= 375
    }
    return false
}

/**
 * Return `true` if the screen is tablet: 768px <= screen size < 992px
 */
export function isTablet(): boolean {
    if (typeof window !== 'undefined') {
        // Note that using window.innerWidth will return double the value of the width on some iOS versions
        return document.documentElement.clientWidth >= 768 && document.documentElement.clientWidth < 992
    }
    return false
}
// For IPv4
const addrToNumber = (addr) => {
    return addr.split('.').reduce((acc, cur, i) => (acc += Number(cur) << ((3 - i) * 8)), 0)
}

const subnetMaskToNumber = (mask) => {
    return (0xffffffff << (32 - Number(mask))) & 0xffffffff
}

const isIPv4FromGoogle = (userIP: string): boolean => {
    const googleBotIPRange = [
        '66.249.64.0/27',
        '66.249.64.128/27',
        '66.249.64.160/27',
        '66.249.64.192/27',
        '66.249.64.224/27',
        '66.249.64.32/27',
        '66.249.64.64/27',
        '66.249.64.96/27',
        '66.249.65.0/27',
        '66.249.65.128/27',
        '66.249.65.160/27',
        '66.249.65.192/27',
        '66.249.65.224/27',
        '66.249.65.32/27',
        '66.249.65.64/27',
        '66.249.65.96/27',
        '66.249.66.0/27',
        '66.249.66.128/27',
        '66.249.66.192/27',
        '66.249.66.32/27',
        '66.249.66.64/27',
        '66.249.68.0/27',
        '66.249.68.32/27',
        '66.249.68.64/27',
        '66.249.69.0/27',
        '66.249.69.128/27',
        '66.249.69.160/27',
        '66.249.69.192/27',
        '66.249.69.224/27',
        '66.249.69.32/27',
        '66.249.69.64/27',
        '66.249.69.96/27',
        '66.249.70.0/27',
        '66.249.70.128/27',
        '66.249.70.160/27',
        '66.249.70.192/27',
        '66.249.70.224/27',
        '66.249.70.32/27',
        '66.249.70.64/27',
        '66.249.70.96/27',
        '66.249.71.0/27',
        '66.249.71.128/27',
        '66.249.71.160/27',
        '66.249.71.192/27',
        '66.249.71.32/27',
        '66.249.71.64/27',
        '66.249.71.96/27',
        '66.249.72.0/27',
        '66.249.72.128/27',
        '66.249.72.160/27',
        '66.249.72.192/27',
        '66.249.72.224/27',
        '66.249.72.32/27',
        '66.249.72.64/27',
        '66.249.72.96/27',
        '66.249.73.0/27',
        '66.249.73.128/27',
        '66.249.73.160/27',
        '66.249.73.192/27',
        '66.249.73.224/27',
        '66.249.73.32/27',
        '66.249.73.64/27',
        '66.249.73.96/27',
        '66.249.74.0/27',
        '66.249.74.32/27',
        '66.249.74.64/27',
        '66.249.74.96/27',
        '66.249.75.0/27',
        '66.249.75.128/27',
        '66.249.75.160/27',
        '66.249.75.192/27',
        '66.249.75.224/27',
        '66.249.75.32/27',
        '66.249.75.64/27',
        '66.249.75.96/27',
        '66.249.76.0/27',
        '66.249.76.128/27',
        '66.249.76.160/27',
        '66.249.76.192/27',
        '66.249.76.224/27',
        '66.249.76.32/27',
        '66.249.76.64/27',
        '66.249.76.96/27',
        '66.249.77.0/27',
        '66.249.77.128/27',
        '66.249.77.32/27',
        '66.249.77.64/27',
        '66.249.77.96/27',
        '66.249.79.0/27',
        '66.249.79.128/27',
        '66.249.79.160/27',
        '66.249.79.192/27',
        '66.249.79.224/27',
        '66.249.79.32/27',
        '66.249.79.64/27',
        '66.249.79.96/27'
    ]
    let isFromGoogle = false
    // Loop through all CIDR from google IPv4 to find match IP
    googleBotIPRange.map((cidr: string) => {
        const [networkAddr, subnetMask] = cidr.split('/')
        const match = (addrToNumber(userIP) & subnetMaskToNumber(subnetMask)) == addrToNumber(networkAddr)
        if (match) {
            isFromGoogle = true
        }
    })

    return isFromGoogle
}
// For IPv6
const isIPv6FromGoogle = (userIP: string): boolean => {
    const googleBotIPv6CIDR = [
        '2001:4860:4801:10::/64',
        '2001:4860:4801:11::/64',
        '2001:4860:4801:12::/64',
        '2001:4860:4801:13::/64',
        '2001:4860:4801:14::/64',
        '2001:4860:4801:15::/64',
        '2001:4860:4801:16::/64',
        '2001:4860:4801:17::/64',
        '2001:4860:4801:18::/64',
        '2001:4860:4801:19::/64',
        '2001:4860:4801:1a::/64',
        '2001:4860:4801:1b::/64',
        '2001:4860:4801:20::/64',
        '2001:4860:4801:21::/64',
        '2001:4860:4801:22::/64',
        '2001:4860:4801:23::/64',
        '2001:4860:4801:24::/64',
        '2001:4860:4801:25::/64',
        '2001:4860:4801:26::/64',
        '2001:4860:4801:27::/64',
        '2001:4860:4801:28::/64',
        '2001:4860:4801:29::/64',
        '2001:4860:4801:2::/64',
        '2001:4860:4801:2a::/64',
        '2001:4860:4801:2b::/64',
        '2001:4860:4801:2c::/64',
        '2001:4860:4801:2d::/64',
        '2001:4860:4801:2e::/64',
        '2001:4860:4801:2f::/64',
        '2001:4860:4801:30::/64',
        '2001:4860:4801:31::/64',
        '2001:4860:4801:32::/64',
        '2001:4860:4801:33::/64',
        '2001:4860:4801:34::/64',
        '2001:4860:4801:35::/64',
        '2001:4860:4801:36::/64',
        '2001:4860:4801:37::/64',
        '2001:4860:4801:38::/64',
        '2001:4860:4801:39::/64',
        '2001:4860:4801:3::/64',
        '2001:4860:4801:3a::/64',
        '2001:4860:4801:3b::/64',
        '2001:4860:4801:3c::/64',
        '2001:4860:4801:3d::/64',
        '2001:4860:4801:3e::/64',
        '2001:4860:4801:40::/64',
        '2001:4860:4801:41::/64',
        '2001:4860:4801:42::/64',
        '2001:4860:4801:43::/64',
        '2001:4860:4801:44::/64',
        '2001:4860:4801:45::/64',
        '2001:4860:4801:46::/64',
        '2001:4860:4801:47::/64',
        '2001:4860:4801:48::/64',
        '2001:4860:4801:49::/64',
        '2001:4860:4801:4a::/64',
        '2001:4860:4801:50::/64',
        '2001:4860:4801:51::/64',
        '2001:4860:4801:53::/64',
        '2001:4860:4801:60::/64',
        '2001:4860:4801:61::/64',
        '2001:4860:4801:62::/64',
        '2001:4860:4801:63::/64',
        '2001:4860:4801:64::/64',
        '2001:4860:4801:65::/64',
        '2001:4860:4801:66::/64',
        '2001:4860:4801:67::/64',
        '2001:4860:4801:68::/64',
        '2001:4860:4801:69::/64',
        '2001:4860:4801:6a::/64',
        '2001:4860:4801:6b::/64',
        '2001:4860:4801:6c::/64',
        '2001:4860:4801:6d::/64',
        '2001:4860:4801:6e::/64',
        '2001:4860:4801:6f::/64',
        '2001:4860:4801:70::/64',
        '2001:4860:4801:71::/64',
        '2001:4860:4801:72::/64',
        '2001:4860:4801:73::/64',
        '2001:4860:4801:74::/64',
        '2001:4860:4801:75::/64',
        '2001:4860:4801:76::/64',
        '2001:4860:4801:77::/64',
        '2001:4860:4801:80::/64',
        '2001:4860:4801:81::/64',
        '2001:4860:4801:82::/64',
        '2001:4860:4801:83::/64',
        '2001:4860:4801:84::/64',
        '2001:4860:4801:85::/64',
        '2001:4860:4801:86::/64',
        '2001:4860:4801:90::/64',
        '2001:4860:4801:91::/64',
        '2001:4860:4801:92::/64',
        '2001:4860:4801::/64',
        '2001:4860:4801:c::/64',
        '2001:4860:4801:f::/64'
    ]
    console.error(googleBotIPv6CIDR, userIP)
    let isFromGoogle = false
    // TODO: Loop through all CIDR from google IPv6 to find match IP
    // IPv6 is not support yet
    return isFromGoogle
}

/**
 * Check user IP address if it is a bot or not
 * @param userIP string
 */
export const isIPFromGoogleBot = (userIP: string[]): boolean => {
    if (userIP.length === 0) {
        return false
    }
    let isFromGoogle = false
    for (let i = 0; i < userIP.length; i++) {
        if (!userIP[i]) {
            continue
        }
        const isIPv4 = userIP[i].indexOf('.') > 0
        const userIPSplitted = isIPv4 ? userIP[i].split('.') : userIP[i].split(':')

        if (isIPv4 && userIPSplitted[0] === '66' && userIPSplitted[1] === '249') {
            isFromGoogle = isIPv4FromGoogle(userIP[i])
        } else if (
            !isIPv4 &&
            userIPSplitted.length > 1 &&
            userIPSplitted[0] === '2001' &&
            userIPSplitted[1] === '4860' &&
            userIPSplitted[2] === '4801'
        ) {
            isFromGoogle = isIPv6FromGoogle(userIP[i])
        }
    }
    return isFromGoogle
}

/**
 * Check a user-agent if it's a robot
 * @param userAgent
 */
export const isRobot = (userAgent: string, userIP: string[]): boolean => {
    const botUserAgents = [
        'googlebot',
        'google-structured-data-testing-tool',
        'bingbot',
        'linkedinbot',
        'mediapartners-google',
        'apis-google',
        'feedfetcher-google',
        'googlebot-image',
        'storebot-google'
    ]

    const currentUserAgent: string = userAgent.toLowerCase()

    let isBot = botUserAgents.some((item) => {
        return currentUserAgent.includes(item)
    })

    // Detect IP from Google bot
    // Only call detect IP when isBot = false (For better performance)
    if (!isBot && userIP !== undefined) {
        isBot = isIPFromGoogleBot(userIP)
    }

    return isBot
}

export function buildJobsByCompanyUrl(companyId, companyName, langCode) {
    const companyPrefix = langCode === ELanguage.VI ? 'nha-tuyen-dung' : 'companies'
    return `/${companyPrefix}/${slugify(companyName)}-c${companyId}`
}

export const buildCompanyUrl = (companyId: number, companyName: string, langCode: string) => {
    const companyNameStr = slugify(companyName)
    const prefixCompanyPath = langCode === 'vi' ? 'nha-tuyen-dung' : 'companies'
    return `/${prefixCompanyPath}/${companyNameStr}-c${companyId}`
}

export function convertISOToMillisecond(dateTime) {
    return Date.parse(dateTime) / 1000
}

export function checkIsNew(item) {
    const onlineDate = new Date(item.onlineDate ? item.onlineDate : item.onlineOn)
    const now = new Date()
    let isNew = false
    try {
        isNew = dateFormat(onlineDate, 'yyyy/mm/dd') === dateFormat(now, 'yyyy/mm/dd')
    } catch {
        isNew = false
    }
    return isNew
}

// ISO date without date/time change
export function getCurrentISODateTime() {
    const now = new Date()
    return new Date(now.getTime() - now.getTimezoneOffset() * 60000).toISOString()
}
