import * as Sentry from '@sentry/node'
import localForage from 'localforage'
import isEmpty from 'lodash/isEmpty'
import categoriesDefaultData from '../components/SearchFilter/Sagas/initialData/data/categoriesDefaultData.json'
import { initialDataProvider } from '@microfrontends/app-shell-v2'
import { ELanguage } from '@microfrontends/react-components';
import { isDevelopment } from '../../../helpers/isDevelopment'

const API_INITIAL_URL = process.env.API_INITIAL_URL

export interface IInitialSelectData {
    status: number
    data: {
        benefit: any
        city: any
        companySize: any
        district: any
        highestDegree: any
        industry: any
        jobLevel: any
        languageLevel: any
        languageProficiency: any
        salary: any
    }
}

interface IDefaultInitialSelectData extends IInitialSelectData {
    defaultDataWasUsed: true
}

class InitialDataService {
    private _selectDataStore: LocalForage
    readonly SELECT_DATA_KEY: string = 'selectData'
    readonly SELECT_DATA_VERSION: string = 'selectDataVersion'

    /**
     * Increase this version number if you want to FORCE UPDATE CACHE
     */
    readonly CURRENT_VERSION: number = 5

    constructor() {
        this._setupInitialSelectDataStore()
    }

    private _setupInitialSelectDataStore() {
        this._selectDataStore = localForage.createInstance({
            name: 'dataCache',
            storeName: 'initialData'
        })
    }

    getInitialSelectData = async (language: ELanguage): Promise<IInitialSelectData> => {
        // Data for Select Filter Components ( Salary, Joblevel, Category, Location )
        const { SELECT_DATA_KEY, SELECT_DATA_VERSION, CURRENT_VERSION } = this

        // If there is data in cache, and it's not outdated return it
        if (typeof window !== 'undefined') {
            // TODO: update this with data stored in indexdb
            // const initialSelectData: IInitialSelectData = await this._selectDataStore.getItem(SELECT_DATA_KEY);
            // const currentSelectDataVersion = await this._selectDataStore.getItem(SELECT_DATA_VERSION);

            const initialSelectData = {
                status: 200,
                data: categoriesDefaultData
            }
            return initialSelectData
            // if (!!initialSelectData && (currentSelectDataVersion === CURRENT_VERSION)) {
            //     return initialSelectData;
            // }
        }

        if (typeof window === 'undefined') {
            await initialDataProvider.initSelectData({
                selectDataKey: ['industry', 'jobFunction', 'jobFunctionCount', 'level'],
                mode: process.env.APP_SHELL_MODE as any,
                langCode: language
            })
        }

        // Otherwise, request it from server
        const selectData: Promise<any> = fetch(`${API_INITIAL_URL}`, {
            method: 'GET',
            mode: 'cors'
        })
            .then((res) => {
                if (res.status !== 200) console.log('status code ' + res.status)
                else {
                    return res.json()
                }
            })
            .catch((error) => {
                console.error(`[InitialDataService] Error trying to getInitialSelectData`, error)
                Sentry.captureMessage(
                    `[InitialDataService] Error trying to getInitialSelectData: ${error.toString()}`,
                    'debug'
                )
            })
            .then((data) => {
                if (!isEmpty(data)) {
                    return Object.assign({}, { status: 200, data })
                }
                if (typeof data === 'undefined') {
                    return {
                        status: 200,
                        data: categoriesDefaultData
                    }
                }
            })
            .catch((error) => {
                console.warn('[CAUGHT ERROR - META DATA] Could not get Meta Data', error)
                Sentry.captureMessage(
                    `[InitialDataService] Could not get Meta Data: ${error.toString()}`,
                    'debug'
                )
                return {
                    status: 200,
                    data: categoriesDefaultData
                }
            })

        // Save it to cache
        if (typeof window !== 'undefined') {
            this._selectDataStore.setItem(SELECT_DATA_KEY, selectData)
            this._selectDataStore.setItem(SELECT_DATA_VERSION, CURRENT_VERSION)

            console.info('[InitialDataService] Store InitialSelectData to IndexDb')
        }

        const defaultSelectData: Promise<IDefaultInitialSelectData> = new Promise((resolve) => {
            setTimeout(() => {
                resolve({
                    status: 200,
                    data: categoriesDefaultData,
                    defaultDataWasUsed: true
                })
            }, 0)
        })

        // Return the newly retrieved data
        return Promise.race([defaultSelectData, selectData]).then((data) => {
            if (data.defaultDataWasUsed && isDevelopment()) {
                const message = '[InitialDataService] Default Select Data was used'
                console.warn(message)
            }
            return data
        })
    }
}

export default new InitialDataService()
