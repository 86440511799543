import * as Sentry from '@sentry/node'
import { decrypt } from '../../../handlers/auth/cryptor'
import { TJobInvitation } from '@microfrontends/react-components/components/ApplyJobs/types'

const API_SPECIAL_JOBS_URL = process.env.API_SPECIAL_JOBS_URL

export interface IBadgeItemData {
    id: number
    type: string
    attributes: {
        applied: boolean
        headHunter: boolean
        saved: boolean
        matching: boolean
        topManagement: boolean
        redirectTo: string
        userBlackList: boolean
        viewed: boolean
        jobInvitation: TJobInvitation
    }
}

export interface IBadgesData {
    data: IBadgeItemData[]
}

class SpecialJobService {
    getBadgesData = (token: string = '', listIds: Array<number> = []): Promise<IBadgesData> => {
        // Data for Badges

        const data = {
            jobId: listIds
        }
        const decryptToken = decrypt(token)

        const badgesData: Promise<IBadgesData> = fetch(`${API_SPECIAL_JOBS_URL}`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${decryptToken}`
            },
            body: JSON.stringify(data)
        })
            .then((res) => {
                if (res.status !== 200) {
                    console.log('status code ' + res.status)
                    return { status: res.status }
                } else {
                    return res.json()
                }
            })
            .catch((error) => {
                console.error(`[InitialDataService] Could not getBadgesData: `, error)
                Sentry.captureMessage(
                    `[InitialDataService] Could not getBadgesData: ${error.toString()} ${JSON.stringify(data)}`,
                    'debug'
                )
            })

        return badgesData
    }
}

const specialJobService = new SpecialJobService()
export default specialJobService
