export enum EPages {
    SEARCH_RESULT = 'searchResult',
    TOP_MANAGEMENT_JOBS = 'topManagementJobs',
    INTERVIEW = 'interview',
    SALARY = 'salary',
    ERROR404 = 'error404'
}

const DEFAULT_SUCCESS_CODE = 200
const DUPLICATE_CODE = 6001
const JOB_ALERT_MAX_CODE = 6002

/**
 * This event is called when user logged in successfully on LoginModal
 */
export const EVENT_UPDATE_USER = 'UPDATE_USER'

const HEAD_JOB_SEARCH_URL = [process.env.ROUTE_SEARCH_RESULT_VI, process.env.ROUTE_SEARCH_RESULT]

export { DEFAULT_SUCCESS_CODE, DUPLICATE_CODE, JOB_ALERT_MAX_CODE, HEAD_JOB_SEARCH_URL }
// A 1x1 white pixel is used as banner image preload
export const EMPTY_IMAGE =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII='

export const OTHER_LOCATION_ID = 66
