import { applyMiddleware, createStore } from 'redux'
import reducers from './searchResultsRootReducers'
import { composeWithDevTools } from 'redux-devtools-extension'
import { rootSaga } from './searchResultsRootSagas'
import createSagaMiddleware from 'redux-saga'

const sagaMiddleware = createSagaMiddleware()
const middleware = [sagaMiddleware]

const searchResultsStore = createStore(reducers, composeWithDevTools(applyMiddleware(...middleware)))

sagaMiddleware.run(rootSaga)

export type AppStore = typeof searchResultsStore
export type RootState = ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']
export default searchResultsStore
