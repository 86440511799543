import { Utils } from '@microfrontends/react-components'
import { put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { SORT_DEFAULT_INDEX } from '../../../../../../env'
import { addPageParamToUrl, setCookie } from '../../../../../../helpers/common'
import { APP_ID } from '../../../../helpers/constant'
import filterHelper from '../../../../helpers/filterHelper'
import { localParamsHelper } from '../../../../helpers/localParamsHelper'
import { urlParamSync } from '../../../../helpers/urlParamSync'
import jobSearchService from '../../../../services/jobSearchService'
import { storeRecentSearch } from '../../../../services/recentSearches'
import { ISearchFilterParams } from '../../../../types/ISearchFilterParams'
import { IUrlSyncOptions } from '../../../../types/IUrlSyncOptions'
import { buildJobsByCompanyUrl, isPhone } from '../../../../utils/utilities'
import { getLastViewJobId } from '../../../NoResult/logics/recommandJobsHelper'
import { normalJobSortingIndexes } from '../../constants'
import * as actions from './searchFilterActions'
import { PUT_SEARCH_JOBS } from './searchFilterActions'

interface ISearchFilterJobAction {
    type: string
    params: ISearchFilterParams
    urlSyncOptions: IUrlSyncOptions
}

export function* watchSearchJobs() {
    // Watcher Saga
    yield takeLatest(actions.SEARCH_JOBS, searchFilterJobs)
}

export function* watchInfinityJobs() {
    yield takeEvery(actions.INFINITY_JOBS, infinityJobs)
}

export function* searchFilterJobs(action: ISearchFilterJobAction) {
    // Update URL
    const { selectData, isRobotRequesting, isRemoveLocation } = yield select((state) => {
        return {
            selectData: state.searchFilter.selectData,
            isRobotRequesting: state.globalMisc.isRobotRequesting,
            isRemoveLocation: state.globalMisc.isRemoveLocation
        }
    })
    // Worker Saga
    const { params, urlSyncOptions } = action

    // Get merge params
    const clonedParams = JSON.parse(JSON.stringify(params))

    if (isRobotRequesting && isRemoveLocation) {
        clonedParams.locationId = []
        clonedParams.locations = []
        clonedParams.districtId = []
    }
    const finalProcessedParams: ISearchFilterParams = yield _processParams(clonedParams)
    // Save params to the local machine
    _saveFilteredParamsToLocalMachine(finalProcessedParams)

    /**
     * VNW-13914 [To ES7] _Integrate Job search
     *
     * @author ThaiTQ
     * @since Sep 9, 2021
     */
    finalProcessedParams.enableAISummary = true
    const data = yield _searchJob(finalProcessedParams)

    /**
     * VNW-31435 New search result
     * @author Sangnd
     * @since Sep 5, 2024
     * */
    if (urlSyncOptions.isGetOutstandingJob) {
        const outStandingParams = {
            ...clonedParams,
            indexName: SORT_DEFAULT_INDEX || '',
            enableAISummary: false,
            isTopPriority: true
        }
        const outStandingProcessedParams = yield _processParams(outStandingParams)
        let outStandingJobData = null
        if (params.query && params.query !== '') {
            outStandingJobData = yield _searchJob(outStandingProcessedParams)
        } else {
            const lastJobSeen = getLastViewJobId()
            const userId = outStandingProcessedParams.user?.userId?.toString()
            const smartNaviVersion = lastJobSeen > 0 && userId ? 'v6' : 'v2'

            const response = yield jobSearchService.getRecommendJob({
                user: userId,
                jobId: lastJobSeen.toString(),
                appId: APP_ID,
                source: encodeURIComponent('New search result - Jobs You Will Love'),
                limit: '200',
                version: smartNaviVersion
            })

            const jobs = response?.data ?? []
            outStandingJobData = {
                hits: jobs || [],
                hitsPerPage: 50,
                nbHits: data?.total || 0,
                nbPage: data?.totalPage || 1,
                query: '',
                params: ''
            }
        }

        // Dispatch out standing job data to redux store
        yield put({ type: actions.PUT_OUT_STANDING_JOBS, payload: outStandingJobData })
    }

    if (!!Number(process.env.NEW_UI_SEARCH_RESULT) && clonedParams?.query && isPhone()) {
        const topPriorityJobs = yield _topPriorityJob(clonedParams)
        yield put({ type: actions.PUT_TOP_PRIORITY_JOB, payload: topPriorityJobs })
    }

    const { page } = finalProcessedParams

    // Inform globally that the result is retrieved
    if (typeof window !== 'undefined') {
        const resultEvent = new CustomEvent('RESULT', {
            detail: {
                params: finalProcessedParams,
                from: 'searchFilterSaga.ts > searchFilterJobs'
            }
        })

        window.dispatchEvent(resultEvent)
    }

    if (page >= 200 && typeof data === 'undefined') {
        const emptyData = {
            hits: [],
            hitsPerPage: 50,
            nbHits: 0,
            nbPage: 7,
            page: 0,
            params: '{"query":"","filter":[],"ranges":[],"order":[],"hitsPerPage":50,"page":0,"retrieveFields":["benefits","jobTitle","salaryMax","isSalaryVisible","jobLevelVI","isShowLogo","salaryMin","companyLogo","userId","jobLevel","jobId","companyId","approvedOn","isAnonymous","alias","expiredOn","industries","workingLocations","services","companyName","salary","onlineOn","simpleServices","visibilityDisplay","isShowLogoInSearch","priorityOrder","onlineOnText"]}',
            query: ''
        }
        yield put({ type: PUT_SEARCH_JOBS, payload: emptyData })
        yield put({ type: actions.PUT_UPDATE_PARAMS, payload: finalProcessedParams })
        return
    }
    // Get the company name for showing the search status, if users are searching by company
    let companyName = ''
    if (finalProcessedParams.userId > 0) {
        const { hits = [] } = data
        if (hits.length > 0) {
            companyName = hits[0].company
        }
    }

    _updateURL(
        selectData,
        urlSyncOptions,
        {
            ...finalProcessedParams,
            query: Utils.transformToLatin(finalProcessedParams.query)
        },
        companyName
    )
    // Store recent search
    _storeRecentSearch(data)

    // Dispatch new data to redux store
    yield put({ type: PUT_SEARCH_JOBS, payload: data })

    // Dispatch new search param value to redux store
    yield put({ type: actions.PUT_UPDATE_PARAMS, payload: finalProcessedParams })
}

function* _processParams(params) {
    // Get the previous params from Redux Store
    const clonedParams = JSON.parse(JSON.stringify(params))
    const previousParams: ISearchFilterParams = yield select((state) => state.searchFilter.lastParams)
    // Process the `userId` params, because there are cases we need to reset it
    const userIdParams = _processUserIdParams(previousParams, clonedParams)

    // Process the `page` param, because there are cases we need to reset it
    const pageParam = _processPageParam(previousParams, clonedParams)

    // Process the `indexName` params, there are special cases for it
    const indexNameParams = _processIndexNameParam(previousParams, clonedParams)
    // Merge the new params with the previous one
    const mergedParams: ISearchFilterParams = {
        ...previousParams,
        ...clonedParams,
        ...userIdParams,
        ...pageParam,
        ...indexNameParams
    }

    return mergedParams
}

function _processIndexNameParam(_, params): { indexName: string } {
    const indexParam = { indexName: '' }
    const searchingManagementJob = params.isManagementJob
    const isSortingNormalJobs = normalJobSortingIndexes.includes(params.indexName)

    // If we're searching management jobs, just keep the category as is.
    if (searchingManagementJob) {
        indexParam.indexName = params.indexName
    } else {
        // But if we're searching normal jobs, then the category is very important in relation to the `indexName`
        // If sorting, we need to change the index
        if (isSortingNormalJobs) {
            indexParam.indexName = params.indexName
        } else {
            // Biz Rule: If not sorting, but searching normal jobs with default index
            // then we need to check if user is filtering with category, in this case we need to use the category indexes
            // The category indexes are the indexes used for top level jobs by categories, this is a product we're selling

            //#VNW-26233 Add sort filter parameter on url
            // category is no longer used, set to current index name
            indexParam.indexName = params.indexName
        }
    }

    return indexParam
}

function _processPageParam(previousParams, params): { page: number } {
    // Except for the pagination, every other filer changes would NOT change the `page` param
    // So if the prev page, and the current page are the same, we know that user are searching something
    // and not clicking on the pagination
    const pageParam = { page: 0 }
    const performingANewSearch: boolean = previousParams.page === params.page

    // Reset the page to zero if user is doing a new search
    // Otherwise just keep it as it is, because user is navigating the pagination in this case
    if (performingANewSearch) {
        pageParam.page = 0
    } else {
        pageParam.page = params.page
    }

    return params
}

function _processUserIdParams(previousParams, params): { userId: number; isAnonymous: boolean } {
    const userIdParams = { userId: 0, isAnonymous: true }

    // Biz Rule: Keep the `userId` if we navigate the pagination
    // The below code line means: if clicking the pagination (new page, or the first page)
    // and users are searching by company
    const isNewPage: boolean = previousParams.page !== params.page
    const isNavigatingThePagination = (isNewPage || params.page === 0) && params.userId !== 0

    // If sorting, it's not a new search, we should keep the userId, which is the current employer filter
    // If not sorting, we need to reset this to `0` which is "all companies"
    const isSorting: boolean =
        normalJobSortingIndexes.includes(params.indexName) && params.indexName !== previousParams.indexName

    // So, if clicking the pagination, or is sorting, we keep the userId
    // Otherwise, just reset it to 0
    if (isNavigatingThePagination || isSorting) {
        userIdParams.userId = params.userId
        // userIdParams.isAnonymous = false
    } else {
        userIdParams.userId = 0
    }

    return userIdParams
}

function _saveFilteredParamsToLocalMachine(mergedParams) {
    const savedParams = Object.assign({}, mergedParams)
    delete savedParams.hitsPerPage
    const localParams = localParamsHelper.getParams()
    savedParams.clearDate = localParams.clearDate
    if (savedParams.clearDate !== undefined && savedParams.clearDate !== '') {
        const today: any = new Date(Date.now())
        const beforeDate: any = new Date(savedParams.clearDate)
        const diffTime = Math.abs(today - beforeDate)
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        if (diffDays > 1) {
            // reset
            // then update localStorage
            // get today date in string
            const today = new Date(Date.now())
            const rawDay = today.getDate()
            const rawMonth = today.getMonth() + 1
            const dayStr = rawDay <= 9 ? '0' + rawDay : rawDay
            const monthStr = rawMonth <= 9 ? '0' + rawMonth : rawMonth
            const yearStr = today.getFullYear()

            savedParams.clearDate = monthStr + '/' + dayStr + '/' + yearStr
        }
    }
    localParamsHelper.setParams(savedParams)
}

async function _searchJob(mergedParams) {
    return await jobSearchService.search(mergedParams)
}
async function _topPriorityJob(mergedParams) {
    return await jobSearchService.getPriorityJob({ ...mergedParams, hitsPerPage: 20, page: 0 })
}

function _storeRecentSearch(data) {
    const keyword = data?.query ?? ''

    storeRecentSearch({
        keyword: keyword || '',
        totalJobs: parseInt(data?.nbHits || 0)
    })
    setCookie('VNWSearchJob[keyword]', keyword || '', 30)
}

function _updateURL(
    selectData,
    urlSyncOptions,
    finalProcessedParams: ISearchFilterParams,
    companyName: string = '',
    companyId: number = -1
) {
    if (Object.keys(selectData).length !== 0 && urlSyncOptions.syncUrl === true) {
        let searchPath = urlParamSync.buildSearchPath(finalProcessedParams, selectData, urlSyncOptions.language)

        /**
         * VNW-22788 New company industry
         * Only update new URL when click SEARCH on searchbar or has 'tim-tat-ca-viec-lam' || 'all-jobs in URL
         * @author Tri, Tran Minh
         * @since Jun, 30 2023
         */
        if (!(urlSyncOptions.isUpdateNewURL === false)) {
            searchPath = urlParamSync.buildSearchUrl(finalProcessedParams, urlSyncOptions.language)
        }
        if (finalProcessedParams.userId > 0) {
            searchPath = buildJobsByCompanyUrl(companyId, companyName, urlSyncOptions.language)
        }

        const hasAdvancedFilter = filterHelper.hasSetAdvancedFilter(finalProcessedParams)
        const currentUrlSearchParamsObj = new URLSearchParams(location.search)
        const currentUrlSearchParams = currentUrlSearchParamsObj.toString()
        const currentPathname = decodeURIComponent(window.location.pathname + window.location.search)

        if (typeof window !== 'undefined') {
            // Reset the value of `historyReplaceState` to `false` if it's true
            // If it's true we will replaceState instead of pushState int he below code
            const replaceHistory: boolean = localStorage.getItem('historyReplaceState') === 'true'
            if (replaceHistory) localStorage.setItem('historyReplaceState', 'false')
            let pageNumber = 0
            if (finalProcessedParams.page > 0) {
                pageNumber = finalProcessedParams.page + 1
            }

            if (searchPath === '' && urlSyncOptions.allJobsPath !== '') {
                const allJobsPath = urlSyncOptions.allJobsPath

                let updatedPathWithSearch = `${allJobsPath}${
                    currentUrlSearchParams ? '?' + currentUrlSearchParams : ''
                }`

                if (hasAdvancedFilter && !currentUrlSearchParamsObj.has('filtered')) {
                    updatedPathWithSearch = `${allJobsPath}?filtered${
                        currentUrlSearchParams ? '&' + currentUrlSearchParams : ''
                    }`
                }

                updatedPathWithSearch = addPageParamToUrl(updatedPathWithSearch, currentUrlSearchParams, pageNumber)

                // `pushState` would update the pathName and save it to history,
                // so we need to check if it's different, otherwise it would save redundant history
                // Note: the `finalProcessedParams` is saved so the next time when this state is 'popped'
                // we could use it to search
                const realLocationPath = window.location.pathname + window.location.search
                if (updatedPathWithSearch !== realLocationPath) {
                    replaceHistory
                        ? history.replaceState(null, '', updatedPathWithSearch)
                        : history.pushState(null, '', updatedPathWithSearch)
                }
            } else {
                let isUpdateHistory = false
                if (currentPathname !== searchPath) {
                    isUpdateHistory = true
                }
                // `pushState` would update the pathName and save it to history,
                // so we need to check if it's different, otherwise it would save redundant history
                if (isUpdateHistory) {
                    replaceHistory
                        ? window.history.replaceState(null, '', searchPath)
                        : window.history.pushState(null, '', searchPath)
                }
            }

            // Dispatch to INFORM globally that the URL has been updated after search
            _dispatchUpdatedURLEvent()
        }
    }
}

/**
 * `URL_UPDATED_AFTER_SEARCH` event is used to inform globally that a URL is updated
 * after a search, so you could safely use the URL or do something after that
 */
function _dispatchUpdatedURLEvent() {
    window?.dispatchEvent(new CustomEvent('URL_UPDATED_AFTER_SEARCH'))
}

function* infinityJobs(action: actions.TInfinityJobsActionParams) {
    // 1. init
    const lastParams = yield select((state) => {
        return state.searchFilter.lastParams
    })
    const updatedParams: ISearchFilterParams = {
        ...lastParams,
        page: action.page,
        enableAISummary: action.target === 'jobs',
        isTopPriority: action.target === 'outstanding'
    }

    // 2. logics
    if (action.target === 'outstanding') {
        updatedParams.indexName = process.env.SORT_DEFAULT_INDEX || ''
    }

    // 3. fetch
    const processedParams = yield _processParams(updatedParams)

    const data = yield _searchJob(processedParams)

    // 4. store data
    if (action.target === 'jobs') {
        yield put({ type: actions.APPEND_SEARCH_JOBS, payload: data })
    } else {
        yield put({ type: actions.APPEND_OUTSTANDING_JOBS, payload: data })
    }
}
