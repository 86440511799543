import Script from 'next/script'

const GA_ACCOUNT = process.env.GA_ACCOUNT


const Scripts = () => {
    return (
        <>
            {/* Google Analytics */}
            <Script
                id='ga'
                dangerouslySetInnerHTML={{
                    __html: `
                var _gaq = _gaq || [];
                window._gaq = _gaq;
                var pluginUrl =
                    '//www.google-analytics.com/plugins/ga/inpage_linkid.js';
                _gaq.push(['_require', 'inpage_linkid', pluginUrl]);
                _gaq.push(['_setAccount', '${GA_ACCOUNT}']);
                _gaq.push(['_setDomainName', 'auto']);
                _gaq.push(['_trackPageview']);

                (function() {
                    var ga = document.createElement('script');
                    ga.type = 'text/javascript';
                    ga.rel = 'preconnect';
                    ga.async = true;
                    ga.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'stats.g.doubleclick.net/dc.js';
                    var s = document.getElementsByTagName('script')[0];
                    s.parentNode.insertBefore(ga, s);
                })();
                
                /* <![CDATA[ */
                var google_conversion_id = 1072665538;
                var google_conversion_label = "BRESCMD5mAMQwqe-_wM";
                var google_custom_params = window.google_tag_params;
                var google_remarketing_only = true;
                /* ]]> */
                function customEvent(uniqueName, action, label, value) {
                    if (_gaq) {
                        _gaq.push(['_trackEvent', uniqueName, action, label], value || 1); // 'Videos', 'Play', 'First Birthday'
                    }
                }`
                }}
            />
            <Script
                id='gtag'
                dangerouslySetInnerHTML={{
                    __html: `window.dataLayer = window.dataLayer || []; 
                    function gtag(){dataLayer.push(arguments);}; 
                    gtag('js', new Date()); 
                    gtag('config', '${GA_ACCOUNT}', {   
                        'transport_type': 'beacon', 
                        custom_map: 
                            { 
                                'dimension4' : 'metric_rating', 
                                'dimension5' : 'debug_target', 
                                'dimension6' : 'debug_event', 
                                'dimension7' : 'debug_time',
                                'dimension8' : 'event_time',
                                'dimension9' : 'event_render_time',
                                'dimension10' : 'event_load_time',
                                'dimension11' : 'event_end_time',
                                'dimension12' : 'metric_value',
                                'dimension13' : 'metric_delta',
                                'dimension14' : 'page_width',
                                'dimension15' : 'load_state',
                                'dimension16' : 'time_to_first_byte',
                                'dimension17' : 'url'
                            } 
                        }
                    );`
                }}
            />

            {/* HotJar tracking code */}
            <Script
                id='hotjar'
                strategy='lazyOnload'
                dangerouslySetInnerHTML={{
                    __html: `(function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:1632895,hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
                }}
            />

            <Script async src={`${process.env.VNW_BASE_URL}/assets-page-container/js/jquery.min.js`} />
        </>
    )
}

export default Scripts
