import React from 'react'
import { ELanguage } from '../utils/language'

export type TSearchResultContext = {
    langCode: ELanguage
}

const SearchResultContext = React.createContext({
    langCode: ELanguage.VI
})

export default SearchResultContext
