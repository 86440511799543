import btoa from 'btoa'
export function decrypt(token: string): string {
    if (typeof atob === 'function') {
        return atob(atob(token))
    }

    const nodeAtob = function (str: string) {
        return Buffer.from(str, 'base64').toString('binary')
    }

    return nodeAtob(nodeAtob(token))
}

export function encrypt(token: string): string {
    if (typeof btoa === 'function') {
        return btoa(btoa(token)).replace(/=/g, '')
    }
    const nodeBtoa = function (str: string) {
        return Buffer.from(str).toString('base64')
    }

    return nodeBtoa(nodeBtoa(token))
}

