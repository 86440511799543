import { TExpireKeyword } from "../components/NextPageJobAlert"

export const EXPIRE_KEYWORD_JOB_ALERT = 'EXPIRE_KEYWORD_JOB_ALERT'
export const NUMBER_DAY_OF_IGNORE = 3

export function  getLocalStorage() {
    const rawExpireKeywordJobAlerts = localStorage.getItem(EXPIRE_KEYWORD_JOB_ALERT)
    if (!rawExpireKeywordJobAlerts) {
        return []
    }

    const expireKeywordJobAlerts: TExpireKeyword[] = JSON.parse(rawExpireKeywordJobAlerts)

    return expireKeywordJobAlerts
}

export function getLocalStorageKeyword(keyword: string) {
    const expireKeywordJobAlert = getLocalStorage()
    if (expireKeywordJobAlert.length === 0) {
        return null
    }

    const filteredKeywords = expireKeywordJobAlert.filter((item) => item.keyword === keyword)
    if (filteredKeywords.length === 0) {
        return null
    }

    return filteredKeywords[0]
}

export function saveLocalStorage(keyword: string) {
    let expireKeywordJobAlert = getLocalStorage()

    if (expireKeywordJobAlert.length > 0) {
        const filteredKeyword = expireKeywordJobAlert.filter((item) => item.keyword === keyword)
        if (filteredKeyword.length > 0) {
            expireKeywordJobAlert = expireKeywordJobAlert.filter((item) => item.keyword !== keyword)
        }
    }

    expireKeywordJobAlert.push({
        keyword: keyword,
        expiredDate: new Date().toString()
    })

    localStorage.setItem(EXPIRE_KEYWORD_JOB_ALERT, JSON.stringify(expireKeywordJobAlert))
}

export function removeExpireKeyword(keyword: string) {
    let expireKeywordJobAlert = getLocalStorage()
    // Remove keyword expire out of local storage
    expireKeywordJobAlert = expireKeywordJobAlert.filter((item) => item.keyword !== keyword)

    localStorage.setItem(EXPIRE_KEYWORD_JOB_ALERT, JSON.stringify(expireKeywordJobAlert))
}
