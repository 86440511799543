import { ELanguage } from '@microfrontends/react-components'
import { TAuthUser } from '../../../handlers/auth/interface'
import { EPageTab } from '../types/EPageTag'
import {
    IS_ROBOT_REQUESTING,
    SET_AUTH_USER,
    SET_DETECTED_LOCATION,
    SET_IS_REMOVE_LOCATION,
    SET_LANGUAGE,
    SET_PAGE_TAB,
    SET_RESTRICT_ROUTES
} from './searchResultGlobalMiscActions'

export type TGlobalMiscStore = {
    readonly isRobotRequesting: boolean
    readonly user: TAuthUser | null
    readonly detectedLocation: number[]
    readonly pageTab: EPageTab
    readonly restrictRoutes: string[]
    readonly language: ELanguage | null
    readonly isRemoveLocation: boolean
}

type TAction = {
    type: string
    payload: any
}

const defaultGlobalMiscStore: TGlobalMiscStore = {
    isRobotRequesting: false,
    user: null,
    detectedLocation: [],
    pageTab: EPageTab.JOBS,
    restrictRoutes: [],
    language: null,
    isRemoveLocation: false
}

export default function globalMiscReducer(
    state: TGlobalMiscStore = defaultGlobalMiscStore,
    action: TAction
): TGlobalMiscStore {
    const { type, payload } = action

    switch (type) {
        case IS_ROBOT_REQUESTING: {
            return { ...state, isRobotRequesting: payload.isRobotRequesting }
        }
        case SET_AUTH_USER: {
            return {
                ...state,
                user: payload
            }
        }
        case SET_DETECTED_LOCATION: {
            return {
                ...state,
                detectedLocation: payload
            }
        }
        case SET_PAGE_TAB: {
            return {
                ...state,
                pageTab: payload
            }
        }
        case SET_RESTRICT_ROUTES: {
            return {
                ...state,
                restrictRoutes: payload
            }
        }
        case SET_LANGUAGE: {
            return {
                ...state,
                language: payload
            }
        }
        case SET_IS_REMOVE_LOCATION: {
            return {
                ...state,
                isRemoveLocation: payload.isRemoveLocation
            }
        }
        default: {
            return state
        }
    }
}
