/* eslint-disable no-unused-vars */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ISearchFilterParams } from '../../types/ISearchFilterParams'
import { DEFAULT_SUCCESS_CODE, DUPLICATE_CODE, JOB_ALERT_MAX_CODE } from '../../../../helpers/constant'
import jobAlertApi from '../JobAlerts/services/jobAlertApi'
import { EJobAlertSendingPeriod, TJobAlertItem } from '../JobAlerts/types'
import SearchResultContext from '../../context'
import { TRootState } from '../../searchResultsRootReducers'
import { TAuthUser } from '../../../../handlers/auth/interface'
import { Button, Dialog, Input, Switcher, useHog, Utils } from '@microfrontends/react-components'
import { useTranslation } from '../../hooks'
import translations from './../../translations.json'
import { jobAlertService, TPostJobAlert } from '../../services/jobAlertService'
import { ELanguage } from '../../utils/language'
import { isMobile } from '../../utils/utilities'
import { decrypt } from '../../../../handlers/auth/cryptor'
import { ON_CREATED_JOB_ALERT } from '../JobAlerts'
import { useDebounce } from '@microfrontends/react-components/components/hooks'
import { SwitcherJobAlertWrapper } from './SwitcherJobAlert.css'

type TQuickJobAlertProps = {
    isMobileProp?: boolean
    isNewUI?: boolean
}

const QuickJobAlert = (props: TQuickJobAlertProps) => {
    const { isMobileProp, isNewUI = false } = props
    const lastParams = useSelector<any, ISearchFilterParams>((state) => {
        return state.searchFilter.lastParams
    })
    const translate = useTranslation(translations)
    const isMobileDevice = isMobile()
    const userInfo = useSelector<TRootState, TAuthUser>((state) => state.globalMisc.user)
    // Quick create job alert
    const [isShowSwitcher, setShowSwitcher] = useState<boolean>(false)
    const [isShowDialogAskEmail, setShowDialogAskEmail] = useState<boolean>(false)
    const [anonymousEmail, setAnonymousEmail] = useState<string>('')
    const [isAnonymousEmailValid, setAnonymousEmailValid] = useState<boolean>(true)
    const [currentJobAlert, setCurrentJobAlert] = useState<TJobAlertItem>(null)
    const [isCreatedJobAlert, setCreatedJobAlert] = useState<boolean>(false)
    // delete
    const [isDeleteConfirmOpen, setShowDeleteConfirm] = useState<boolean>(false)
    const [jobAlertsCount, setJobAlertCount] = useState<number>(0)

    const debounceQuery = useDebounce(lastParams.query, 500)

    const context = useContext(SearchResultContext)
    const language = context.langCode
    const { tracking } = useHog()
    // End quick create job alert

    const getNoOfJobAlert = async () => {
        const { isMobileProp } = props
        if (isMobileDevice !== isMobileProp) {
            return 0
        }
        const isAnonymous = userInfo === null || userInfo.accessToken === null || userInfo.accessToken === ''
        if (isAnonymous) {
            return 0
        }
        // Check if user reach limit 10 job alert or not
        const responseJobAlert: TJobAlertItem[] = await jobAlertApi.getList()
        setShowSwitcher(responseJobAlert.length < 10)
        // check match job alert conditions
        const filterResult = responseJobAlert.filter(
            (jobAlertItem) => jobAlertItem.jobTitle.toLowerCase() === lastParams.query.toLowerCase().trim()
        )

        setCreatedJobAlert(filterResult.length > 0)
        if (filterResult.length > 0) {
            setShowSwitcher(true)
            setCurrentJobAlert(filterResult[0])
        }
        if (filterResult.length === 10) {
            setShowSwitcher(false)
        }
        setJobAlertCount(responseJobAlert.length)
        return responseJobAlert.length
    }

    const onChangeQuickCreateJobAlert = (isToggleOn: boolean) => {
        const isLoggedIn = userInfo !== null && userInfo.userName !== undefined && userInfo.userName !== null

        if (!isLoggedIn && isToggleOn) {
            setShowDialogAskEmail(true)
            setCreatedJobAlert(true)
            return
        }

        if (isToggleOn) {
            setCreatedJobAlert(true)
            doSendJobAlert()
        } else {
            if (currentJobAlert !== null && currentJobAlert.id !== -1) {
                setShowDeleteConfirm(true)
            }
        }
    }

    const handleOnClickDelete = () => {
        setShowDeleteConfirm(false)
        doDeleteJobAlert()
    }

    const handleOnClickSendJobAlert = () => {
        // check email valid
        let isValid = false
        if (anonymousEmail.indexOf('@') > 0) {
            isValid = true
            if (anonymousEmail.indexOf('.') > 0) {
                isValid = true
            } else {
                isValid = false
            }
        }
        if (isValid) {
            doSendJobAlert()
        } else {
            // only set valid false. No need to set true for better performance
            setAnonymousEmailValid(false)
        }
    }

    const handleOnCloseDialog = () => {
        setShowDialogAskEmail(false)
        setCreatedJobAlert(false)
    }

    const doSendJobAlert = async () => {
        const isLoggedIn = userInfo !== null && userInfo.userName !== undefined && userInfo.userName !== null
        const userEmail = isLoggedIn ? userInfo.userName : anonymousEmail
        const postData: TPostJobAlert = {
            keyword: lastParams.query,
            salary_min: lastParams.salary[0] > -1 ? lastParams.salary[0] : 0,
            job_level: lastParams.jobLevelId !== -1 ? [lastParams.jobLevelId] : [],
            location: lastParams.locationId,
            industry: lastParams.categoryId,
            status: 1,
            period_type_id: 2,
            notification_by: 3,
            user_email: userEmail,
            language_id: language === ELanguage.EN ? 2 : 1,
            company_industry: lastParams.companyIndustries,
            job_function: [],
            salaryPeriodId: 1,
            salaryCurrencyId: 1
        }

        // - call create job alert if isToggleOn = true
        const isAnonymous = userInfo === null || userInfo.accessToken === null || userInfo.accessToken === ''
        let apiPath = jobAlertApi._buildApiPath(isAnonymous ? 'job-alerts-anonymous' : 'job-alerts')
        apiPath = apiPath + '?utm_source_navi=jobsearch&utm_medium_navi=quickalert'
        const response = await jobAlertService.createJobAlert(
            postData,
            apiPath,
            isAnonymous ? '' : decrypt(userInfo.accessToken),
            isAnonymous
        )

        if (response?.Code === DEFAULT_SUCCESS_CODE || response?.meta?.code === DEFAULT_SUCCESS_CODE) {
            // show toast success
            setShowDialogAskEmail(false)
            let notifyTitle = translate('Job alert created successfully')
            let notifyContent = translate('Job alert "{0}" created')

            Utils.showToast(notifyTitle, notifyContent.replace('{0}', lastParams.query))
            // check have job alert id then save to state
            if (response?.data?.job_alert_id) {
                const jobAlertItemTemporary: TJobAlertItem = {
                    id: response?.data?.job_alert_id,
                    jobTitle: lastParams.query,
                    userEmail: userEmail,
                    jobLevel: lastParams.jobLevelId,
                    locations: lastParams.locationId,
                    industries: lastParams.categoryId,
                    salary: lastParams.salary[0] > -1 ? lastParams.salary[0] : 0,
                    periodType: 2,
                    createdDate: '',
                    jobResultLink: {
                        vi: '',
                        en: ''
                    }
                }
                setCurrentJobAlert(jobAlertItemTemporary)
            }
            if (isAnonymous) {
                setAnonymousEmail('')
                setShowSwitcher(false)
            }
            // Send PostHog event
            tracking({
                eventName: 'js_search_result_page:turn_on_quick_job_alert'
            })
        } else if (response?.Code === DUPLICATE_CODE) {
            Utils.showToast(translate('Job alert error'), translate('You already created this job alert'), 'error')
        } else if (response?.Code === JOB_ALERT_MAX_CODE) {
            setShowSwitcher(false)
            setCreatedJobAlert(false)
            Utils.showToast(translate('Job alert error'), translate('You already created 10 job alerts!'), 'error')
        }
    }

    const doDeleteJobAlert = async () => {
        const isAnonymous = userInfo === null || userInfo.accessToken === null || userInfo.accessToken === ''
        if (isAnonymous) {
            return
        }
        if (currentJobAlert === null) {
            return
        }
        const apiPath = jobAlertApi._buildApiPath('job-alert')
        const response = await jobAlertService.deleteJobAlert(
            apiPath + '/' + currentJobAlert.id,
            decrypt(userInfo.accessToken)
        )
        if (response?.meta?.code === DEFAULT_SUCCESS_CODE) {
            setCreatedJobAlert(false)
            const deletedNotifyContent = translate('Job alert "{0}" deleted')
            Utils.showToast(
                translate('Job alert deleted successfully'),
                deletedNotifyContent.replace('{0}', currentJobAlert.jobTitle)
            )
            getNoOfJobAlert()
            // Delete success! Send tracking
            tracking({
                eventName: 'js_search_result_page:turn_off_quick_job_alert'
            })
        }
    }

    const onSearchEventCall = async () => {
        const keywordTrimmed = lastParams.query.trim()
        const isAnonymous = userInfo === null || userInfo.accessToken === null || userInfo.accessToken === ''
        if (keywordTrimmed !== '' && isAnonymous && !isShowSwitcher) {
            setCreatedJobAlert(false)
            setShowSwitcher(true)
            getNoOfJobAlert()
        }
    }

    const onkeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleOnClickSendJobAlert()
        }
    }

    const onCreatedJobAlert = (e: Event) => {
        setCreatedJobAlert(true)
        setCurrentJobAlert({
            jobTitle: lastParams.query,
            id: e.detail.jobAlertId,
            userEmail: '',
            jobLevel: -1,
            locations: [],
            industries: [],
            salary: -1,
            periodType: EJobAlertSendingPeriod.Daily,
            createdDate: '',
            jobResultLink: {
                vi: '',
                en: ''
            }
        })
    }

    const checkToggle = useCallback(() => {
        // toggle to false after state change
        setCreatedJobAlert(false)
        const asyncCheckParam = async () => {
            let countJob = jobAlertsCount
            // Only need to get number of job alert when job alerts count is closing to 10
            if (userInfo !== null) {
                // do check limit
                countJob = await getNoOfJobAlert()

            } else {
                // always show quick create job alert when in anonymous
                setShowSwitcher(true)
            }
            const keywordTrimmed = lastParams.query.trim()
            if (keywordTrimmed === '') {
                setShowSwitcher(false)
            } else if (countJob < 10 && keywordTrimmed !== '') {
                setShowSwitcher(true)
            }
        }
        asyncCheckParam()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo, jobAlertsCount, lastParams.query])

    useEffect(() => {
        checkToggle()
    }, [debounceQuery])

    useEffect(() => {
        if (typeof window === 'undefined') {
            return
        }
        window.addEventListener('SEARCH', onSearchEventCall)
        return () => {
            window.removeEventListener('SEARCH', onSearchEventCall)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        window.addEventListener(ON_CREATED_JOB_ALERT, onCreatedJobAlert)
        return () => {
            window.removeEventListener(ON_CREATED_JOB_ALERT, onCreatedJobAlert)
        }
    }, [lastParams.query])

    return (
        <>
            {isShowSwitcher && (
                <SwitcherJobAlertWrapper isNewUI={isNewUI} className={`quick-create-job-alert ${isMobileProp ? 'is-mobile' : ''}`}>
                    <span>{translate(isNewUI ? 'Enable alert' : 'Create job alert')}</span>
                    <Switcher value={isCreatedJobAlert} onChange={onChangeQuickCreateJobAlert} isNeedSetState={false} />
                </SwitcherJobAlertWrapper>
            )}

            {isShowDialogAskEmail && (
                <Dialog
                    key={`dialog-quick-job-alert-anonymous-${isShowDialogAskEmail ? 'true' : 'false'}`}
                    dialogClass='anonymous-job-alert-dialog'
                    isOpen={isShowDialogAskEmail}
                    title={translate('Create job alert')}
                    fullScreenOnMobile
                    actionText={translate('Send me jobs')}
                    onClickOutside={() => {
                        if (!isShowDialogAskEmail) {
                            return
                        }
                        handleOnCloseDialog()
                    }}
                    backdrop={true}
                    backdropClassName='backdrop-blur'
                    onBack={handleOnCloseDialog}
                    onClickAction={handleOnClickSendJobAlert}
                >
                    <div className='row'>
                        <label>{translate('Your email')}</label>
                    </div>
                    <div className='row'>
                        <div className='col-input'>
                            <Input
                                value={anonymousEmail}
                                placeholder={translate('Email to receive jobs')}
                                onChange={(value) => {
                                    setAnonymousEmail(value)
                                    setAnonymousEmailValid(true)
                                }}
                                onKeyDown={onkeydown}
                            />
                        </div>
                        {isMobileDevice ? (
                            <></>
                        ) : (
                            <div className='col-action'>
                                <Button type='primary' onClick={handleOnClickSendJobAlert}>
                                    {translate('Send me jobs')}
                                </Button>
                            </div>
                        )}
                    </div>
                    <div className='row' style={{ display: isAnonymousEmailValid ? 'none' : 'block' }}>
                        <p className='error-message'>{translate('This is not a valid email address')}</p>
                    </div>
                </Dialog>
            )}

            {isDeleteConfirmOpen && (
                <Dialog
                    key={`dialog-quick-delete-job-alert-${isDeleteConfirmOpen ? 'true' : 'false'}`}
                    title={translate('Delete this Job Alert?')}
                    isOpen={isDeleteConfirmOpen}
                    onBack={() => {
                        setShowDeleteConfirm(false)
                        setCreatedJobAlert(true)
                    }}
                    onClickOutside={() => {
                        if (isDeleteConfirmOpen) {
                            setCreatedJobAlert(true)
                            setShowDeleteConfirm(false)
                        }
                    }}
                    onClickAction={handleOnClickDelete}
                    type='delete'
                    deleteText={translate('Remove')}
                    secondaryButtonText={translate('Cancel')}
                    backdrop
                    backdropClassName='backdrop-blur'
                >
                    <div>{translate('You will not be able to receive email for job alert')}</div>
                </Dialog>
            )}
        </>
    )
}

function areEqual(prevProps, nextProps) {
    return prevProps.isMobileProp === nextProps.isMobileProp
}

export default React.memo(QuickJobAlert, areEqual)
