import dynamic from 'next/dynamic'
import { ComponentType } from 'react'
import { Hooks } from '@microfrontends/react-components'
import { TNotificationProps } from './type'

const DynamicNotification: ComponentType<any> = dynamic(
    import('@microfrontends/react-components').then((mod) => mod.Notifications),
    { ssr: false }
)

export default function NotificationComponent(props: TNotificationProps) {
    Hooks.useInitNotification()

    return (
        <DynamicNotification
            langCode={props.langCode}
            user={props.user}
            config={{
                firebaseConfig: props.firebaseConfig,
                jobSeekerUrl: props.jobSeekerUrl,
                baseMsUrl: process.env.MICROSERVICE_DOMAIN ?? ''
            }}
        />
    )
}
