export type TJobAlertItem = {
    id: number
    userEmail: string
    jobTitle: string
    jobLevel: number
    locations: number[]
    industries: number[]
    salary: number
    periodType: EJobAlertSendingPeriod
    createdDate: string
    jobResultLink: {
        vi: string
        en: string
    }
}

export type TSubmitJobAlert = {
    userEmail: string
    jobTitle: string
    jobLevel: number
    locations: number[]
    industries: number[]
    salary: number
    periodType: EJobAlertSendingPeriod
}

export type TSubmitUpdateJobAlert = TSubmitJobAlert & {
    id: number
}

export enum EJobAlertSendingPeriod {
    Daily = 2,
    Weekly = 3
}

export enum EJobAlertActionName {
    Create = 'create',
    Update = 'update',
    Delete = 'delete',
    ShowList = 'showList'
}
