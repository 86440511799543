import React from 'react'

export class Translator {
    private _translations: any = {}

    constructor(resource: any = null) {
        this.setTranslationResource(resource)
    }

    setTranslationResource(resource: any) {
        this._translations = resource
    }

    translate(lang: string = 'en', term: string): string {
        return lang === 'en' ? term : this._translations[term] || term
    }
}

// IReturnFunction
export default function withComponentTranslation(translationResource) {
    // Set the translation
    const translator = new Translator(translationResource)

    // Return a new function that accept a component
    return function (WrappedComponent) {
        // Return the new component
        return class WithComponentTranslation extends React.PureComponent<any, any> {
            render() {
                const language = this.props.language
                const translate = (term: string): string => {
                    return translator.translate(language, term)
                }

                return <WrappedComponent translate={translate} {...this.props} />
            }
        }
    }
}
