import { ISearchFilterParams } from '../../types/ISearchFilterParams'
import { PUT_SELECT_DATA } from './Sagas/initialData/initialDataActions'
import { PUT_SAVE_JOB, PUT_UNSAVE_JOB, TOGGLE_SAVE_JOB } from './Sagas/saveJobs/saveJobsActions'
import {
    PUT_SEARCH_JOBS,
    PUT_RESET_DATA,
    PUT_UPDATE_PARAMS,
    PUT_UPDATE_PARTIAL_PARAMS,
    PUT_OUT_STANDING_JOBS,
    APPEND_SEARCH_JOBS,
    APPEND_OUTSTANDING_JOBS,
    PUT_TOP_PRIORITY_JOB
} from './Sagas/searchFilterJobs/searchFilterActions'
import { PUT_SPECIAL_JOBS } from './Sagas/specialJobs/specialJobAction'
import { defaultSearchFilterParams } from './constants'

type TSearchResult = {
    hits: any[]
    hitsPerPage: number
    nbHits: number
    nbPage: number
    page: number
    params: string
    query: string
}

interface ISearchFilterStore {
    selectData: any
    language: string
    lastParams: ISearchFilterParams
    searchResult: Partial<TSearchResult>
    outStandingJobs: Partial<TSearchResult>
    topPriorityJobs: Partial<TSearchResult>
    isInitParams: boolean
}

const defaultFilterStore: Readonly<ISearchFilterStore> = {
    language: 'en',
    selectData: {},
    lastParams: defaultSearchFilterParams,
    searchResult: {},
    isInitParams: true,
    outStandingJobs: {},
    topPriorityJobs: {}
}

export default function searchFilterReducer(
    state: ISearchFilterStore = defaultFilterStore,
    action
): ISearchFilterStore {
    const { type, payload } = action

    switch (type) {
        case PUT_SEARCH_JOBS: {
            return {
                ...state,
                searchResult: payload
            }
        }

        case APPEND_SEARCH_JOBS: {
            const hits = [...(state.searchResult.hits || []), ...(payload.hits || [])]
            return {
                ...state,
                searchResult: { ...payload, hits }
            }
        }

        case APPEND_OUTSTANDING_JOBS: {
            const hits = [...(state.outStandingJobs.hits || []), ...(payload.hits || [])]
            return {
                ...state,
                outStandingJobs: { ...payload, hits }
            }
        }

        case PUT_TOP_PRIORITY_JOB: {
            return {
                ...state,
                topPriorityJobs: payload
            }
        }

        case PUT_OUT_STANDING_JOBS: {
            return {
                ...state,
                outStandingJobs: payload
            }
        }

        case PUT_SPECIAL_JOBS: {
            if (payload.type === 'jobs') {
                return {
                    ...state,
                    searchResult: {
                        ...state.searchResult,
                        hits: payload.data
                    }
                }
            }
            if (payload.type === 'top-priority-jobs') {
                return {
                    ...state,
                    topPriorityJobs: {
                        ...state.searchResult,
                        hits: payload.data
                    }
                }
            }
            return {
                ...state,
                outStandingJobs: {
                    ...state.outStandingJobs,
                    hits: payload.data
                }
            }
        }

        case PUT_RESET_DATA: {
            return {
                ...defaultFilterStore,
                lastParams: { ...defaultFilterStore.lastParams },
                isInitParams: false
            }
        }

        case TOGGLE_SAVE_JOB: {
            const updatedJobHits = state.searchResult?.hits?.map((item) => {
                if (item.jobId === payload.jobId) {
                    return {
                        ...item,
                        isSaved: payload.status
                    }
                }
                return item
            })
            const updatedOutstandingJobHits = state.outStandingJobs?.hits?.map((item) => {
                if (item.jobId === payload.jobId) {
                    return {
                        ...item,
                        isSaved: payload.status
                    }
                }
                return item
            })
            const updatedTopPriorityJobsHits = state.topPriorityJobs?.hits?.map((item) => {
                if (item.jobId === payload.jobId) {
                    return {
                        ...item,
                        isSaved: payload.status
                    }
                }
                return item
            })
            return {
                ...state,
                searchResult: { ...state.searchResult, hits: updatedJobHits },
                outStandingJobs: { ...state.outStandingJobs, hits: updatedOutstandingJobHits },
                topPriorityJobs: { ...state.topPriorityJobs, hits: updatedTopPriorityJobsHits }
            }
        }

        case PUT_SAVE_JOB: {
            // payload is Job id and status after saving both Save and Unsave Action
            const updatedHits = state.searchResult.hits.map((item) => {
                if (item.jobId === payload.jobId)
                    return {
                        ...item,
                        isSaved: payload.status === 'success',
                        status: payload.status
                    }
                return item
            })
            return {
                ...state,
                searchResult: {
                    ...state.searchResult,
                    hits: updatedHits
                }
            }
        }

        case PUT_UNSAVE_JOB: {
            const updatedHits = state.searchResult.hits.map((item) => {
                if (item.jobId === payload.jobId)
                    return {
                        ...item,
                        isSaved: payload.status !== 'success',
                        status: payload.status
                    }
                return item
            })
            return {
                ...state,
                searchResult: {
                    ...state.searchResult,
                    hits: updatedHits
                }
            }
        }

        case PUT_UPDATE_PARAMS: {
            return {
                ...state,
                lastParams: payload,
                isInitParams: false
            }
        }

        // TODO: [Open] Check to see the difference between this and UPDATE_PARAMS
        case PUT_UPDATE_PARTIAL_PARAMS: {
            return {
                ...state,
                lastParams: { ...state.lastParams, ...payload },
                isInitParams: false
            }
        }

        case PUT_SELECT_DATA: {
            return {
                ...state,
                selectData: payload
            }
        }
    }

    return state
}
