import { ISaveJobParams, IToggleSaveJobParams } from '../../../../types/ISaveJobParams'

export const SAVE_JOB = 'SAVE_JOB'
export const PUT_SAVE_JOB = 'PUT_SAVE_JOB'
export const UNSAVE_JOB = 'UNSAVE_JOB'
export const PUT_UNSAVE_JOB = 'PUT_UNSAVE_JOB'
export const TOGGLE_SAVE_JOB = 'TOGGLE_SAVE_JOB'

export function createSaveJobAction(params: ISaveJobParams) {
    return {
        type: SAVE_JOB,
        params: params
    }
}

export function createUnSaveJobAction(params: ISaveJobParams) {
    return {
        type: UNSAVE_JOB,
        params: params
    }
}

export function createToggleSaveJobAction(params: IToggleSaveJobParams) {
    return {
        type: TOGGLE_SAVE_JOB,
        payload: params
    }
}
