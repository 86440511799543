interface IRequestOptions {
    method: string
    language: string
}

interface IApiResult {
    data: any[]
    meta: {
        rotateTime: number
    }
}

class RecommendService {
    apiBaseUrl: string

    constructor(apiBaseUrl: string) {
        this.apiBaseUrl = apiBaseUrl
    }

    async getRecommendJobs(
        jobId: number = 0,
        userId: number = 0,
        limit: number = 20,
        method: string = 'get',
        language: string = 'vi'
    ) {
        const apiUrl = `${this.apiBaseUrl}/v2/jobs?jobId=${jobId}&user=${userId}&limit=${limit}&lang=${language}`

        const result = await this._callApi(apiUrl, {
            method: method,
            language: language
        })

        return result.data
    }

    async _callApi(apiUrl: string, options: IRequestOptions): Promise<IApiResult> {
        const headers = {
            'Accept-Language': options.language
        }

        const apiResult = {
            data: [],
            meta: {
                rotateTime: 3000
            }
        }

        try {
            const res = await fetch(apiUrl, {
                method: options.method,
                headers: headers
            })

            if (res.status === 200) {
                const responseContent = await res.json()

                apiResult.data = responseContent.jobs || []
                apiResult.meta = { ...responseContent.total }
            }
        } catch (err) {
            if (err instanceof Error) {
                console.error(`[RecommendService] Error when getting data from API: ${err.message} ${apiUrl}`)
            }
        }

        return apiResult
    }
}

export default RecommendService
