import get from 'lodash/get'
import { ISearchFilterParams } from '../types/ISearchFilterParams'
import filterHelper from './filterHelper'
import { getCurrentTimestamp } from '../../../helpers/common'

const hasLocalStorage: boolean = !!(typeof window !== 'undefined' && window.localStorage)
const SORT_DEFAULT_INDEX = process.env.SORT_DEFAULT_INDEX || process.env.ALGOLIA_DEFAULT_INDEX || ''

class LocalParamsHelper {
    /**
     * Return saved params from localStorage
     * `indexName` for sorting would be from sessionStorage
     */
    getParams(): ISearchFilterParams {
        const defaultParams = filterHelper.getDefaultSearchFilterParams()
        if (hasLocalStorage) {
            const params: string | null = localStorage.getItem('params')
            const sessionParams = JSON.parse(sessionStorage.getItem('params') || '{}')

            // Most params are stored in localStorage
            const localParams = params ? JSON.parse(params) : defaultParams
            if (localParams.clearDate !== undefined && localParams.clearDate !== '') {
                const today: any = new Date(Date.now())
                const beforeDate: any = new Date(localParams.clearDate)
                const diffTime = Math.abs(today - beforeDate)
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
                if (diffDays > 1) {
                    // reset
                    localParams.categoryId = []
                    // then update localStorage
                    // get today date in string
                    this.setParams(localParams)
                }
            } else {
                this.updateClearDate()
            }
            if (localParams.ttl < getCurrentTimestamp()) {
                localParams.categoryId = []
                localParams.locationId = []
                localParams.locations = []
                localParams.ttl = getCurrentTimestamp() + 30 * 24 * 3600 // 30 days in second
                this.setParams(localParams)
            }

            // `sortId` & `categoryId` are stored in session storage
            const sessionParamsIsNotEmpty = Object.keys(sessionParams).length > 0

            const fullParams = sessionParamsIsNotEmpty
                ? Object.assign(localParams, sessionParams)
                : Object.assign(localParams, { indexName: SORT_DEFAULT_INDEX })

            return fullParams
        }

        return defaultParams
    }

    updateClearDate() {
        const defaultParams = filterHelper.getDefaultSearchFilterParams()
        if (hasLocalStorage) {
            const params: string | null = localStorage.getItem('params')

            // Most params are stored in localStorage
            const localParams = params ? JSON.parse(params) : defaultParams
            const today: any = new Date(Date.now())
            const rawDay = today.getDate()
            const rawMonth = today.getMonth() + 1
            const dayStr = rawDay <= 9 ? '0' + rawDay : rawDay
            const monthStr = rawMonth <= 9 ? '0' + rawMonth : rawMonth
            const yearStr = today.getFullYear()

            localParams.clearDate = monthStr + '/' + dayStr + '/' + yearStr
            this.setParams(localParams)
        }
    }

    setParams(params: ISearchFilterParams) {
        if (hasLocalStorage) {
            // We need to removed field page in localStorage since It is not necessary
            const removedPageNumber: any = Object.assign({}, params)

            if (get(removedPageNumber, 'page', undefined) !== undefined) delete removedPageNumber.page
            // Except for `sortId`, we save all params in localStorage
            // Save to localStorage
            localStorage.setItem('params', JSON.stringify(removedPageNumber))

            // `indexName` for sorting and `categoryId` should be kept for only 1 session, to keep the business rules
            const sessionParams = {
                indexName: params.indexName,
                categoryId: params.categoryId
            }
            sessionStorage.setItem('params', JSON.stringify(sessionParams))
        }
    }

    isEnterDirectly(): boolean {
        var pattern = '.*(vietnamworks.com)$'
        var regex = new RegExp(pattern)
        var referrerUrl = document.referrer
        return !regex.test(referrerUrl) || !referrerUrl
    }
}

export const localParamsHelper = new LocalParamsHelper()
