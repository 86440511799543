import styled, {css} from 'styled-components'

export enum EMostRelevantPositionType {
    FILTER_BAR = 1,
    OTHER = 2
}
export const SortingBar = styled.div<{isMobile: boolean, sortingPosition?: EMostRelevantPositionType}>`
    display: flex;
    align-items: center;
    gap: 8px;
    .title {
        font-size: 12px;
        margin-right: 0;
    }
    .sort-item-wrapped {
        display: flex;
        align-items: center;
        gap: 8px;
    }
    ${({ sortingPosition }) => {
        if (sortingPosition && sortingPosition === EMostRelevantPositionType.FILTER_BAR) {
            return css`
                padding: 0 8px;
            `
        }
        return css``
    }}
`
export const HighlightContainer = styled.div`
    position: relative;
    @media screen and (max-width: 1024px) {
        .tooltipContains {
            .tooltip-bottom {
                transform: unset;
                left: -80px;
            }
            .arrow-up {
                left: calc(50% - 50px);
            }
        }
    }
`

export const HighlightTagBasic = styled.div`
    cursor: pointer;
    @keyframes svgAnimation {
        from {
            stroke-dashoffset: 0;
        }
        to {
            stroke-dashoffset: 1000;
        }
    }
    svg {
        position: absolute;
    }
    .line {
        stroke-dasharray: 6, 6;
        stroke-width: 3px;
        fill: transparent;
        stroke: #005aff;
        animation: svgAnimation 30s linear infinite;
    }
`
export const TooltipDes = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;

    p {
        white-space: nowrap;
    }
    svg:hover {
        cursor: pointer;
    }
`
