export function getDayDifference(date1: Date, date2: Date): number {
    // Convert both dates to timestamps
    const timestamp1: number = date1.getTime()
    const timestamp2: number = date2.getTime()

    // Calculate the difference in milliseconds
    const timeDifference: number = timestamp2 - timestamp1

    // Convert milliseconds to days (1 day = 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
    const minutes = 1000 * 60
    const dayDifference: number = Math.ceil(timeDifference / (minutes * 60 * 24))

    return dayDifference
}
