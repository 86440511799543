import isEqual from 'lodash/isEqual'
import { SORT_DEFAULT_INDEX, MANAGEMENT_JOB_DEFAULT, HITS_PER_PAGE, RESTRICT_SEARCHABLE_ATTRIBUTES } from '../../../env'
import searchResultsStore from '../searchResultsStore'
import { ISearchFilterParams } from '../types/ISearchFilterParams'
import { ELanguage } from '@microfrontends/react-components'
import { TOrderedSelectDataItem } from '@microfrontends/react-components/components/types'

type TSalary = {
    // { "id": [0, 500], "name": "<= $500" }
    id: number[]
    name: string
}

type TSelectDataItem = {
    // { "id": 29, "name": "Hồ Chí Minh", "nameEn": "Ho Chi Minh" }
    id: number
    name: string
    nameEn?: string
    nameVi?: string
}

type TOption = {
    id: number
    name: string
    nameEn: string
}

type TSelectChildData = {
    [key: string]: TSelectDataItem[]
}

type TSelectParentData = {
    parent: TSelectDataItem[]
    child: TSelectChildData
}

export type TSelectData = {
    salary: TSalary[]
    level: TSelectDataItem[]
    category: TSelectDataItem[]
    categoryById: { [name: number]: { name: string; slug: string } }
    location: TSelectDataItem[]
    locationById: { [name: number]: { name: string; slug: string } }
    benefit: TSelectDataItem[]
    typeWorking: TSelectDataItem[]
    companySize: TSelectDataItem[]
    companyIndustry: TOption[]
    jobFunction: TSelectParentData
    districtId: any[]
    districts: {
        [key: string]: TOrderedSelectDataItem[]
    }
}

export function fireGlobalSearchEvent(
    params: Partial<ISearchFilterParams>,
    from: string,
    hideFilter: boolean = true,
    clearCurrentParams: boolean = false
) {
    if (typeof window !== 'undefined') {
        // TODO: [Docs] add documentation for events
        // VNW-26233 Sorting
        // Every filter and sort handler itself use this function and provide its own props
        // only sort handler inside JobHead/index.ts and SortDialog should contain indexName inside param
        // Reset sorting to default when filters changed
        // Related event code:
        // - filterHelpers.ts > fireGlobalSearchEvent
        // - _FilterHandler.ts > handleSetFilterValue
        // - SearchFilter/index.ts > search
        // reset sort value if event come from filter bar and not sort dialog / JobHead component
        const updatedParams = {
            ...params,
            indexName: params.indexName || process.env.SORT_DEFAULT_INDEX
        }

        const searchEvent = new CustomEvent('SEARCH', {
            detail: {
                params: updatedParams,
                from,
                hideFilter,
                clearCurrentParams: clearCurrentParams
            }
        })

        window.dispatchEvent(searchEvent)
    }
}

/**
 * This helper class is designed to:
 * - Get the "text values" from the "ids" of filter params
 *
 * This is done by connecting this class to the Redux store
 * // TODO: [Refactor] Move all above functions, convert them into FilterHelper methods
 * // TODO: [Refactor] Consider getting these data from localStorage/idb instead of the redux store for consistent
 *      with the other duplicated functions in the App Shell Module
 */
class FilterHelper {
    transformedSalary: any = null

    private get _selectData(): TSelectData {
        return searchResultsStore.getState().searchFilter?.selectData
    }

    getLocationNameFromId(ids: any): string {
        let locationNameText = ''
        if (ids !== -1) {
            ids.map((item, index) => {
                const locationName = this._selectData.locationById[item]?.name
                if (locationName !== undefined) {
                    locationNameText += (index > 0 ? ', ' : '') + locationName
                }
            })
        }
        return locationNameText
    }

    getCategoryNameFromId(id: number): string {
        return this._selectData.categoryById?.[id]?.name || ''
    }

    getCategoryNameFromIds(ids: number[]): string {
        let categoryNameText = ''
        if (typeof ids === 'object') {
            ids.map((item, index) => {
                const categoryName = this._selectData.categoryById[item]?.name
                if (categoryName !== undefined) {
                    categoryNameText += (index > 0 ? ', ' : '') + categoryName
                }
            })
            return categoryNameText
        }
        return this._selectData.categoryById[ids as number]?.name
    }

    getSalaryNameFromId(id: number[]): string {
        const stringyId = JSON.stringify(id)
        return this._selectData?.salary?.filter((item) => JSON.stringify(item.id) === stringyId)[0]?.name || ''
    }

    getJobLevelNameFromId(id: number): string {
        return this._selectData?.level?.filter((item) => item.id === id)[0]?.name || ''
    }

    getTypeWorkingNameFromId(id: number): string {
        return this._selectData?.typeWorking?.filter((item) => item.id === id)[0]?.name || ''
    }

    getBenefitNameFromIds(ids: number[]): string {
        return this._selectData?.benefit
            .filter((benefitItem) => {
                return ids.includes(benefitItem.id)
            })
            .map((benefitItem) => benefitItem.name)
            .join(', ')
    }
    getCompanySizeNameFromId(id: number): string {
        return this._selectData?.companySize?.filter((item) => item.id === id)[0]?.name || ''
    }
    getIndustriesNameFromIds(ids: number[], language: ELanguage): string {
        const listIndustry = this._selectData?.companyIndustry.filter((item) => ids.includes(item.id))

        return language === ELanguage.VI
            ? listIndustry.map((item) => item.name).join(', ')
            : listIndustry.map((item) => item.nameEn).join(', ')
    }

    /**
     *
     * The salary options in SelectData has ID if type array
     * But the Select Component using number type in ID
     * So convert the array ID to number-like ID
     *
     * @param salaryData
     */
    getMemorizedTransformedSelectSalary(salaryData: Array<TSalary>) {
        if (this.transformedSalary) {
            return this.transformedSalary
        }

        this.transformedSalary = salaryData?.map((salary) => {
            return {
                id: salary.id.join(', '),
                name: salary.name
            }
        })
    }

    getDefaultSearchFilterParams() {
        const defaultSearchFilterParams: Readonly<ISearchFilterParams> = {
            benefitIds: [],
            categoryId: [],
            companySizeId: -1,
            salaryCurrency: 1,
            salaryId: -1,
            hitsPerPage: HITS_PER_PAGE,
            indexName: SORT_DEFAULT_INDEX,
            isManagementJob: false,
            isUrgentJob: false,
            jobLevelId: -1,
            locationId: [],
            districtId: [],
            locations: [],
            jobFunctionId: [],
            companyIndustries: [],
            page: 0,
            query: '',
            restrictSearchableAttributes: RESTRICT_SEARCHABLE_ATTRIBUTES,
            salary: [-1, -1],
            typeWorkingId: -1,
            userId: 0,
            clearDate: ''
        }

        return { ...defaultSearchFilterParams }
    }

    getDefaultManagementJobParams() {
        const defaultManagementJobParams: Partial<ISearchFilterParams> = {
            indexName: MANAGEMENT_JOB_DEFAULT,
            isManagementJob: true,
            page: 0
        }
        return { ...defaultManagementJobParams }
    }

    /**
     * Check if users have set any value
     * It returns `true` if any values is different than the default value
     * @param params
     */
    checkIfAnyParamWasSet(params: ISearchFilterParams) {
        const salaryStr = params?.salary.join(',')
        // Should not put userId in this check, userId is a special case
        if (
            params?.benefitIds.length > 0 ||
            params.categoryId.length > 0 ||
            params.isUrgentJob ||
            params.jobLevelId > 0 ||
            params.locationId.length > 0 ||
            salaryStr !== '-1,-1' ||
            params.query !== '' ||
            params.userId > 0
        ) {
            return true
        }

        return false
    }

    hasSetAdvancedFilter(params: ISearchFilterParams) {
        return (
            params.jobLevelId > -1 ||
            !isEqual(params.salary, [-1, -1]) ||
            params.benefitIds.length > 0 ||
            params.typeWorkingId > -1
        )
    }

    getDefaultFilterableParams(): Partial<ISearchFilterParams> {
        return {
            salary: [-1, -1],
            benefitIds: [],
            categoryId: [],
            salaryId: -1,
            companyIndustries: [],
            jobFunctionId: [],
            jobLevelId: -1,
            typeWorkingId: -1,
            isUrgentJob: false,
            companySizeId: -1,
            salaryCurrency: 1, // USD
            page: 0
        }
    }
}

const filterHelper = new FilterHelper()
export default filterHelper
