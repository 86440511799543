import { useContext, useEffect } from 'react'
import { ELanguage } from '@microfrontends/react-components'
import SearchResultContext from '../context'

export function useTranslation(resource, langCode?: ELanguage) {
    const context = useContext(SearchResultContext)

    const language = langCode || context.langCode

    function translate(key: string) {
        return resource?.[language]?.[key] || key
    }

    return translate
}

export function useOnClickOutside(ref, handler) {
    useEffect(() => {
        const listener = (event) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                return
            }
            handler(event)
        }
        document.addEventListener('mousedown', listener)
        document.addEventListener('touchstart', listener)
        return () => {
            document.removeEventListener('mousedown', listener)
            document.removeEventListener('touchstart', listener)
        }
    }, [ref, handler])
}
