import { all, call, spawn } from 'redux-saga/effects'
import {
    watchJobAlertCreation,
    watchJobAlertDeletion,
    watchJobAlertEdition
} from './components/JobAlerts/sagas/jobAlertCreationSagas'
import { watchGetJobAlertList } from './components/JobAlerts/sagas/jobAlertSagas'
import { watchGetSpecialJob } from './components/SearchFilter/Sagas/specialJobs/specialJobSaga'
import { watchSaveJob, watchUnsaveJob } from './components/SearchFilter/Sagas/saveJobs/saveJobSaga'

import { watchGetData } from './components/SearchFilter/Sagas/initialData/initialDataSaga'
import { watchInfinityJobs, watchSearchJobs } from './components/SearchFilter/Sagas/searchFilterJobs/searchFilterSaga'

export function* rootSaga() {
    const sagas = [
        watchSearchJobs,
        watchInfinityJobs,
        watchGetData,
        watchGetSpecialJob,
        watchSaveJob,
        watchUnsaveJob,
        watchGetJobAlertList,
        watchJobAlertCreation,
        watchJobAlertEdition,
        watchJobAlertDeletion,
    ]

    yield all(
        sagas.map((saga) =>
            spawn(function* () {
                while (true) {
                    try {
                        yield call(saga)
                        break
                    } catch (e) {
                        console.log(e)
                    }
                }
            })
        )
    )
}
